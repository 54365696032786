import React, {useEffect, useState} from "react";
import classes from './BidModal.module.sass'
import {Modal, SubmitMenu, SuccessModal} from "../Modal";
import {getAPI} from "../../../api/api";
import {useFormik} from "formik";
import {timeFromISO, timeToISO} from "../../../utils/timeConverters";
import {useDispatch} from "react-redux";
import {createBid, editBid} from "../../../redux/actions/Bids";
import {getErrorMessageFromResponse} from "../../../utils/errors";
import DateInput from "../../FormControls/DateInput";
import moment from "moment";
import TextInput from "../../FormControls/TextInput";
import TextareaInput from "../../FormControls/TextareaInput";
import staticData from "../../../utils/staticData";
import {useParams} from "react-router";
import useQuery from "../../../hooks/useQuery";
import useAuth from "../../../hooks/useAuth";
import bidSchema from "../../../schemas/bidSchema";
import {getMostFrequentlyEncounteredError} from "../../../schemas";
import DropdownInput from "../../FormControls/DropdownInput";
import useLoadableData from "../../../hooks/useLoadableData";
import useFirstRender from "../../../hooks/useFirstRender";

const BidModal = ({close}) => {

    const style = {
        width: '700px',
    }

    const dispatch = useDispatch()
    const firstRender = useFirstRender()
    const params = useParams()
    const query = useQuery()
    const {user} = useAuth()

    // Объект, выбранный по дефолт
    const [chosenFirstObjectId, setFirstChosenObjectId] = useState(null)

    const [title, setTitle] = useState(params.type === 'edit' ? 'Редактирование заявки' : 'Создание заявки')
    const [scroll, setScroll] = useState(0)
    const [isLoading, setLoading] = useState(true)
    const [isWatch, setIsWatch] = useState(false)

    const formik = useFormik({
        initialValues: bidSchema.cast({
            id: params.id,
            executor: {
                id: user.id,
                full_name: `${user.last_name} ${user.first_name}`,
                phone: user.phone
            },
        }),
        validationSchema: bidSchema,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validate: () => setScroll(prev => prev + 1),
        onSubmit: async values => {
            const data = {
                id: values.id,
                subject: values.subject?.id,
                type_vehicle: values.type_vehicle?.id,
                vehicle: values.vehicle?.id || '',
                description: values.description,
                date_closed: values.date_closed,
                executor: values.executor.id,
                add_req_vehicle: values.add_req_vehicle,
                add_req_driver: values.add_req_driver,
            }

            if (params.type === 'edit') {
                const response = await dispatch(editBid(params.id, data))

                if (response.error) {
                    setErrorModal(getErrorMessageFromResponse(response))
                } else {
                    setSuccessModal('Заявка успешно изменена')
                }
            } else {
                const response = await dispatch(createBid(data))

                if (response.error) {
                    setErrorModal(getErrorMessageFromResponse(response))
                } else {
                    setSuccessModal('Заявка успешно добавлена')
                }
            }
        }
    })

    // стейт для хранения типов ТС в виде {id, name}
    const [vehicleTypes, setVehicleTypes] = useState({
        data: [],
        loading: true,
    })

    // стейт для хранения ТС в виде {id, name}
    // запрос принимает в себя параметры:
    // relation_subject__subject__id - id объекта, к которому прикреплено ТС,
    // type__name - наименование типа ТС
    const [vehicles, fetchVehicles] = useLoadableData(
        getAPI.getVehicles,
        {relation_subject__subject__id: formik.values.subject?.id, type__name: formik.values.type_vehicle?.name},
        error => setErrorModal(error),
        vehicles => vehicles.map(vehicle => ({
            id: vehicle.id,
            name: vehicle.show_name
        })))

    // стейт для хранения объектов в виде {id, name, time_zone}
    // запрос принимает в себя параметры:
    // executors - id исполнителя, к которому прикреплен объект,
    const [objects, fetchObjects] = useLoadableData(
        getAPI.getObjects,
        {executors: formik.values.executor.id || ''},
        error => setErrorModal(error),
        objects => objects.map(object => ({
            id: object.id,
            name: object.show_name,
            time_zone: object.time_zone
        })))

    const [successModal, setSuccessModal] = useState('')
    const [errorModal, setErrorModal] = useState('')

    const [time, setTime] = useState('')

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])


    // Проверка доступа. Если доступа нет, включается режим просмотра
    const fetchAccess = async () => {
        const response = await getAPI.editBidAccess(params.id)

        if (response.error) {
            setIsWatch(true)
            setTitle('Просмотр заявки')
        } else {
            setIsWatch(false)
        }
    }

    const fetchVehicleTypes = async () => {
        setVehicleTypes(prev => ({...prev, loading: true}))
        if (!formik.values.subject) {
            setVehicleTypes(prev => ({...prev, data: []}))
        } else {
            const response = await getAPI.getObjectById(formik.values.subject?.id)

            if (response.error) {
                setVehicleTypes(prev => ({...prev, data: []}))
            } else {
                setVehicleTypes(prev => ({
                    ...prev,
                    data: response.types.map(type => ({id: `${type.id}`, name: type.name}))
                }))
            }
        }
        setVehicleTypes(prev => ({...prev, loading: false}))
    }


    // Получение данных о текущей заявке
    const fetchBid = async () => {
        const bid = await getAPI.getBidById(params.id)

        // если заявка еще не создана, то необходимо при наличии запарсить объект из запроса
        if (bid.error) {
            if (query.get('object')) {
                const object = await getAPI.getObjectById(query.get('object'))

                if (object.error) {
                    setErrorModal(getErrorMessageFromResponse(object))
                } else {
                    await formik.setFieldValue('subject', {
                        id: object.id,
                        name: object.show_name,
                        time_zone: object.time_zone
                    })
                }
            }
            return
        }

        const [date_closed_time, date_closed_date] = [timeFromISO(bid.date_closed).time, timeFromISO(bid.date_closed).date]

        setFirstChosenObjectId(bid.subject?.id)

        await formik.setValues(
            bidSchema.cast({
                ...bid,
                subject: bid.subject ? {
                    id: bid.subject.id,
                    name: bid.subject.show_name,
                    time_zone: bid.subject.time_zone
                } : null,
                vehicle: bid.vehicle ? {
                    id: bid.vehicle?.id,
                    name: `${bid.vehicle?.brand?.name} ${bid.vehicle?.model} | ${bid.vehicle?.registration_number}`,
                } : null,
                date_finish: bid.date_finish ? `${timeFromISO(bid.date_finish).date} ${timeFromISO(bid.date_finish).time}` : '',
                date_closed_time,
                date_closed_date,
            }))

        await fetchAccess()
    }

    //------------------------------------------------------------------------------------------------------------------
    // сохранение в стейт id объекта, ТС и типа ТС из запроса
    // TODO необходимо пераработать, т.к. теперь данные хранятся в виде {id, name}

    useEffect(() => {
        if (!query.get('object')) return
        formik.setFieldValue('subject', query.get('object'))
    }, [query.get('object')])

    useEffect(() => {
        if (!query.get('vehicle')) return
        formik.setFieldValue('vehicle', query.get('vehicle'))
    }, [query.get('vehicle')])

    useEffect(() => {
        if (!query.get('type')) return
        formik.setFieldValue('type_vehicle', query.get('type'))
    }, [query.get('type')])
    //------------------------------------------------------------------------------------------------------------------

    // Типы ТС запрашиваются при условии, что выбран объект
    useEffect(() => {
        if (!formik.values.subject?.id) return
        fetchVehicleTypes()
    }, [formik.values.subject?.id])

    // ТС запрашивается при условии, что выбран объект и тип
    useEffect(() => {
        if (!formik.values.subject?.id || !formik.values.type_vehicle?.name) return
        fetchVehicles()
    }, [formik.values.subject?.id, formik.values.type_vehicle])

    // Обнуление выбранного ТС и типа ТС при смене объекта
    useEffect(() => {
        if (firstRender) return
        if (formik.values.subject?.id === chosenFirstObjectId) return;
        formik.setFieldValue('vehicle', null)
        formik.setFieldValue('type_vehicle', null)
    }, [formik.values.subject?.id])


    // Обновление текущего времени объекта
    useEffect(() => {
        const getTime = () => {
            setTime(moment().utcOffset(staticData.object.timezones.find(tz => tz.name === formik.values.subject?.time_zone)?.value, false).format('DD.MM.YYYY HH:mm') + ' ' + formik.values.subject?.time_zone)
        }
        setTimeout(getTime, 100)
        const interval = setInterval(getTime, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [formik.values.subject])


    // Запрос данных о текущей заявке
    useEffect(() => {
        (async () => {
            setLoading(true)
            await fetchBid()
            setLoading(false)

            await fetchObjects()
        })()
    }, [params.id, query.get('object')])

    // Обновление поля date_closed при изменении полей date_closed_time и date_closed_date
    useEffect(() => {
        formik.setFieldValue('date_closed', timeToISO(formik.values.date_closed_date, formik.values.date_closed_time || undefined))
    }, [formik.values.date_closed_time, formik.values.date_closed_date])

    return (
        <>
            <Modal close={close} title={title} style={style} scroll={scroll} isLoading={isLoading} isShowing>
                <div className={classes.header}>
                    <div className={classes.header_id}>
                        <p>ID</p>
                        <p>{formik.values.id}</p>
                    </div>
                    <div className={classes.header_info}>
                        <div className={classes.header_info_column}>
                            <b>Дата и время на объекте</b>
                            <p>{formik.values.subject ? time : 'Объект не выбран'}</p>
                        </div>
                        <div className={classes.header_info_column}>
                            <b>Статус</b>
                            <p>{formik.values.status}</p>
                        </div>
                    </div>
                </div>
                {formik.errors.message ? <div className='form_error_message'>
                    <p>{formik.errors.message}</p>
                </div> : null}
                <div className='form'>
                    <div className='form_columns'>
                        <div className='form_columns_column'>
                            {/*executor_name*/}
                            <TextInput onChange={formik.handleChange} name={'executor_fullname'}
                                       id={'executor_fullname'}
                                       value={formik.values.executor?.full_name || `${formik.values.executor?.last_name} ${formik.values.executor?.first_name}`}
                                       disabled label='ФИО заявителя'/>
                            {/*executor_phone*/}
                            <TextInput onChange={formik.handleChange} name={'executor_phone'} id={'executor_phone'}
                                       value={formik.values.executor?.phone}
                                       disabled label='Телефон заявителя'/>
                            {/*subject*/}
                            <DropdownInput
                                id='subject'
                                name='subject'
                                onChange={subject => formik.setFieldValue('subject', subject)}
                                value={formik.values.subject}
                                disabled={isWatch}
                                label='Объект'
                                isRequired
                                error={formik.errors.subject}
                                items={[
                                    {id: '-1', name: 'Не выбрано', value: null},
                                    ...objects.data.map(object => ({
                                        id: object.id,
                                        name: object.name,
                                        value: object
                                    }))]}
                                loading={objects.loading}
                                onScrollBottom={async () => fetchObjects()}
                                displayText={formik.values.subject?.name || 'Не выбрано'}
                            />
                            {/*type_vehicle*/}
                            <DropdownInput
                                id='type_vehicle'
                                name='type_vehicle'
                                onChange={type => formik.setFieldValue('type_vehicle', type)}
                                value={formik.values.type_vehicle}
                                disabled={isWatch}
                                label='Тип ТС'
                                isRequired
                                error={formik.errors.type_vehicle}
                                items={[
                                    {id: '-1', name: 'Не выбрано', value: null},
                                    ...vehicleTypes.data.map(type => ({...type, value: type}))
                                ]}
                                loading={vehicleTypes.loading}
                                displayText={formik.values.type_vehicle?.name || 'Не выбрано'}
                            />
                            {/*vehicle*/}
                            <DropdownInput
                                id='vehicle'
                                name='vehicle'
                                onChange={vehicle => formik.setFieldValue('vehicle', vehicle)}
                                value={formik.values.vehicle}
                                disabled={isWatch}
                                label='ТС'
                                error={formik.errors.vehicle}
                                items={[
                                    {id: '-1', name: 'Не выбрано', value: null},
                                    ...vehicles.data.map(vehicle => ({...vehicle, value: vehicle}))
                                ]}
                                loading={vehicles.loading}
                                displayText={formik.values.vehicle?.name || 'Не выбрано'}
                            />
                            {/*description*/}
                            <TextareaInput onChange={formik.handleChange} name='description' id='description'
                                           value={formik.values.description}
                                           inputStyle={{padding: '5px 14px', height: '150px'}}
                                           disabled={isWatch} label='Описание' error={formik.errors.description}
                                           placeholder='Описание'/>
                        </div>
                        <div className='form_columns_column'>
                            {/*date_closed*/}
                            <DateInput
                                value={formik.values.date_closed_date}
                                onChange={(date) => formik.setFieldValue('date_closed_date', date)}
                                inputStyles={{padding: '0 10px'}}
                                disabled={isWatch}
                                isRequired
                                label='Дата окончания работ'
                                name='date_closed_date' id='date_closed_date'
                                error={formik.errors.date_closed_date}
                                innerStyles={{width: '110px'}}

                            />
                            <TextInput onChange={formik.handleChange} name='date_closed_time' id='date_closed_time'
                                       value={formik.values.date_closed_time} inputStyle={{paddingLeft: '10px'}}
                                       placeholder='00:00' outerStyle={{width: '110px'}}
                                       disabled={isWatch} label='Время окончания работ'
                                       error={formik.errors.date_closed_time}
                                       mask={'99:99'}/>
                            {/*add_req_vehicle*/}
                            <TextareaInput onChange={formik.handleChange} name='add_req_vehicle' id='add_req_vehicle'
                                           value={formik.values.add_req_vehicle}
                                           inputStyle={{padding: '5px 14px', height: '105px'}}
                                           disabled={isWatch} label='Доп.требования к ТС'
                                           error={formik.errors.add_req_vehicle}
                                           placeholder='Доп.требования'/>
                            {/*add_req_driver*/}
                            <TextareaInput onChange={formik.handleChange} name='add_req_driver' id='add_req_driver'
                                           value={formik.values.add_req_driver}
                                           inputStyle={{padding: '5px 14px', height: '105px'}}
                                           disabled={isWatch} label='Доп.требования к водителю'
                                           error={formik.errors.add_req_driver} placeholder='Доп.требования'/>
                            {/*date_finish*/}
                            {formik.values.date_finish &&
                                <TextInput onChange={formik.handleChange} name='date_finish' id='date_finish'
                                           error={formik.errors.date_finish} value={formik.values.date_finish}
                                           label='Дата завершения работ' disabled/>}
                        </div>
                    </div>
                </div>
                {!isWatch && <SubmitMenu close={close} saveHandler={formik.submitForm}/>}
            </Modal>
            {successModal && <SuccessModal close={() => {
                setSuccessModal('')
                close()
            }} description={successModal} title='Успех' isShowing={successModal}/>}
            {errorModal && <SuccessModal close={() => setErrorModal('')} description={errorModal} title='Ошибка' isError
                                         isShowing={errorModal}/>}
        </>
    )
}

export default BidModal
