import React from "react";
import classes from './StateButton.module.sass'
import clsx from "clsx";

const StateButton = ({active, disabled, label, onClick}) => {

    return (
        <div className={clsx(classes.button, active && classes.button_active, disabled && classes.button_disabled)}
             onClick={onClick}
        >
            {label}
        </div>
    )
}

export default StateButton
