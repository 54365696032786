import {
    BIDS_ADD_BIDS,
    BIDS_SET_BIDS, BIDS_SET_LOADING,
    BIDS_SET_NEXT,
    BIDS_SET_RENDER
} from "../types";
import {getAPI} from "../../api/api";
import {store} from "../store";

export const getBids = params => async dispatch => {
    dispatch(setLoading(true))

    const response = await getAPI.getBids(params)
    if (!response.error) {
        dispatch(setBids(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const suppleBids = () => async dispatch => {
    const {next} = store.getState().Bids

    if(!next) return

    dispatch(setLoading(true))

    const response = await getAPI.getRequest(next)
    if (!response.error) {
        dispatch(addBids(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const createBid = data => async dispatch => {
    const response = await getAPI.createBid(data)
    if (!response.error) dispatch(setRender())

    return response
}

export const editBid = (id, data) => async dispatch => {
    const response = await getAPI.editBid(id, data)
    if (!response.error) dispatch(setRender())

    return response
}

export const deleteBid = bids => async dispatch => {
    const response = await getAPI.deleteBid(bids)
    if (!response.error) dispatch(setRender())

    return response
}

export const setRender = () => ({type: BIDS_SET_RENDER})

const setBids = bids => ({type: BIDS_SET_BIDS, payload: {bids}})

const addBids = bids => ({type: BIDS_ADD_BIDS, payload: {bids}})

const setLoading = loading => ({type: BIDS_SET_LOADING, payload: {loading}})

const setNext = next => ({type: BIDS_SET_NEXT, payload: {next}})
