import {useRef} from "react";


// Хук возвращающий true, если элемент зарендерился впервые
const useFirstRender = () => {
    const ref = useRef(true)
    const firstRender = ref.current
    ref.current = false
    return firstRender
}

export default useFirstRender