import React from "react";
import Error from "../pages/Error";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: '', errorInfo: ''}
    }

    static getDerivedStateFromError(error){
        return {hasError: true}
    }

    componentDidCatch(error, errorInfo) {
        this.setState({...this.state, error, errorInfo})
    }

    render() {
        if(this.state.hasError){
            return <Error title='Произошла ошибка' description={this.state.error.stack}/>
        }

        return this.props.children
    }
}

export default ErrorBoundary
