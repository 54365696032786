import {useEffect, useState} from "react";
import Cookies from "js-cookie";

const useCookie = cookieName => {
    const [value, setValue] = useState(Cookies.get(cookieName) || null)

    useEffect(() => {
        const cookie = Cookies.get(cookieName)
        if (cookie) {
            updateCookie(cookie)
        } else {
            updateCookie(null)
        }
    }, [])

    useEffect(() => {
        const checkCookie = () => {
            if (value !== Cookies.get(cookieName)) {
                updateCookie(Cookies.get(cookieName))
            }
        }

        const checkCookieInterval = setInterval(checkCookie, 500)

        return () => {
            clearInterval(checkCookieInterval)
        }
    }, [])

    const updateCookie = (newValue) => {
        if(!newValue || newValue == 'undefined' || newValue == 'null'){
            deleteCookie()
            setValue(undefined)
            return
        }
        Cookies.set(cookieName, newValue)
        setValue(newValue)
    }

    const deleteCookie = () => {
        Cookies.remove(cookieName)
    }

    return [value, updateCookie, deleteCookie]
}

export default useCookie
