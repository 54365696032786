import React, {useEffect, useState} from "react";
import classes from "./VerificationModal.module.sass";
import clsx from "clsx";
import {Modal, SuccessModal} from "../Modal";
import InputMask from "react-input-mask";
import {getErrorMessageFromResponse} from "../../../utils/errors";
import {getAPI} from "../../../api/api";
import Button from "../../UI/Button";

const VerificationModal = ({close, phone, passHandler, isShowing, submit}) => {

    const style = {
        width: '420px',
        height: '400px'
    }

    const [code, setCode] = useState('')
    const [timer, setTimer] = useState(120)
    const [errorModal, setErrorModal] = useState('')
    const [successModal, setSuccessModal] = useState('')

    const submitHandler = async () => {
        const response = !!submit ? await submit(phone, code) : await getAPI.phoneVerify({phone, code})

        if (response.error) {
            setErrorModal(getErrorMessageFromResponse(response))
        } else {
            setSuccessModal('Номер телефона подтвержден')
        }
    }

    const sendCodeHandler = async () => {
        const response = await getAPI.sendPhoneCode({phone})
        if(response.error){
            setErrorModal(getErrorMessageFromResponse(response))
        } else {
            setTimer(120)
        }
    }

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (timer === 0) return
            setTimer(timer - 1)
        }, 1000)

        return () => clearTimeout(timeOut)
    }, [timer])

    useEffect(()=>{
        if(isShowing) setTimer(120)
    },[isShowing])

    return (
        <>
            <Modal close={close} style={style} title='Подтверждение номера телефона' isShowing={isShowing}>
                <div className={classes.text}>
                    <p>На указанный вами номер телефона было отправлено SMS с кодом подтверждения.
                        Введите полученый код, чтобы подтвердить номер телефона.</p>
                </div>
                <div className={classes.input}>
                    <input value={phone} disabled/>
                </div>
                <div className={classes.sms}>
                    <div className={classes.sms_text}>
                        <p>Код из SMS</p>
                    </div>
                    <div className={clsx(classes.sms_input)}>
                        <InputMask mask={'9999'} placeholder='____' type="text"
                                   className={clsx(errorModal && classes.error)} value={code}
                                   onChange={event => setCode(event.target.value)}/>
                    </div>
                </div>
                <div className={clsx(classes.timer, timer && classes.timer_inactive)}>
                    {timer ? <p>Отправить повторно
                        через {new Date(timer * 1000).toISOString().substr(14, 5)}</p>
                    : <p onClick={sendCodeHandler}><span>Отправить SMS повторно</span></p>
                    }
                </div>
                <div className={classes.submit}>
                    <Button
                        disabled={code.split('').filter(e => e !== '_').length < 4}
                        onClick={submitHandler} text='Подтвердить'>
                    </Button>
                </div>
            </Modal>
            <SuccessModal close={() => setErrorModal('')} description={errorModal} title='Ошибка' isError
                          isShowing={errorModal}/>
            <SuccessModal close={() => {
                setSuccessModal('');
                passHandler();
                close()
            }} description={successModal} title='Успех' isShowing={successModal}/>
        </>
    )
}

export default VerificationModal
