import {
    BIDS_ADD_BIDS,
    BIDS_SET_BIDS,
    BIDS_SET_LOADING, BIDS_SET_NEXT,
    BIDS_SET_RENDER,
} from "../types";

const Bids = (state = {
    bids: [],
    render: 0,
    loading: false,
    next: null
}, action) => {
    switch (action.type) {
        case BIDS_SET_BIDS:
            return {
                ...state,
                bids: action.payload.bids
            }
        case BIDS_SET_RENDER:
            return {
                ...state,
                render: state.render + 1
            }
        case BIDS_ADD_BIDS:
            return {
                ...state,
                bids: [...state.bids, ...action.payload.bids]
            }
        case BIDS_SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case BIDS_SET_NEXT:
            return {
                ...state,
                next: action.payload.next
            }
        default:
            return state
    }
}

export default Bids