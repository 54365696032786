import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {Link} from "react-router-dom";

const CheckboxInput = ({
                           title,
                           label,
                           onChange,
                           checked,
                           disabled,
                           id,
                           name,
                           children,
                           error,
                           isRequired,
                           titleStyle,
                           link
                       }) => {

    return (
        <div className='form_control'>
            {title && <div className='form_control_label'>
                <label htmlFor={id} style={titleStyle}>{title} {isRequired && <span>*</span>}</label>
            </div>}
            <div className={clsx('form_control_checkbox', error && 'form_control_checkbox_error')}>
                <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} id={id}
                       name={name}/>
                {children}
                {label && link ?
                    <Link to={link} target='_blank' htmlFor={id}>{label} {isRequired && <span>*</span>}</Link> :
                    <label htmlFor={id}>{label} {isRequired && <span>*</span>}</label>}
            </div>
        </div>
    )
}

CheckboxInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    title: PropTypes.string
}

export default CheckboxInput
