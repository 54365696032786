import * as yup from 'yup'
import masks from "../utils/masks";

const monitoringSchema = yup.object().shape({
    id: yup.string().required(),
    brand_monitoring: yup.string().default('').nullable(true),
    id_monitoring: yup.string().default('').required('Заполните обязательные поля').nullable(true),
    imei: yup.string().default('').test('checkImeiLength','Некорректный IMEI', imei => !imei || imei.length >= 15).nullable(true),
    name_monitoring: yup.string().nullable(true).default(''),
    phone_support: yup.string().nullable(true).default('').test('checkPhone', 'Некорректный номер телефона', phone => !phone || masks.phone.test(phone)),
    email_support: yup.string().nullable(true).default('').test('checkEmail', 'Некорректный email', email => !email || masks.email.test(email)),
    connect: yup.boolean().default(false),
    relay_protocol: yup.string().nullable(true).default('Авто'),
    operator: yup.string().nullable(true).default('')
})

export default monitoringSchema