import React from "react";
import Dropdown from "../../UI/Dropdown";
import {MenuItem} from "../../UI/Dropdown/Dropdown";

const DropdownInput = React.forwardRef(({
                                            outerStyle,
                                            innerStyle,
                                            labelStyle,
                                            label,
                                            items,
                                            isRequired,
                                            id,
                                            error,
                                            emptyListMessage,
                                            disabled,
                                            name,
                                            value,
                                            onChange,
                                            dropdownLabel,
                                            inputStyle,
                                            itemStyle,
                                            isMultiple,
                                            loading,
                                            displayText,
                                            onScrollBottom,
                                            children
                                        }, ref) => {

    return (
        <div className='form_control' style={outerStyle} ref={ref}>
            {label && <div className='form_control_label'>
                <label htmlFor={id} style={labelStyle}>{label} {isRequired && <span>*</span>}</label>
            </div>}
            <div className='form_control_input' style={innerStyle}>
                <Dropdown
                    id={id}
                    name={name}
                    error={error}
                    onChange={onChange}
                    value={value}
                    label={dropdownLabel}
                    disabled={disabled}
                    style={inputStyle}
                    isMultiple={isMultiple}
                    loading={loading}
                    onScrollBottom={onScrollBottom}
                    displayText={displayText}
                >
                    {!items.length && <MenuItem value='' disabled>{emptyListMessage || 'Список пуст'}</MenuItem>}
                    {items.map(item => (
                        <MenuItem style={itemStyle} id={item.id} name={item.name} value={item.value}
                                  key={item.id}>{item.name}</MenuItem>
                    ))}
                </Dropdown>
                {children}
            </div>
        </div>
    )
})

export default DropdownInput