import {getAPI} from "../../api/api";
import {
    OBJECTS_ADD_OBJECTS,
    OBJECTS_SET_LOADING,
    OBJECTS_SET_NEXT,
    OBJECTS_SET_OBJECTS,
    OBJECTS_SET_RENDER
} from "../types";
import {store} from '../store'

export const getObjects = params => async dispatch => {
    dispatch(setLoading(true))

    const response = await getAPI.getObjects(params)
    if (!response.error) {
        dispatch(setObjects(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const suppleObjects = () => async dispatch => {
    const {next} = store.getState().Objects

    if(!next) return

    dispatch(setLoading(true))

    const response = await getAPI.getRequest(next)
    if (!response.error) {
        dispatch(addObjects(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const createObject = data => async dispatch => {
    const response = await getAPI.createObject(data)
    if (!response.error) dispatch(setRender())

    return response
}

export const editObject = (id, data) => async dispatch => {
    const response = await getAPI.editObject(id, data)
    if (!response.error) dispatch(setRender())

    return response
}

export const deleteObjects = objects => async dispatch => {
    const response = await getAPI.deleteObjects(objects)
    if (!response.error) dispatch(setRender())

    return response
}

const setObjects = objects => ({type: OBJECTS_SET_OBJECTS, payload: {objects}})

const addObjects = objects => ({type: OBJECTS_ADD_OBJECTS, payload: {objects}})

const setLoading = loading => ({type: OBJECTS_SET_LOADING, payload: {loading}})

const setNext = next => ({type: OBJECTS_SET_NEXT, payload: {next}})

export const setRender = () => ({type: OBJECTS_SET_RENDER})
