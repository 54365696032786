import {
    DRIVERS_ADD_DRIVERS,
    DRIVERS_SET_DRIVERS,
    DRIVERS_SET_LOADING,
    DRIVERS_SET_NEXT,
    DRIVERS_SET_RENDER,
} from "../types";

const Drivers = (state = {
    drivers: [],
    render: 0,
    loading: false,
    next: null
}, action) => {
    switch (action.type) {
        case DRIVERS_SET_DRIVERS:
            return {
                ...state,
                drivers: action.payload.drivers
            }
        case DRIVERS_SET_RENDER:
            return {
                ...state,
                render: state.render + 1
            }
        case DRIVERS_ADD_DRIVERS:
            return {
                ...state,
                drivers: [...state.drivers, ...action.payload.drivers]
            }
        case DRIVERS_SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case DRIVERS_SET_NEXT:
            return {
                ...state,
                next: action.payload.next
            }
        default:
            return state
    }
}

export default Drivers