import React, {useState} from "react";
import classes from './Button.module.sass'
import clsx from "clsx";

const Types = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DARK: 'dark'
}

const Button = ({
                    id,
                    text,
                    icon,
                    disabled,
                    onClick = () => {
                    },
                    style = {},
                    innerStyle = {},
                    textStyle = {},
                    iconStyle = {},
                    fontSize,
                    children,
                    type = Types.PRIMARY
                }) => {

    const [clicked, setClicked] = useState(false)

    const primaryTextStyles = {
        'textAlign': 'center',
        'fontFamily': 'PTRootUIRegular',
        'fontSize': fontSize || '17px',
        'lineHeight': '21px',
        'color': '#fff',
        'margin': 0,
    }

    const secondaryTextStyles = {
        'textAlign': 'center',
        'fontFamily': 'PTRootUIRegular',
        'fontSize': fontSize || '17px',
        'lineHeight': '21px',
        'color': '#000',
        'margin': 0
    }

    const darkTextStyles = {
        'textAlign': 'center',
        'fontFamily': 'PTRootUIRegular',
        'fontSize': fontSize || '17px',
        'lineHeight': '21px',
        'color': '#fff',
        'margin': 0
    }

    const clickHandler = async event => {
        if(disabled || clicked) return
        setClicked(true)
        await onClick(event)
        setClicked(false)
    }

    const PrimaryButton = (
        <div
            id={id}
            className={clsx(classes.button, classes.primary, (disabled || clicked) && classes.primary_disabled)}
            style={style}
            onClick={clickHandler}
        >
            <div className={classes.button_content} style={innerStyle}>
                {icon && <div className={classes.button_content_icon} style={iconStyle}>
                    {icon}
                </div>}
                <p style={{...primaryTextStyles, ...textStyle}}>{text}</p>
                {children}
            </div>
        </div>
    )

    const SecondaryButton = (
        <div
            id={id}
            className={clsx(classes.button, classes.secondary, (disabled || clicked) && classes.secondary_disabled)}
            style={style}
            onClick={clickHandler}
        >
            <div className={classes.button_content} style={innerStyle}>
                {icon && <div className={classes.button_content_icon} style={iconStyle}>
                    {icon}
                </div>}
                <p style={{...secondaryTextStyles, ...textStyle}}>{text}</p>
                {children}
            </div>
        </div>
    )

    const DarkButton = (
        <div
            id={id}
            className={clsx(classes.button, classes.dark, (disabled || clicked) && classes.dark_disabled)}
            style={style}
            onClick={clickHandler}
        >
            <div className={classes.button_content} style={innerStyle}>
                {icon && <div className={classes.button_content_icon} style={iconStyle}>
                    {icon}
                </div>}
                <p style={{...darkTextStyles, ...textStyle}}>{text}</p>
                {children}
            </div>
        </div>
    )

    switch (type) {
        case Types.PRIMARY:
            return PrimaryButton
        case Types.SECONDARY:
            return SecondaryButton
        case Types.DARK:
            return DarkButton
        default:
            return PrimaryButton
    }
}

export default Button
