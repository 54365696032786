import {ReactComponent as Objects} from "./assets/navigation/objects.svg";
import {ReactComponent as Applications} from "./assets/navigation/applications.svg";
import {ReactComponent as Vehicles} from "./assets/navigation/vehicles.svg";
import {ReactComponent as Drivers} from "./assets/navigation/drivers.svg";
import {ReactComponent as Search} from "./assets/navigation/search.svg";
import {ReactComponent as Contractors} from "./assets/navigation/contractors.svg";
import {ReactComponent as Users} from "./assets/navigation/users.svg";
import React from "react";

export const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL


//NAVBAR -----------------------------------------------------------------
export const navbarItems = account => {
    if(account === 'Администратор'){
        return [
            {name: 'Объекты', path: '/dashboard/objects', icon:<Objects/>},
            {name: 'Заявки', path: '/dashboard/bids', icon:<Applications/>},
            {name: 'Транспортные средства', path: '/dashboard/vehicles', icon:<Vehicles/>},
            {name: 'Водители', path: '/dashboard/drivers', icon:<Drivers/>},
            {name: 'Поиск', path: '/dashboard/search', icon:<Search/>},
            {name: 'Контрагенты', path: '/dashboard/companies', icon:<Contractors/>},
            {name: 'Пользователи', path: '/dashboard/users', icon:<Users/>},
        ]
    }

    if(account === 'Пользователь'){
        return [
            {name: 'Объекты', path: '/dashboard/objects', icon:<Objects/>},
            {name: 'Заявки', path: '/dashboard/bids', icon:<Applications/>},
            {name: 'Транспортные средства', path: '/dashboard/vehicles', icon:<Vehicles/>},
            {name: 'Водители', path: '/dashboard/drivers', icon:<Drivers/>},
            {name: 'Поиск', path: '/dashboard/search', icon:<Search/>},
            {name: 'Контрагенты', path: '/dashboard/companies', icon:<Contractors/>},
        ]
    }

    return []
}

//MAP---------------------------------------------------------------------

export const YANDEX_MAP_API_KEY = process.env.REACT_APP_YANDEX_MAP_API_KEY
export const PELENGATION_TIME_IN_SECONDS = parseInt(process.env.REACT_APP_PELENGATION_TIME_IN_SECONDS)

