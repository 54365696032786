import * as yup from 'yup'
import masks from "../utils/masks";

export const personalSchema = yup.object().shape({
    phone: yup.string().required('Заполните обязательные поля').matches(masks.phone, 'Неверный номер телефона').default(''),
    email: yup.string().nullable(true).default('').test('checkEmail', 'Некорректный email', email => !email || masks.email.test(email)),
    first_name: yup.string().required('Заполните обязательные поля').default(''),
    last_name: yup.string().required('Заполните обязательные поля').default(''),
    middle_name: yup.string().nullable(true).default(''),
    account: yup.string().required('Заполните обязательные поля').default('')
})

export const companySchema = yup.object().shape({
    shortname_ru: yup.string().required('Заполните обязательные поля').default(''),
    tin: yup.string().required('Заполните обязательные поля').test('checkTinLength', 'Некорректный ИНН', tin => !tin || tin.length === 10 || tin.length === 12),
    payment_account: yup.string().default('').nullable(true).test('checkPayAcc', 'Некорректный расчетный счет', value => !value || value.length >= 20),
    correspondent_account: yup.string().default('').nullable(true).test('checkCorAcc', 'Некорректный корреспондентский счет', value => !value || value.length >= 20),
    BIC: yup.string().nullable(true).test('checkBIClength', 'Некорректный БИК', BIC => !BIC || BIC.length === 9),
    phone: yup.string().test('checkPhone', 'Неверный номер телефона', phone => !phone || masks.phone.test(phone)).default('').nullable(true),
    email: yup.string().nullable(true).default('').test('checkEmail', 'Некорректный email', email => !email || masks.email.test(email)),
})

export const configurationSchema = yup.object().shape({
    rate: yup.string().required('Заполните обязательные поля').default('')
})