import React, {useRef} from "react";
import classes from './ActionMenu.module.sass'
import PropTypes from "prop-types";
import clsx from "clsx";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const ActionMenu = ({close, actionList, style}) => {

    const actionMenuRef = useRef(null)

    useOnClickOutside(actionMenuRef, close)

    return (
        <div style={style} className={classes.actions} data-menu="action" ref={actionMenuRef}>
            {actionList?.map(action => (
                <div className={clsx(classes.actions_item, action.disabled && classes.disabled)}
                     key={action.id}
                     onClick={() => {
                         if (action.disabled) return
                         action.handler();
                     }}>
                    {action?.icon && action.icon}
                    <p>{action.name}</p>
                </div>
            ))}
        </div>
    )
}

ActionMenu.propTypes = {
    actionList: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            name: PropTypes.string.isRequired,
            icon: PropTypes.node,
            disabled: PropTypes.func.isRequired,
            handler: PropTypes.func.isRequired
        }).isRequired,
    ),
    close: PropTypes.func.isRequired
}

export default ActionMenu
