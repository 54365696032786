import {createContext, useEffect, useReducer} from "react";
import axios from "axios";
import {getAPI} from "../api/api";
import useCookie from "../hooks/useCookie";

const Types = {
    Initial: 'INITIALIZE',
    Login: 'LOGIN',
    Logout: 'LOGOUT',
    SetUser: 'SET_USER',
    Render: 'RENDER'
}

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: {},
    render: 0
}

const AuthReducer = (state, action) => {
    switch (action.type) {
        case Types.Initial:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user
            }
        case Types.Login:
            return {
                ...state,
                isAuthenticated: true,
            }
        case Types.Logout:
            return {
                ...state,
                isAuthenticated: false,
                user: {}
            }
        case Types.SetUser:
            return {
                ...state,
                user: action.payload.user
            }
        case Types.Render:
            return {
                ...state,
                render: state.render + 1
            }

        default:
            return state
    }
}

const AuthContext = createContext(null)

const AuthProvider = ({children}) => {
    const [accessToken, updateAccessToken, deleteAccessToken] = useCookie('access')
    const [state, dispatch] = useReducer(AuthReducer, initialState)

    useEffect(() => {
        const initialize = async () => {
            try {
                if (accessToken) {
                    setSession(accessToken)

                    const user = await getAPI.getCurrentUser()

                    dispatch({
                        type: Types.Initial,
                        payload: {
                            isAuthenticated: true,
                            user
                        }
                    })
                } else {
                    dispatch({
                        type: Types.Initial,
                        payload: {
                            isAuthenticated: false,
                            user: {}
                        }
                    })
                }
            } catch (e) {
                dispatch({
                    type: Types.Initial,
                    payload: {
                        isAuthenticated: false,
                        user: {}
                    }
                })
            }
        }

        initialize()
    }, [])

    useEffect(()=>{
        if(state.isInitialized && !accessToken){
            logout()
        }
    },[accessToken])

    useEffect(() => {
        if(state.isAuthenticated){
            (async () => {
                const user = await getAPI.getCurrentUser()

                if(user.error) dispatch({type: Types.SetUser, payload: {user: {}}})

                dispatch({type: Types.SetUser, payload: {user}})
            })()
        }
    }, [state.isAuthenticated, state.render])

    const setSession = accessToken => {
        if (accessToken) {
            updateAccessToken(accessToken)
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        } else {
            deleteAccessToken()
            delete axios.defaults.headers.common.Authorization
        }
    }

    const login = async (phone, password) => {
        const response = await getAPI.auth({phone, password})

        if(response.error){
            return response
        }

        const {access, refresh} = response

        setSession(access)
        //TODO connect refresh token

        dispatch({type: Types.Login})

        return response
    }

    const logout = async () => {
        setSession(null)
        dispatch({type: Types.Logout})
    }

    const setRender = () => {
        dispatch({type: Types.Render})
    }


    return (
        <AuthContext.Provider value={{...state, login, logout, setRender}}>
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider}
