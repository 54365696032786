import useAuth from "../hooks/useAuth";
import {Navigate, useLocation} from "react-router-dom";
import {useState} from "react";
import LoadingScreen from "../components/LoadingScreen";

// Данный гард необходим для перенаправления пользователя на изначально
// запрошенный ресурс после прохождения авторизации
const AuthGuard = ({children}) => {

    const {isAuthenticated, isInitialized} = useAuth()

    const {pathname} = useLocation()

    const [requestedLocation, setRequestedLocation] = useState(null)

    if (!isInitialized) return <LoadingScreen/>

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }
        return <Navigate to='/auth/login'/>
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation}/>
    }

    return <>{children}</>
}

export default AuthGuard
