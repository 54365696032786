import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

const TextareaInput = ({
                           id,
                           name = '',
                           disabled = false,
                           onChange,
                           value,
                           error,
                           label = '',
                           inputStyle = {},
                           labelStyle = {},
                           innerStyle = {},
                           outerStyle = {},
                           placeholder = '',
                           isRequired = false,
                           maxLength = 250
                       }) => {

    return (
        <div className='form_control' style={outerStyle}>
            <div className='form_control_label'>
                <label htmlFor={id} style={labelStyle}>{label} {isRequired && <span>*</span>}</label>
            </div>
            <div className={clsx('form_control_input', error && 'form_control_input_error')} style={innerStyle}>
                <textarea type='text' id={id} name={name} style={inputStyle} disabled={disabled}
                          onChange={onChange} value={value} placeholder={placeholder} maxLength={maxLength}/>
            </div>
        </div>
    )
}

TextareaInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    error: PropTypes.any,
    label: PropTypes.string,
    style: PropTypes.shape({}),
    inputStyle: PropTypes.shape({}),
    labelStyle: PropTypes.shape({}),
    innerStyle: PropTypes.shape({}),
    outerStyle: PropTypes.shape({}),
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    maxLength: PropTypes.number
}

export default TextareaInput
