import React from "react";
import {Modal, SubmitMenu} from "../Modal";
import {ReactComponent as Phone} from "../../../assets/auth/login.svg";
import {useFormik} from "formik";
import masks from "../../../utils/masks";
import TextInput from "../../FormControls/TextInput";

const PhoneModal = ({close, resolve, isShowing}) => {

    const style = {
        width: '420px'
    }

    const formik = useFormik({
        initialValues: {
            phone: ''
        }
    })

    const submitHandler = async () => {
        if (!formik.values.phone) {
            formik.setErrors({phone: 'error', message: 'Поле не может быть пустым'})
            return
        }

        if (!masks.phone.test(formik.values.phone)) {
            formik.setErrors({phone: 'error', message: 'Неверный номер телефона'})
            return
        }

        await resolve(formik.values.phone)
    }

    return (
        <Modal close={close} title='Введите номер телефона' style={style} isShowing={isShowing}>
            {formik.errors.message ? <div className='form_error_message'>
                <p>{formik.errors.message}</p>
            </div> : null}
            <TextInput onChange={formik.handleChange} name='phone' id='phone' value={formik.values.phone}
                       error={formik.errors.phone} icon={<Phone/>} placeholder='+79999999999' mask='+79999999999'
                       label='Номер телефона'/>
            <SubmitMenu close={close} saveHandler={submitHandler} saveText='Продолжить'/>
        </Modal>
    )
}

export default PhoneModal
