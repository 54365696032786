import React, {useEffect, useState} from "react";
import classes from './Profile.module.sass'
import {useDispatch} from "react-redux";
import {setGeneralPage} from "../../redux/actions/General";
import Button from "../../components/UI/Button";
import clsx from "clsx";
import {Outlet, useNavigate} from "react-router";
import ActionMenu from "../../components/UI/ActionMenu";
import useIsMobile from "../../hooks/useIsMobile";
import useAuth from "../../hooks/useAuth";
import {useLocation} from "react-router-dom";
import DropdownInput from "../../components/FormControls/DropdownInput";
import StateButton from "../../components/UI/StateButton";

const pageOptions = [
    {id: 0, name: 'Личные данные', value: 'personal'},
    {id: 1, name: 'Данные компании', value: 'company'},
    {id: 2, name: 'Настройки системы', value: 'configuration'},
]

const Profile = () => {

    const isMobile = useIsMobile()

    const [actionVisibility, setActionVisibility] = useState(false)

    const {user} = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {pathname} = useLocation()

    useEffect(() => {
        dispatch(setGeneralPage('Личный кабинет'))
    }, [])

    const type = pathname.split('/')[pathname.split('/').length - 1]

    return (
        <>
            <div className={classes.header}>
                <div className={classes.header_title}>
                    <h1>Личный кабинет</h1>
                </div>
                <Button onClick={() => setActionVisibility(true)} text='Действия'>
                    {actionVisibility && <ActionMenu close={() => setActionVisibility(false)} actionList={[
                        {
                            id: 0,
                            name: 'Экспорт данных',
                            handler: () => {
                            },
                            disabled: true
                        },
                        {
                            id: 1,
                            name: 'Импорт данных',
                            handler: () => {
                            },
                            disabled: true
                        },
                    ]}/>}
                </Button>
            </div>
            <div className={classes.menu}>
                {!isMobile ?
                    <div className={classes.menu_buttons}>
                        <StateButton active={type === 'personal'}
                                onClick={() => navigate('/dashboard/profile/personal')} label='Личные данные'/>
                        <StateButton active={type === 'company'}
                                disabled={!Object.keys(user['individual'] || {}).length && !Object.keys(user['legal'] || {}).length}
                                onClick={() => navigate('/dashboard/profile/company')} label='Данные компании'/>
                        <StateButton active={type === 'configuration'}
                                onClick={() => navigate('/dashboard/profile/configuration')} label='Настройки системы' />
                    </div>
                    : <div className={classes.menu_select}>
                        <DropdownInput
                            id='select_page'
                            name='select_page'
                            onChange={value => navigate(`/dashboard/profile/${value}`)}
                            items={pageOptions}
                            value={type}
                            inputStyle={{background: 'inherit', border: 'none', boxShadow: 'none', height: '100%'}}
                        />
                    </div>}
            </div>
            <Outlet/>
        </>
    )
}

export default Profile
