import {useEffect, useState} from "react";

// Хук для обращение к данным в сессионном хранилище
export default function useSessionStorage(key, defaultValue){
    const [value, setValue] = useState(()=>{
        const storedValue = sessionStorage.getItem(key)

        return storedValue === null ? defaultValue : JSON.parse(storedValue)
    })

    useEffect(()=>{
        const listener = event => {
            if (event.storageArea === sessionStorage && event.key === key) {
                setValue(event.newValue ? JSON.parse(event.newValue) : event.newValue)
            }
        }

        window.addEventListener('storage', listener)

        return () => {
            window.removeEventListener('storage', listener)
        }
    },[key, defaultValue])

    const setValueInLocalStorage = newValue => {
        setValue(currentValue => {
            const result = typeof newValue === 'function' ? newValue(currentValue) : newValue

            sessionStorage.setItem(key, JSON.stringify(result))

            return result
        })
    }

    return [value, setValueInLocalStorage]
}
