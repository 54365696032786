import * as yup from "yup";
import masks from "../utils/masks";
import {getTimeDateObject} from "../utils/timeConverters";
import {nanoid} from "nanoid";

export const driverDocumentSchema = yup.object().shape({
    id: yup.string().default(nanoid(5)),
    category: yup.array().of(yup.object().shape({
        id: yup.string(),
        description: yup.string()
    })).required('Заполните обязательные поля').default([]),
    driver_license: yup.string().required('Заполните обязательные поля').test('checkPasNumber', 'Некорректный номер удостоверения', value => !value || value.length === 10).default(''),
    driver_license_date: yup.string()
        .required('Заполните обязательные поля')
        .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date))
        .test('maxDate', 'Дата выдачи не может быть позже текущей', date => !masks.date.test(date) || new Date() - getTimeDateObject(date) > 0)
        .default(''),
    driver_license_deadline: yup.string()
        .required('Заполните обязательные поля')
        .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date))
        .test('minDate', 'Срок действия документа не может быть раньше текущей даты', date => !masks.date.test(date) || new Date() - getTimeDateObject(date) <= 0)
        .default(''),
    type: yup.object().shape({
        id: yup.string(),
        name: yup.string(),
        types: yup.array().of(yup.object().shape({
            id: yup.string(),
            name: yup.string()
        }))
    }).required('Заполните обязательные поля').default(null).nullable(true),
})

const driverSchema = yup.object().shape({
    id: yup.string().required(),
    contractor: yup.string().required(),
    first_name: yup.string().required('Заполните обязательные поля').default(''),
    last_name: yup.string().required('Заполните обязательные поля').default(''),
    middle_name: yup.string().nullable(true).default(''),
    phone: yup.string().required('Заполните обязательные поля').matches(masks.phone, 'Неверный номер телефона').default(''),
    email: yup.string().nullable(true).test('checkEmail', 'Некорректный email', email => !email || masks.email.test(email)).default(''),
    passport_number: yup.string().nullable(true).default('').test('checkPasNumber', 'Некорректный номер паспорта', value => !value || value.length === 10),
    passport_date: yup.string()
        .nullable(true)
        .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date))
        .test('maxDate', 'Дата выдачи не может быть позже текущей', date => !masks.date.test(date) || new Date() - getTimeDateObject(date) > 0)
        .default(''),
    passport_entity: yup.string().nullable(true).default(''),
    division_code: yup.string().nullable(true).default('').test('checkDivCode', 'Некорректный код подразделения', value => !value || value.length === 6),
    passport_address: yup.string().nullable(true).default(''),
    comment: yup.string().nullable(true).default(''),
    type: yup.array().of(yup.object()).required('Заполните обязательные поля').min(1, 'Заполните обязательные поля').default([]),
    vehicle: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).default(null).nullable(true)
})

export default driverSchema