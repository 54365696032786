import React, {Children, cloneElement, forwardRef, isValidElement, useEffect, useRef, useState} from "react";
import classes from './Dropdown.module.sass'
import {createPortal} from "react-dom";
import clsx from "clsx";
import {ReactComponent as Arrow} from "../../../assets/dropdownArrow.svg";
import useScrollBottom from "../../../hooks/useScrollBottom";
import LoadingScreen from "../../LoadingScreen";
import * as _ from "lodash";
import getWindowCoords from "../../../utils/getWindowCoords";

export const MenuItem = forwardRef(({
                                        active,
                                        disabled,
                                        name,
                                        id,
                                        value,
                                        children,
                                        style = {},
                                        ...rest
                                    }, ref) => {

    const disabledStyle = {
        cursor: 'initial',
        opacity: '0.5'
    }

    return (
        <div className={classes.item} {...rest} ref={ref} value={value}
             style={Object.assign(style, disabled ? disabledStyle : {})}>
            {children}
        </div>
    )
})

const Dropdown = ({
                      label = 'Не выбрано',
                      displayText = '',
                      children,
                      onChange,
                      value,
                      disabled,
                      name,
                      id,
                      style,
                      error,
                      isMultiple = false,
                      loading = false,
                      onScrollBottom = () => {
                      }
                  }) => {

    const [view, setView] = useState(label)
    const [isOpen, setOpen] = useState(false)
    const [coords, setCoords] = useState(null)
    const [scrollY, setScrollY] = useState(0)

    const controlRef = useRef(null)
    const dropdownRef = useRef(null)

    const getCoords = () => {
        if(!controlRef.current) return

        const box = getWindowCoords(controlRef.current)

        if (box) {

            return {
                left: box.left,
                top: box.top + box.height,
                width: box.width,
            };
        }

        return null;
    };

    const handleKeyDown = async (event) => {
        switch (event.code) {
            case 'Escape': {
                event.preventDefault()
                event.stopPropagation()
                setOpen(false)
            }
        }
    }

    useScrollBottom(dropdownRef, onScrollBottom)

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown, true);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, true);
        }
    }, [isOpen])

    useEffect(() => {
        if (!isOpen) return;

        const coords = getCoords();
        setCoords(coords);
    }, [isOpen])

    const handleOpen = () => setOpen(true)

    const handleChange = item => {
        if (isMultiple) {
            if (value.includes(item.value)) {
                onChange(value.filter(value => value !== item.value))
            } else {
                onChange([...value, item.value])
            }
        } else {
            onChange(item.value)
            setOpen(false)
        }
    }

    useEffect(() => {
        const elements = children[1]
        if (isMultiple) {
            value.length ? setView(value.join(', ')) : setView(label)
        } else {
            elements.forEach(element => {
                if (_.isEqual(element.props.value, value)) {
                    setView(element.props.children)
                }
            })
        }
    }, [value, children])

    useEffect(() => {
        if (!dropdownRef.current || loading) return

        const scrollListener = () => {
            setScrollY(dropdownRef.current?.scrollTop)
        }

        dropdownRef.current?.addEventListener('scroll', scrollListener)

        return () => {
            dropdownRef.current?.removeEventListener('scroll', scrollListener)
        }

    }, [dropdownRef, children, loading])

    useEffect(() => {
        if (!dropdownRef.current || loading) return

        dropdownRef.current.scrollTop = scrollY

    }, [dropdownRef, children, loading])

    return (
        <div className={classes.dropdown}>
            <button
                id={id}
                name={name}
                disabled={disabled}
                ref={controlRef}
                className={clsx(classes.dropdown_control, error && classes.dropdown_control_error)}
                onClick={handleOpen}
                style={style}
                type='button'>
                <div className={classes.dropdown_control_content}>
                    {displayText || view}
                </div>
                <div className={classes.dropdown_control_arrow}
                     style={isOpen ? {transform: 'rotateZ(180deg)', top: '2px'} : {
                         transform: 'rotateZ(0deg)',
                         top: '-2px'
                     }}>
                    <Arrow/>
                </div>
            </button>
            {isOpen && coords && createPortal(
                <>
                    <div className={classes.dropdown_backdrop} onClick={() => {
                        setOpen(false)
                    }}/>
                    <div className={classes.dropdown_menu} style={{
                        left: `${coords.left}px`,
                        top: `${coords.top}px`,
                        minWidth: `${Math.max(150, coords.width)}px`
                    }} ref={dropdownRef}>
                        {loading
                            ? <LoadingScreen/>
                            : Children.map(children, (child) => {
                                if (isValidElement(child)) {
                                    return cloneElement(child, {
                                        onMouseDown: event => {
                                            event.stopPropagation()
                                            if (child.props.disabled) return
                                            handleChange({
                                                id: child.props.id,
                                                name: child.props.name,
                                                value: child.props.value
                                            })
                                        }
                                    })
                                }
                            })}
                    </div>
                </>, document.body
            )}
        </div>
    )
}

export default Dropdown