import React from "react";
import classes from './Error.module.sass'
import {ReactComponent as Logo} from "../../assets/logo.svg";
import {useNavigate} from "react-router-dom";

const Error = ({description, title}) => {

    const navigate = useNavigate()

    return (
        <div className={classes.error}>
            <div className={classes.error_logo} onClick={()=>navigate('/auth/login')}>
                <Logo/>
            </div>
            <div className={classes.error_content}>
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default Error
