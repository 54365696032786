import React, {useEffect, useState} from "react";
import {Modal, SubmitMenu, SuccessModal} from "../Modal";
import {useParams} from "react-router";
import classes from './MonitoringModal.module.sass'
import {getAPI} from "../../../api/api";
import {useFormik} from "formik";
import Button from "../../UI/Button";
import TextInput from "../../FormControls/TextInput";
import PhoneInput from "../../FormControls/PhoneInput";
import {editVehicle, setRender} from "../../../redux/actions/Vehicles";
import {getErrorMessageFromResponse} from "../../../utils/errors";
import {useDispatch} from "react-redux";
import moment from "moment";
import 'moment/locale/ru'
import DropdownInput from "../../FormControls/DropdownInput";
import monitoringSchema from "../../../schemas/monitoringSchema";
import {getMostFrequentlyEncounteredError} from "../../../schemas";
import ConfirmModal from "../ConfirmModal";

moment.locale('ru')

const MonitoringModal = ({close}) => {

    const dispatch = useDispatch()
    const params = useParams()
    const vehicleID = params.id

    const [techData, setTechData] = useState(null)

    const [isWatch, setWatch] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [scroll, setScroll] = useState(0)

    const [errorModal, setErrorModal] = useState('')
    const [successModal, setSuccessModal] = useState('')
    const [successDisconnectModal, setSuccessDisconnectModal] = useState('')
    const [disconnectModal, setDisconnectModal] = useState(false)

    const style = {
        width: '700px'
    }

    const formik = useFormik({
        initialValues: monitoringSchema.cast({
            id: vehicleID
        }),
        validationSchema: monitoringSchema,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validate: () => setScroll(prev => prev + 1),
        onSubmit: async values => {
            const data = {
                id: values.id,
                brand_monitoring: values.brand_monitoring,
                id_monitoring: values.id_monitoring,
                imei: values.imei,
                name_monitoring: values.name_monitoring,
                phone_support: values.phone_support,
                email_support: values.email_support,
                connect: values.connect,
                relay_protocol: values.relay_protocol,
                operator: values.operator
            }

            const response = await dispatch(editVehicle(data.id, data))

            if (response.error) {
                setErrorModal(getErrorMessageFromResponse(response))
            } else {
                setSuccessModal('Данные для мониторинга отправлены')
            }
        }
    })

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    const fetchAccess = async () => {
        const response = await getAPI.editVehicleAccess(vehicleID)

        if (response.error) {
            setWatch(true)
        } else {
            setWatch(false)
        }
    }

    const fetchVehicle = async () => {
        const vehicle = await getAPI.getVehicleById(vehicleID)

        if (vehicle.error) return

        await formik.setValues(monitoringSchema.cast({
            ...vehicle,
            id: vehicleID,
            relay_protocol: vehicle.relay_protocol || 'Авто'
        }))
        await setTechData({
            ...vehicle.technical_info,
            csv: vehicle.csv_file,
            xlsx: vehicle.xlsx_file
        })

        await fetchAccess()
    }

    const fetchDeleteMonitoring = async () => {
        //TODO: Отсутствует обработчик ошибки
        setLoading(true)
        const response = await getAPI.deleteVehicleMonitoring(vehicleID)
        // //reload form
        // await fetchVehicle()
        //reload table
        dispatch(setRender())
        setLoading(false)
        setSuccessDisconnectModal("Транспортное средство было отключено от мониторинга.")
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            await fetchVehicle()
            setLoading(false)
        })()
    }, [])

    const downloadPDF = async () => {
        const a = document.createElement('a')
        a.href = '/files/Ретрансляция.docx'
        a.setAttribute('download', 'Ретрансляция')
        a.click()
    }

    const downloadCSV = async fileName => {
        const a = document.createElement('a')
        a.href = `${fileName}`
        a.setAttribute('type', 'text/csv')
        a.setAttribute('download', 'Таблица.csv')
        a.setAttribute('target', '_blank')
        a.click()
    }

    const downloadXLSX = async fileName => {
        const a = document.createElement('a')
        a.href = `${fileName}`
        a.setAttribute('type',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        a.setAttribute('download', 'Таблица.xlsx')
        a.click()
    }

    return (
        <>
            <Modal close={close} title='Мониторинг ТС' isShowing style={style} isLoading={isLoading} scroll={scroll}>
                {formik.errors.message && <div className='form_error_message'>
                    {formik.errors.message}
                </div>}
                <div className='form'>
                    <div className="form_columns">
                        <div className='form_columns_column'>
                            {/*brand_monitoring*/}
                            <TextInput onChange={formik.handleChange} name='brand_monitoring' id='brand_monitoring'
                                       disabled={isWatch} error={formik.errors.brand_monitoring}
                                       value={formik.values.brand_monitoring} labelStyle={{whiteSpace: 'normal'}}
                                       label='Марка и модель оборудования мониторинга GPS/GLONASS' placeholder=''/>
                            {/*imei*/}
                            <TextInput onChange={formik.handleChange} name='imei' id='imei'
                                       disabled={isWatch} error={formik.errors.imei} labelStyle={{whiteSpace: 'normal'}}
                                       value={formik.values.imei} inputStyle={{letterSpacing: '4px'}} maskChar=''
                                       label='IMEI оборудования мониторинга GPS/GLONASS'
                                       mask='9999999999999999' placeholder='IMEI'/>
                            {/*id_monitoring*/}
                            <TextInput onChange={formik.handleChange} name='id_monitoring' id='id_monitoring'
                                       disabled={isWatch} error={formik.errors.id_monitoring} isRequired
                                       labelStyle={{whiteSpace: 'normal'}} maskChar=''
                                       value={formik.values.id_monitoring} inputStyle={{letterSpacing: '4px'}}
                                       label='ID номер оборудования мониторинга GPS/GLONASS'
                                       placeholder='ID номер оборудования' mask='999999999999999'
                            />
                            {/*name_monitoring*/}
                            <TextInput onChange={formik.handleChange} name='name_monitoring' id='name_monitoring'
                                       disabled={isWatch} error={formik.errors.name_monitoring}
                                       labelStyle={{whiteSpace: 'normal'}}
                                       value={formik.values.name_monitoring}
                                       label='Наименование сервиса мониторинга'/>
                        </div>
                        <div className='form_columns_column'>
                            {/*relay_protocol*/}
                            <DropdownInput
                                id='relay_protocol'
                                name='relay_protocol'
                                outerStyle={{marginTop: '20px'}}
                                onChange={value => formik.setFieldValue('relay_protocol', value)}
                                value={formik.values.relay_protocol}
                                items={[
                                    {
                                        id: 1,
                                        name: 'Авто',
                                        value: 'Авто'
                                    },
                                    {
                                        id: 2,
                                        name: 'Wialon IPS',
                                        value: 'Wialon IPS'
                                    },
                                    {
                                        id: 3,
                                        name: 'ЕГТС',
                                        value: 'ЕГТС'
                                    }
                                ]}
                                label='Протокол ретрансляции'
                                disabled={isWatch}
                                error={formik.errors.relay_protocol}
                            />
                            {/*operator*/}
                            <TextInput
                                onChange={formik.handleChange}
                                name='operator'
                                id='operator'
                                disabled={isWatch}
                                outerStyle={{marginTop: '20px'}}
                                error={formik.errors.operator}
                                value={formik.values.operator}
                                label='Оператор'
                                placeholder='ФИО'/>

                            {/*phone_support*/}
                            <PhoneInput
                                onChange={value => formik.setFieldValue("phone_support", value)}
                                formValue={formik.values.phone_support}
                                name='phone_support'
                                id='phone_support'
                                error={formik.errors.phone_support}
                                label='Контактный телефон тех.поддержки'
                                labelStyle={{whiteSpace: 'normal'}}
                                outerStyle={{marginTop: '20px'}}
                            />
                            {/*email_support*/}
                            <TextInput onChange={formik.handleChange} name='email_support' id='email_support'
                                       disabled={isWatch} error={formik.errors.email_support}
                                       labelStyle={{whiteSpace: 'normal'}}
                                       value={formik.values.email_support}
                                       label='Контактный email тех.поддержки'
                                       placeholder='mail@mail.ru'/>
                            {/*connect*/}
                            <div className={'form_control'}>
                                <div className={'form_control_label'}>
                                    <label>&nbsp;</label>
                                </div>
                                <div className={'form_control_input ' + classes.button_form}>
                                    {formik.values.connect
                                        ? <Button text={"Отключить"} onClick={() => setDisconnectModal(true)}/>
                                        : <Button onClick={downloadPDF} text='Скачать заявку на ретрансляцию'/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    {techData && formik.values.connect && <div className={classes.info}>
                        <h1>Техническая информация</h1>
                        <div className={classes.info_content}>
                            <h1>Последняя запись</h1>
                            <div className={classes.info_content_list}>
                                <div className={classes.info_content_list_item}>
                                    <p>Дата</p>
                                    <p>{moment(techData.date).format('LL')}</p>
                                </div>
                                <div className={classes.info_content_list_item}>
                                    <p>Время</p>
                                    <p>{techData.time}</p>
                                </div>
                                <div className={classes.info_content_list_item}>
                                    <p>Широта</p>
                                    <p>{techData.latitude}</p>
                                </div>
                                <div className={classes.info_content_list_item}>
                                    <p>Долгота</p>
                                    <p>{techData.longitude}</p>
                                </div>
                            </div>
                            <div className={classes.info_content_journal}>
                                <h1>Журнал мониторинга</h1>
                                <div className={classes.buttons}>
                                    <Button onClick={() => downloadCSV(techData.csv)} text={'Таблица.csv'}/>
                                    <Button onClick={() => downloadXLSX(techData.xlsx)} text={'Таблица.xlsx'}/>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>


                {!isWatch && <SubmitMenu close={close} saveHandler={formik.submitForm}/>}
                <ConfirmModal close={() => setDisconnectModal(false)} title="Отключить"
                              confirmHandler={fetchDeleteMonitoring} isShowing={disconnectModal}
                              text="Вы уверены, что хотите отключить выбранное транспортное средство от мониторинга?"
                              confirmText="Да"
                />

            </Modal>
            <SuccessModal close={() => {
                setSuccessModal('');
                close()
            }} title='Успех' description={successModal} isShowing={successModal}/>
            <SuccessModal isError close={() => {
                setErrorModal('');
            }} title='Ошибка' description={errorModal} isShowing={errorModal}/>

            <SuccessModal close={() => {
                setSuccessDisconnectModal('');
                close()
            }} title='' description={successDisconnectModal} isShowing={successDisconnectModal}/>
        </>
    )
}

export default MonitoringModal