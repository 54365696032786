import {GENERAL_SET_PAGE} from "../types";

const General = (state = {
    currentPage: ''
}, action) => {
    switch (action.type){
        case GENERAL_SET_PAGE:
            return {
                ...state,
                currentPage: action.payload.page
            }
        default:
            return state
    }
}

export default General