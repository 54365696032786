import React from "react";
import {InfinitySpin} from "react-loader-spinner";

const LoadingScreen = () => {

    const style = {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }

    return (
        <div style={style}>
            <InfinitySpin color='#213848'/>
        </div>
    )
}

export default LoadingScreen
