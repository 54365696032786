import React, {useState} from "react";
import {ReactComponent as Eye} from "../../../assets/popups/eye.svg";
import clsx from "clsx";
import PropTypes from "prop-types";

const PasswordInput = ({
                           id,
                           name = '',
                           disabled = false,
                           onChange,
                           value,
                           error,
                           icon = undefined,
                           placeholder = '',
                           label = '',
                           helper = undefined,
                           addition = undefined,
                           additionStyle = {},
                           helperStyle = {},
                           inputStyle = {},
                           iconStyle = {},
                           labelStyle = {},
                           innerStyle = {},
                           outerStyle = {},
                           isRequired = false
                       }) => {

    const [passwordVisibility, setPasswordVisibility] = useState(false)
    const [iconHover, setIconHover] = useState(false)

    const onChangeHandler = event => {
        const value = event.target.value
        if(value.includes(' ')) return
        onChange(event)
    }

    const baseIconStyles = {
      cursor: 'pointer',
      filter: iconHover ? 'drop-shadow(0px 4px 2px rgba(0, 0, 0, .28))' : undefined
    }

    return (
        <div className='form_control' style={outerStyle}>
            {label && <div className='form_control_label'>
                <label htmlFor={id} style={labelStyle}>{label} {isRequired && <span>*</span>}</label>
            </div>}
            <div className={clsx('form_control_input', error && 'form_control_input_error')} style={innerStyle}>
                <input type={passwordVisibility ? 'text' : 'password'} id={id} name={name} style={inputStyle}
                       disabled={disabled} placeholder={placeholder}
                       onChange={onChangeHandler} value={value}/>
                <div className='form_control_icon' onClick={() => setPasswordVisibility(prev => !prev)}
                     style={{...baseIconStyles, ...iconStyle}}
                     onMouseEnter={() => setIconHover(true)}
                     onMouseLeave={() => setIconHover(false)}
                     onMouseDown={e => {
                       e.preventDefault()
                       e.stopPropagation()
                     }}
                     onMouseUp={e => e.preventDefault()}
                >
                    {(!passwordVisibility && icon) ? icon : <Eye/>}
                </div>
            </div>
            {helper && <div className='form_control_helper' style={helperStyle}>
                {helper}
            </div>}
            {addition && <div className='form_control_addition' style={additionStyle}>
                {addition}
            </div>}
        </div>
    )
}

PasswordInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    error: PropTypes.any,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.shape({}),
    inputStyle: PropTypes.shape({}),
    labelStyle: PropTypes.shape({}),
    innerStyle: PropTypes.shape({}),
    outerStyle: PropTypes.shape({}),
    isRequired: PropTypes.bool
}

export default PasswordInput
