import React, {useEffect} from "react";
import {Modal, SubmitMenu} from "../Modal";
import {useFormik} from "formik";
import masks from "../../../utils/masks";
import {timeToISO} from "../../../utils/timeConverters";
import DateInput from "../../FormControls/DateInput";
import * as yup from "yup"
import {getMostFrequentlyEncounteredError} from "../../../schemas";

const dateRangeSchema = yup.object().shape({
    left: yup.string()
        .required('Заполните обязательные поля')
        .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date)),
    right: yup.string()
        .required('Заполните обязательные поля')
        .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date))
        .when('left', (left, schema) => {
            return schema.test('checkDates', 'Правая дата не может быть меньше левой', date => !date || new Date(timeToISO(date)) - new Date(timeToISO(left)) > 0)
        })
})

const DateRangeModal = ({values, setValues, title, close, isShowing}) => {

    const formik = useFormik({
        initialValues: {
            left: values[0],
            right: values[1]
        },
        validationSchema: dateRangeSchema,
        validateOnChange: true,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit: values => {
            const left = timeToISO(values.left)
            const right = timeToISO(values.right)

            setValues([left, right])
            close()
        }
    })

    useEffect(()=>{
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    },[formik.errors])

    return (
        <Modal close={close} title={title} isShowing={isShowing}>
            <div className='form'>
                {formik.errors.message && <div className='form_error_message'>
                    {formik.errors.message}
                </div>}
                <div className='form_columns'>
                    <div className='form_columns_column'>
                        <DateInput
                            error={formik.errors.left}
                            value={formik.values.left}
                            onChange={(date) => formik.setFieldValue('left', date)}
                        />
                    </div>
                    <div className='form_columns_column'>
                        <DateInput
                            error={formik.errors.right}
                            value={formik.values.right}
                            onChange={(date) => formik.setFieldValue('right', date)}
                        />
                    </div>
                </div>
            </div>
            <SubmitMenu close={close} saveHandler={formik.submitForm}/>
        </Modal>
    )
}

export default DateRangeModal
