import React from "react";
import {useLocation} from "react-router";


// возвращает параметры запроса
const useQuery = () => {
    const {search} = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

export default useQuery
