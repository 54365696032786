import {useEffect} from "react";


// Хук, вызывающий колбэк при клике по области вне выбранного элемента
const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = event => {
            const element = ref?.current
            if (!element || element.contains(event.target || null)) {
                return
            }
            handler(event)
        }

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    },[ref, handler])
}

export default useOnClickOutside