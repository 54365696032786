import {useEffect} from "react";
import React from "react";
import useQuery from "./hooks/useQuery";
import Cookies from "js-cookie";
import Router from "./routes";
import ErrorBoundary from "./guards/ErrorBoundary";

const App = () => {

    const query = useQuery()

    // Данная проверка необходима, для авторизации пользователя через ссылку, полученную на электронную почту
    useEffect(() => {
        if (query.get('token') && !Cookies.get('access')) {
            Cookies.set('access', query.get('token'))
        }
    }, [])

    return (
        <ErrorBoundary>
            <Router/>
        </ErrorBoundary>
    )
}

export default App;
