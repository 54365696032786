import React, {useCallback, useRef, useState} from 'react';
import Calendar from "../../UI/Calendar";
import {ReactComponent as CalendarIcon} from "../../..//assets/popups/calendar.svg";
import clsx from "clsx";
import moment from "moment";
import InputMask from "react-input-mask";
import masks from "../../../utils/masks";
import getWindowCoords from "../../../utils/getWindowCoords";

const DateInput = ({
                       label,
                       labelStyles = {},
                       innerStyles = {},
                       outerStyles = {},
                       id = '',
                       name = '',
                       isRequired = false,
                       error = '',
                       inputStyles = {},
                       value = '',
                       onChange,
                       disabled = false
                   }) => {

    const [calendarOpened, setCalendarOpened] = useState(false)

    const inputRef = useRef(null)

    const openCalendar = useCallback(() => setCalendarOpened(true), [])

    const closeCalendar = useCallback(() => setCalendarOpened(false), [])

    const getCoords = () => {
        if(!inputRef.current) return

        const box = getWindowCoords(inputRef.current)

        const calendarHeight = 252

        if (box) {
            return {
                left: box.left,
                top: (box.bottom + calendarHeight > window.innerHeight) ? box.top + (box.height / 2) - calendarHeight : box.bottom,
            }
        }

        return null
    }

    const calendarChange = date => {
        onChange(moment(date).format('DD.MM.YYYY'))
    }

    const inputChange = event => {
        const input = event.target.value.replaceAll('_', '')
        if (input.length > 2 && input.length < 10 || masks.date.test(input))
            onChange(input)
        else
            onChange('')

    }

    return (
        <>
            <div className='form_control' style={outerStyles} ref={inputRef}>
                <div className='form_control_label'>
                    <label style={labelStyles} htmlFor={id}>{label} {isRequired && <span>*</span>}</label>
                </div>
                <div className={clsx('form_control_input', error && 'form_control_input_error')} style={innerStyles}>
                    <InputMask id={id}
                               name={name}
                               value={value}
                               onChange={inputChange}
                               disabled={disabled}
                               mask='99.99.9999'
                               placeholder="ДД.ММ.ГГГГ"
                               style={{...inputStyles, paddingRight: '10px'}}
                    />
                    <div
                        className='form_control_icon'
                        onClick={openCalendar}
                        style={{cursor: 'pointer', marginTop: '0', top: '5px', right: '10px'}}
                    >
                        <CalendarIcon/>
                    </div>
                </div>
            </div>
            {calendarOpened && <Calendar
                close={closeCalendar}
                value={value ? new Date(moment(value, 'DD.MM.YYYY').format()) : new Date()}
                onChange={calendarChange}
                coords={getCoords()}
            />}
        </>

    );
}

export default DateInput
