import {combineReducers} from "redux";
import Users from "./Users";
import General from "./General";
import Vehicles from "./Vehicles";
import Drivers from "./Drivers";
import Bids from "./Bids";
import Objects from "./Objects";
import Companies from "./Companies";

export default combineReducers({
    Users, General, Vehicles, Drivers, Bids, Objects, Companies
})
