import {
    DRIVERS_ADD_DRIVERS,
    DRIVERS_SET_DRIVERS,
    DRIVERS_SET_LOADING,
    DRIVERS_SET_NEXT,
    DRIVERS_SET_RENDER
} from "../types";
import {getAPI} from "../../api/api";
import {store} from "../store";

export const getDrivers = params => async dispatch => {
    dispatch(setLoading(true))

    const response = await getAPI.getDrivers(params)
    if(!response.error){
        dispatch(setDrivers(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const suppleDrivers = () => async dispatch => {
    const {next} = store.getState().Drivers

    if(!next) return

    dispatch(setLoading(true))

    const response = await getAPI.getRequest(next)
    if(!response.error){
        dispatch(addDrivers(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const createDriver = data => async dispatch => {
    const response = await getAPI.createDriver(data)
    if(!response.error) dispatch(setRender())
    return response
}

export const deleteDrivers = drivers => async dispatch => {
    const response = await getAPI.deleteDrivers(drivers)
    if(!response.error) dispatch(setRender())
    return response
}

export const editDriver = (id, data) => async dispatch => {
    const response = await getAPI.editDriver(id, data)
    if(!response.error) dispatch(setRender())
    return response
}

const setDrivers = drivers => ({type: DRIVERS_SET_DRIVERS, payload: {drivers}})

const addDrivers = drivers => ({type: DRIVERS_ADD_DRIVERS, payload: {drivers}})

const setRender = () => ({type: DRIVERS_SET_RENDER})

const setLoading = loading => ({type: DRIVERS_SET_LOADING, payload: {loading}})

const setNext = next => ({type: DRIVERS_SET_NEXT, payload: {next}})
