import {getAPI} from "../../api/api";
import {
    VEHICLES_ADD_VEHICLES,
    VEHICLES_SET_LOADING, VEHICLES_SET_NEXT,
    VEHICLES_SET_RENDER,
    VEHICLES_SET_VEHICLES
} from "../types";
import {store} from "../store";

export const getVehicles = params => async dispatch => {
    dispatch(setLoading(true))

    const response = await getAPI.getVehicles(params)
    if(!response.error){
        dispatch(setVehicles(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const suppleVehicles = () => async dispatch => {
    const {next} = store.getState().Vehicles

    if(!next) return

    dispatch(setLoading(true))

    const response = await getAPI.getRequest(next)
    if(!response.error){
        dispatch(addVehicles(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const createVehicle = data => async dispatch => {
    const response = await getAPI.createVehicle(data)
    if(!response.error) dispatch(setRender())

    return response
}

export const editVehicle = (id, data) => async dispatch => {
    const response = await getAPI.editVehicle(id, data)
    if (!response.error) dispatch(setRender())

    return response
}

export const deleteVehicle = vehicles => async dispatch => {
    const response = await getAPI.deleteVehicles(vehicles)
    if(!response.error) dispatch(setRender())

    return response
}

const setVehicles = vehicles => ({type: VEHICLES_SET_VEHICLES, payload: {vehicles}})
export const setRender = () => ({type: VEHICLES_SET_RENDER})
const addVehicles = vehicles => ({type: VEHICLES_ADD_VEHICLES, payload: {vehicles}})
const setLoading = loading => ({type: VEHICLES_SET_LOADING, payload: {loading}})
const setNext = next => ({type: VEHICLES_SET_NEXT, payload: {next}})
