import * as yup from "yup";
import masks from "../utils/masks";
import {getTimeDateObject} from "../utils/timeConverters";

const vehicleSchema = yup.object().shape({
    id: yup.string().required(),
    type: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).required('Заполните обязательные поля').default(null).nullable(true),
    brand: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).required('Заполните обязательные поля').default(null).nullable(true),
    model: yup.string().trim().required('Заполните обязательные поля').default(''),
    release_date: yup.number().required('Заполните обязательные поля').min(1900, 'Год выпуска не может быть раньше 1900').max(new Date().getFullYear(), 'Год выпуска не может быть больше текущего').default(''),
    subject: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).default(null).nullable(true),
    driver: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).default(null).nullable(true),
    description: yup.string().nullable(true).default(''),
    images: yup.array().nullable(true).default([]),
    type_doc: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).default(null).nullable(true),
    number_doc: yup.string().nullable(true).default(''),
    date_doc: yup.string()
        .nullable(true)
        .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date))
        .test('maxDate', 'Дата выдачи не может быть позже текущей', date => !masks.date.test(date) || new Date() - getTimeDateObject(date) > 0)
        .default(''),
    deadline_doc: yup.string()
        .nullable(true)
        .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date))
        .test('minDate', 'Срок действия документа не может быть раньше текущей даты', date => !masks.date.test(date) || new Date() - getTimeDateObject(date) <= 0)
        .default(''),
    scan_doc: yup.array().nullable(true).default([]),
    type_registration: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).required('Заполните обязательные поля').default(null).nullable(true),
    registration_number: yup.string().required('Заполните обязательные поля')
        .when('type_registration', {
            is: tr => tr?.name === 'Произвольный',
            then: schema => schema,
            otherwise: schema => schema.test('checkRegNumLength', 'Неверный регистрационный номер', number => !number || number.length >= 10)
        })
        .default(''),
    type_doc_admiss: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).default(null).nullable(true),
    admiss: yup.string().nullable(true).default(''),
    date_admiss: yup.string()
        .nullable(true)
        .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date))
        .test('maxDate', 'Дата выдачи не может быть позже текущей', date => !masks.date.test(date) || new Date() - getTimeDateObject(date) > 0)
        .default(''),
    scan_admiss: yup.array().nullable(true).default([]),
    type_engine: yup.string().required('Заполните обязательные поля').default(''),
    top_eq: yup.boolean().nullable(true).default(false),
    engine_top_eq: yup.boolean().nullable(true).default(false),
    type_admiss: yup.array().of(yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
        types: yup.array().of(yup.object().shape({
            id: yup.number().required(),
            name: yup.string().required()
        }))
    })).required('Заполните обязательные поля').min(1, 'Заполните обязательные поля').default([]),
    connect: yup.boolean().nullable(true).default(false)
})

export default vehicleSchema