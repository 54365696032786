import {Navigate, Outlet, useNavigate, useRoutes} from "react-router-dom";
import React, {lazy, Suspense, useEffect} from "react";
import LoadingScreen from "../components/LoadingScreen";
import Layout from "../hoc/Layout";
import ObjectModal from "../components/Modal/ObjectModal";
import VehicleModal from "../components/Modal/VehicleModal";
import DriverModal from "../components/Modal/DriverModal";
import BidModal from "../components/Modal/BidModal";
import EntityModal from "../components/Modal/EntityModal";
import UserModal from "../components/Modal/UserModal";
import Help from "../pages/Help";
import Profile, {Company, Configuration, Personal} from "../pages/Profile";
import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";
import Error from "../pages/Error";
import MonitoringModal from "../components/Modal/MonitoringModal";
import VehicleMap from "../pages/Vehicles/VehicleMap";

const Loadable = Component => props => {

    return (
        <Suspense fallback={<LoadingScreen/>}>
            <Component {...props}/>
        </Suspense>
    )
}

const withOutlet = Component => props => {

    return (
        <>
            <Component {...props}/>
            <Outlet/>
        </>
    )
}

const Router = () => {

    const navigate = useNavigate()

    return useRoutes([
        {
            path: 'auth',
            element: <GuestGuard>
                <Auth/>
            </GuestGuard>,
            children: [
                {path: 'login', element: <Login/>},
                {path: 'register', element: <Registration/>}
            ]
        },

        {
            path: 'dashboard',
            element: <AuthGuard>
                <Layout/>
            </AuthGuard>,
            children: [
                {element: <Navigate to='profile' replace/>, index: true},
                {
                    path: 'profile',
                    element: <Profile/>,
                    children: [
                        {element: <Navigate to='personal' replace/>, index: true},
                        {path: 'personal', element: <Personal/>},
                        {path: 'company', element: <Company/>},
                        {path: 'configuration', element: <Configuration/>}
                    ]
                },
                {
                    path: 'objects',
                    element: <Objects/>,
                    children: [
                        {path: ':type/:id', element: <ObjectModal close={() => navigate(-1)}/>},
                        {path: 'vehicle/:type/:id', element: <VehicleModal close={() => navigate(-1)}/>},
                        {path: 'driver/:type/:id', element: <DriverModal close={() => navigate(-1)}/>}
                    ]
                },
                {
                    path: 'objects/map/:id',
                    element: <ObjectMap/>,
                    children: [
                        {path: 'bid/:type/:id', element: <BidModal close={() => navigate(-1)}/>}
                    ]
                },
                {
                    path: 'bids',
                    element: <Bids/>,
                    children: [
                        {path: ':type/:id', element: <BidModal close={() => navigate(-1)}/>},
                        {path: 'object/:type/:id', element: <ObjectModal close={() => navigate(-1)}/>},
                        {path: 'vehicle/:type/:id', element: <VehicleModal close={() => navigate(-1)}/>},
                    ]
                },
                {
                    path: 'bids/map/:id',
                    element: <BidMap/>,
                    children: [
                        {path: 'bid', element: <BidModal close={() => navigate(-1)}/>},
                        {path: 'vehicle/:id', element: <VehicleModal close={() => navigate(-1)}/>}
                    ]
                },
                {
                    path: 'vehicles',
                    element: <Vehicles/>,
                    children: [
                        {path: ':type/:id', element: <VehicleModal close={() => navigate(-1)}/>},
                        {path: 'monitoring/:id', element: <MonitoringModal close={() => navigate(-1)}/>},
                        {path: 'subject/:type/:id', element: <ObjectModal close={() => navigate(-1)}/>},
                        {path: 'driver/:type/:id', element: <DriverModal close={() => navigate(-1)}/>}
                    ]
                },
                {
                    path: 'vehicles/map/:id',
                    element: <VehicleMap/>,
                    children: [

                    ]
                },
                {
                    path: 'drivers',
                    element: <Drivers/>,
                    children: [
                        {path: ':type/:id', element: <DriverModal close={() => navigate(-1)}/>},
                        {path: 'object/:type/:id', element: <ObjectModal close={() => navigate(-1)}/>},
                        {path: 'vehicle/:type/:id', element: <VehicleModal close={() => navigate(-1)}/>}
                    ]
                },
                {
                    path: 'companies',
                    element: <Companies/>,
                    children: [
                        {path: ':id', element: <EntityModal close={() => navigate(-1)}/>},
                        {path: 'object/:type/:id', element: <ObjectModal close={() => navigate(-1)}/>},
                        {path: 'vehicle/:type/:id', element: <VehicleModal close={() => navigate(-1)}/>}
                    ]
                },
                {
                    path: 'users',
                    element: <Users/>,
                    children: [
                        {path: ':type/:id', element: <UserModal close={() => navigate(-1)}/>},
                        {path: ':type', element: <UserModal close={() => navigate(-1)}/>},
                    ]
                },
                {
                    path: 'search',
                    element: <Search/>,
                    children: [
                        {path: 'vehicle/:type/:id', element: <VehicleModal close={() => navigate(-1)}/>},
                        {path: 'company/:type/:id', element: <EntityModal close={() => navigate(-1)}/>},
                        {path: 'object/:type/:id', element: <ObjectModal close={() => navigate(-1)}/>},
                        {path: 'user/:type/:id', element: <UserModal close={() => navigate(-1)}/>},
                    ]
                }
            ]
        },
        {
            path: '/',
            element: <Navigate to='/dashboard/profile' replace/>,
            index: true
        },
        {
            path: '*',
            children: [
                {path: 'help', element: <Help/>},
                {path: 'policy', element: <Policy/>},
                {path: '500', element: <Error description='Произошла ошибка на сервере' title='Ошибка 500'/>},
                {path: '404', element: <Error description='Страница не найдена или не существует' title='Ошибка 404'/>},
                {path: '*', element: <Navigate to='/404' replace/>}
            ]
        },

        {path: '*', element: <Navigate to='/404' replace/>}
    ])
}

export default Router

const Objects = withOutlet(Loadable(lazy(() => import('../pages/Objects'))))
const Bids = withOutlet(Loadable(lazy(() => import('../pages/Bids'))))
const Vehicles = withOutlet(Loadable(lazy(() => import('../pages/Vehicles'))))
const Drivers = withOutlet(Loadable(lazy(() => import('../pages/Drivers'))))
const Companies = withOutlet(Loadable(lazy(() => import('../pages/Companies/Companies'))))
const Users = withOutlet(Loadable(lazy(() => import('../pages/Users'))))
const Search = withOutlet(Loadable(lazy(() => import('../pages/Search'))))
const ObjectMap = withOutlet(Loadable(lazy(() => import('../pages/Objects/ObjectMap'))))
const BidMap = withOutlet(Loadable(lazy(() => import('../pages/Bids/BidMap'))))

const Login = withOutlet(Loadable(lazy(() => import("../pages/Auth/Login"))))
const Registration = withOutlet(Loadable(lazy(() => import("../pages/Auth/Registration"))))
const Auth = Loadable(lazy(() => import("../pages/Auth")))

const Policy = () => {

    // Перенаправление на внешний ресурс
    useEffect(() => {
        window.location.replace('https://operax.ru/policy')
    }, [])

    return null
}
