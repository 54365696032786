import React, {useState} from "react";
import {Modal, SubmitMenu, SuccessModal} from "../Modal";
import {useFormik} from "formik";
import masks from "../../../utils/masks";
import {getAPI} from "../../../api/api";
import {getErrorMessageFromResponse} from "../../../utils/errors";
import TextInput from "../../FormControls/TextInput";

const EmailModal = ({close, isShowing}) => {

    const [errorModal, setErrorModal] = useState('')
    const [successModal, setSuccessModal] = useState('')

    const style = {
        width: '420px'
    }

    const formik = useFormik({
        initialValues: {
            email: ''
        }
    })

    const submitHandler = async () => {
        if (!formik.values.email) {
            formik.setErrors({email: 'error', message: 'Поле не может быть пустым'})
            return
        }

        if (!masks.email.test(formik.values.email)) {
            formik.setErrors({email: 'error', message: 'Неверная почта'})
            return
        }

        const response = await getAPI.sendEmailCode({email: formik.values.email})
        if (response.error) {
            setErrorModal(getErrorMessageFromResponse(response))
        } else {
            setSuccessModal('На указанный email было отправлено письмо с ссылкой для подтверждения.')
        }
    }

    return (
        <>
            <Modal close={close} style={style} title='Смена почты' isShowing={isShowing}>
                {formik.errors.message ? <div className='form_error_message'>
                    <p>{formik.errors.message}</p>
                </div> : null}
                <TextInput onChange={formik.handleChange} name='email' id='email'
                           value={formik.values.email} mask='aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' maskChar=''
                           formatChars={{'a': '[A-Za-z.@0-9\-_]'}}
                           error={formik.errors.email}
                           placeholder='mail@mail.ru' label='Электронная почта'/>
                <SubmitMenu close={close} saveHandler={submitHandler}/>
            </Modal>
            <SuccessModal close={() => setErrorModal('')} description={errorModal} title='Ошибка'
                          isShowing={errorModal} isError/>
            <SuccessModal close={() => {
                setSuccessModal('');
                close()
            }} description={successModal} title='Успех' isShowing={successModal}/>
        </>
    )
}

export default EmailModal
