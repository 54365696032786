import {
    SETTINGS_ADD_USERS,
    SETTINGS_SET_LOADING, SETTINGS_SET_NEXT,
    SETTINGS_SET_RENDER,
    SETTINGS_SET_USERS
} from "../types";

const Users = (state = {
    users: [],
    render: 0,
    loading: false,
    next: null
}, action) => {
    switch (action.type) {

        case SETTINGS_SET_USERS:
            return {
                ...state,
                users: action.payload.users
            }
        case SETTINGS_ADD_USERS:
            return {
                ...state,
                users: [...state.users, ...action.payload.users]
            }
        case SETTINGS_SET_RENDER:
            return {
                ...state,
                render: state.render + 1
            }
        case SETTINGS_SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case SETTINGS_SET_NEXT:
            return {
                ...state,
                next: action.payload.next
            }
        default:
            return state
    }
}

export default Users