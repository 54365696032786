import React, {useState, useEffect, useRef} from 'react';
import {ReactComponent as Phone} from "../../../assets/auth/login.svg";
import TextInput from "../TextInput";

const PhoneInput = ({
                        name,
                        id,
                        inputStyle,
                        iconStyle,
                        labelStyle,
                        disabled,
                        viewIcon = true,
                        label = "Телефон",
                        error,
                        onChange,
                        formValue,
                        isRequired,
                        outerStyle,
                    }) => {

    const inputRef = useRef()

    const handleChange = (event) => {
        const {value} = event.target
        onChange(value)
    }

    useEffect(() => {
        if (formValue === '+7') onChange('')
    }, [formValue])

    return (
        <TextInput
            id={id}
            name={name}
            value={formValue}
            onChange={handleChange}
            placeholder="+79999999999"
            mask="+79999999999"
            icon={viewIcon && <Phone/>}
            maskChar=''
            error={error}
            label={label}
            iconStyle={iconStyle}
            inputStyle={inputStyle}
            ref={inputRef}
            labelStyle={labelStyle}
            disabled={disabled}
            isRequired={isRequired}
            outerStyle={outerStyle}
        />
    )
};

export default PhoneInput;
