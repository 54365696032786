import React, {useCallback, useEffect, useState} from "react";
import {useFormik} from "formik";
import {getErrorMessageFromResponse} from "../../utils/errors";
import classes from "./Profile.module.sass";
import TextInput from "../../components/FormControls/TextInput";
import {SuccessModal} from "../../components/Modal/Modal";
import useAuth from "../../hooks/useAuth";
import {getAPI} from "../../api/api";
import {getMostFrequentlyEncounteredError} from "../../schemas";
import {companySchema} from "../../schemas/profileSchema";
import Button from "../../components/UI/Button";
import {individualEntitySchema, legalEntitySchema} from "../../schemas/authorizationSchema";
import CheckboxInput from "../../components/FormControls/CheckboxInput";
import DropdownInput from "../../components/FormControls/DropdownInput";
import staticData from "../../utils/staticData";
import DateInput from "../../components/FormControls/DateInput";

const Individual = ({formik, data}) => {

    useEffect(() => {
        formik.setValues(individualEntitySchema.cast(data))
    }, [data])

    return (
        <div className='form'>
            <div className='form_columns'>
                <div className='form_columns_column'>
                    {/*shortname_ru*/}
                    <TextInput onChange={formik.handleChange} name='shortname_ru' id='shortname_ru'
                               value={formik.values.shortname_ru} error={formik.errors.shortname_ru} isRequired
                               placeholder='ФИО' label='ФИО' disabled/>
                    {/*vat_payer*/}
                    <CheckboxInput onChange={formik.handleChange} name='vat_payer' id='vat_payer'
                                   error={formik.errors.vat_payer} label='Облагается налогом' disabled
                                   title='Плательщик НДС' checked={formik.values.vat_payer}/>
                    {/*vat_reason*/}
                    {!formik.values.vat_payer &&
                        <DropdownInput
                            id='vat_reason'
                            name='vat_reason'
                            disabled
                            onChange={value => formik.setFieldValue('vat_reason', value)}
                            value={formik.values.vat_reason}
                            error={formik.errors.vat_reason}
                            label='Причина'
                            items={staticData.company.vat_reason.map(reason => ({
                                id: reason.id,
                                name: reason.name,
                                value: reason.name,
                            }))}/>}
                    {/*tin*/}
                    <TextInput onChange={formik.handleChange} name='tin' id='tin' mask='999999999999'
                               value={formik.values.tin} error={formik.errors.tin} isRequired
                               inputStyle={{letterSpacing: '5px'}} disabled
                               placeholder='____________' label='ИНН'/>
                </div>
                <div className='form_columns_column'>
                    {/*OGRN*/}
                    <TextInput onChange={formik.handleChange} name='OGRN' id='OGRN' mask='999999999999999'
                               value={formik.values.OGRN} error={formik.errors.OGRN} isRequired
                               inputStyle={{letterSpacing: '5px'}} disabled
                               placeholder='_______________' label='ОГРН'/>
                    {/*register_address*/}
                    <TextInput onChange={formik.handleChange} name='register_address' id='register_address'
                               value={formik.values.register_address} error={formik.errors.register_address}
                               isRequired disabled
                               placeholder='Адрес регистрации' label='Адрес регистрации'/>
                    {/*payment_account*/}
                    <TextInput onChange={formik.handleChange} name='payment_account' id='payment_account'
                               value={formik.values.payment_account} error={formik.errors.payment_account}
                               label='Расчетный счет' inputStyle={{letterSpacing: '1px'}} mask='99999999999999999999'
                               placeholder='____________________'/>
                    {/*correspondent_account*/}
                    <TextInput onChange={formik.handleChange} name='correspondent_account' id='correspondent_account'
                               value={formik.values.correspondent_account} error={formik.errors.correspondent_account}
                               inputStyle={{letterSpacing: '1px'}} mask='99999999999999999999'
                               placeholder='____________________' label='Корреспондентский счет'/>
                    {/*BIC*/}
                    <TextInput onChange={formik.handleChange} name='BIC' id='BIC' mask='999999999'
                               value={formik.values.BIC} error={formik.errors.BIC}
                               inputStyle={{letterSpacing: '5px'}}
                               placeholder='_________' label='БИК'/>
                    {/*phone*/}
                    <TextInput onChange={formik.handleChange} name='phone' id='phone'
                               value={formik.values.phone} maskChar=''
                               error={formik.errors.phone} mask='+79999999999'
                               placeholder='+79999999999' label='Контактный телефон'/>
                    {/*email*/}
                    <TextInput onChange={formik.handleChange} name='email' id='email'
                               value={formik.values.email} mask='aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' maskChar=''
                               formatChars={{'a': '[A-Za-z.@0-9\-_]'}}
                               error={formik.errors.email}
                               placeholder='mail@mail.ru' label='Электронная почта'/>
                </div>
            </div>
        </div>
    )
}

const Legal = ({formik, data}) => {

    useEffect(() => {
        formik.setValues(legalEntitySchema.cast({
            ...data,
            proxy: data.proxy || 'Устав'
        }))
    }, [data])

    return (
        <div className='form'>
            {/*fullname_ru*/}
            <TextInput
                id='fullname_ru'
                name='fullname_ru'
                disabled
                onChange={formik.handleChange}
                value={formik.values.fullname_ru}
                error={formik.errors.fullname_ru}
                isRequired
                placeholder='Полное название'
                label='Полное название'
            />
            <div className='form_columns'>
                <div className='form_columns_column'>
                    {/*shortname_ru*/}
                    <TextInput
                        disabled
                        id='shortname_ru'
                        name='shortname_ru'
                        onChange={formik.handleChange}
                        value={formik.values.shortname_ru}
                        error={formik.errors.shortname_ru}
                        isRequired
                        placeholder='Краткое название'
                        label='Краткое название'
                    />
                    {/*organizational_form*/}
                    <TextInput onChange={formik.handleChange} name='organizational_form' id='organizational_form'
                               value={formik.values.organizational_form} isRequired
                               error={formik.errors.organizational_form} disabled
                               placeholder='Организационная форма' label='Организационная форма'/>
                    {/*vat_payer*/}
                    <CheckboxInput onChange={formik.handleChange} name='vat_payer' id='vat_payer'
                                   disabled error={formik.errors.vat_payer} label='Облагается налогом'
                                   title='Плательщик НДС' checked={formik.values.vat_payer}/>
                    {/*vat_reason*/}
                    {!formik.values.vat_payer &&
                        <DropdownInput
                            disabled
                            id='vat_reason'
                            name='vat_reason'
                            onChange={value => formik.setFieldValue('vat_reason', value)}
                            value={formik.values.vat_reason}
                            error={formik.errors.vat_reason}
                            label='Причина'
                            items={staticData.company.vat_reason.map(reason => ({
                                id: reason.id,
                                name: reason.name,
                                value: reason.name,
                            }))}/>}
                    {/*tin*/}
                    <TextInput onChange={formik.handleChange} name='tin' id='tin'
                               inputStyle={{letterSpacing: '5px'}} disabled
                               value={formik.values.tin} error={formik.errors.tin} isRequired
                               placeholder='__________' label='ИНН' mask='9999999999'/>
                    {/*checkpoint*/}
                    <TextInput onChange={formik.handleChange} name='checkpoint' id='checkpoint'
                               inputStyle={{letterSpacing: '5px'}} disabled
                               value={formik.values.checkpoint} error={formik.errors.checkpoint} isRequired
                               placeholder='_________' label='КПП' mask='999999999'/>
                    {/*OGRN*/}
                    <TextInput onChange={formik.handleChange} name='OGRN' id='OGRN'
                               inputStyle={{letterSpacing: '5px'}} disabled
                               value={formik.values.OGRN} error={formik.errors.OGRN} isRequired
                               placeholder='_____________' label='ОГРН' mask='9999999999999'/>
                    {/*proxy*/}
                    <DropdownInput
                        id='proxy'
                        name='proxy'
                        disabled
                        value={formik.values.proxy}
                        onChange={value => formik.setFieldValue('proxy', value)}
                        error={formik.errors.proxy}
                        label='Документ на право подписи'
                        items={[
                            {
                                id: 0,
                                name: 'Устав',
                                value: 'Устав'
                            },
                            {
                                id: 1,
                                name: 'Доверенность',
                                value: 'Доверенность'
                            }
                        ]}
                    />
                    {formik.values.proxy === 'Доверенность' &&
                        <>
                            <TextInput
                                id='proxy_number'
                                name='proxy_number'
                                disabled
                                onChange={formik.handleChange}
                                value={formik.values.proxy_number}
                                error={formik.errors.proxy_number}
                                placeholder='Номер доверенности'
                                label='Номер доверенности'
                                isRequired
                            />
                            <DateInput
                                id='proxy_date'
                                name='proxy_date'
                                disabled
                                onChange={date => formik.setFieldValue('proxy_date', date)}
                                value={formik.values.proxy_date}
                                error={formik.errors.proxy_date}
                                label='Дата доверенности'
                                isRequired
                            />
                        </>
                    }
                </div>
                <div className='form_columns_column'>
                    {/*payment_account*/}
                    <TextInput onChange={formik.handleChange} name='payment_account' id='payment_account'
                               value={formik.values.payment_account} error={formik.errors.payment_account}
                               inputStyle={{letterSpacing: '1px'}}
                               label='Расчетный счет' mask='99999999999999999999' placeholder='____________________'/>
                    {/*correspondent_account*/}
                    <TextInput onChange={formik.handleChange} name='correspondent_account' id='correspondent_account'
                               value={formik.values.correspondent_account} error={formik.errors.correspondent_account}
                               inputStyle={{letterSpacing: '1px'}} mask='99999999999999999999'
                               placeholder='____________________' label='Корреспондентский счет'/>
                    {/*BIC*/}
                    <TextInput onChange={formik.handleChange} name='BIC' id='BIC'
                               inputStyle={{letterSpacing: '5px'}}
                               value={formik.values.BIC} error={formik.errors.BIC}
                               placeholder='_________' label='БИК' mask='999999999'/>
                    {/*director*/}
                    <TextInput onChange={formik.handleChange} name='director' id='director'
                               value={formik.values.director} disabled
                               error={formik.errors.director} isRequired
                               placeholder='Руководитель' label='Руководитель'/>
                    {/*phone*/}
                    <TextInput onChange={formik.handleChange} name='phone' id='phone'
                               value={formik.values.phone} error={formik.errors.phone}
                               placeholder='+79999999999' label='Контактный телефон' mask='+79999999999'/>
                    {/*email*/}
                    <TextInput onChange={formik.handleChange} name='email' id='email'
                               value={formik.values.email} mask='aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' maskChar=''
                               formatChars={{'a': '[A-Za-z.@0-9\-_]'}}
                               error={formik.errors.email}
                               placeholder='mail@mail.ru' label='Электронная почта'/>
                </div>
            </div>
            {/*legal_address*/}
            <TextInput onChange={formik.handleChange} name='legal_address' id='legal_address'
                       value={formik.values.legal_address} disabled
                       error={formik.errors.legal_address} isRequired
                       placeholder='Юридический адрес' label='Юридический адрес'/>
            {/*mail_address*/}
            <TextInput onChange={formik.handleChange} name='mail_address' id='mail_address'
                       value={formik.values.mail_address} disabled
                       error={formik.errors.mail_address} isRequired
                       placeholder='Почтовый адрес' label='Почтовый адрес'/>
        </div>
    )
}

export const Company = () => {

    const {user, setRender} = useAuth()

    const [scroll, setScroll] = useState(0)

    const [successModal, setSuccessModal] = useState('')
    const [errorModal, setErrorModal] = useState('')

    const data = React.useMemo(() => user.legal || user.individual, [user])

    const scrollRef = useCallback(scrollRef => {
        if ((scroll !== 0 && !scroll) || !scrollRef) return

        scrollRef.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [scroll])

    const formik = useFormik({
        initialValues: companySchema.cast({}),
        validationSchema: companySchema,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        validate: () => setScroll(prev => prev + 1),
        onSubmit: async values => {
            const data = {
                shortname_ru: values.shortname_ru,
                tin: values.tin,
                payment_account: values.payment_account,
                correspondent_account: values.correspondent_account,
                BIC: values.BIC,
                phone: values.phone,
                email: values.email
            }
            const response = await getAPI.editEntity(user.id, data)

            if (response?.error) {
                setErrorModal(getErrorMessageFromResponse(response))
            } else {
                setSuccessModal('Данные сохранены')
                setRender()
            }
        }
    })

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    useEffect(() => {
        formik.setValues(companySchema.cast(data))
    }, [data])

    return (
        <>
            <div className={classes.content} ref={scrollRef}>
                <h1>Редактирование данных компании</h1>
                <div className={classes.content_form}>
                    {formik.errors.message ? <div className='form_error_message'>
                        <p>{formik.errors.message}</p>
                    </div> : null}
                    {user.individual && <Individual formik={formik} data={data}/>}
                    {user.legal && <Legal formik={formik} data={data}/>}
                </div>
                <div className={classes.submit}>
                    <Button id='save_button' onClick={formik.submitForm} text='Сохранить'/>
                </div>
            </div>
            <SuccessModal close={() => setSuccessModal('')} description={successModal}
                          title='Успех' isShowing={successModal}/>
            <SuccessModal close={() => setErrorModal('')} description={errorModal} title='Ошибка'
                          isError isShowing={errorModal}/>
        </>
    )
}
