import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import getAllItems from "../../../utils/getAllItems";
import {getAPI} from "../../../api/api";
import {Modal} from "../Modal";
import TextInput from "../../FormControls/TextInput";
import clsx from "clsx";
import InputMask from "react-input-mask";
import TreeListInput from "../../FormControls/TreeListInput";
import {useParams} from "react-router";
import CheckboxInput from "../../FormControls/CheckboxInput";
import staticData from "../../../utils/staticData";
import DropdownInput from "../../FormControls/DropdownInput";
import DateInput from "../../FormControls/DateInput";

const EntityModal = ({close}) => {

    const style = {
        width: '700px'
    }

    const params = useParams()

    const [entityData, setEntityData] = useState({})
    const [districts, setDistricts] = useState([])
    const [isLoading, setLoading] = useState(true)

    const fetchEntity = async () => {
        const response = await getAPI.getEntity(params.id)

        if (response.error) {

        } else {
            setEntityData(response)
        }
    }

    const fetchDistricts = async () => {
        await getAllItems(getAPI.getDistricts(), setDistricts)
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            await Promise.all([fetchEntity(), fetchDistricts()]);
            setLoading(false);
        })()
    }, [params.id])

    return (
        <Modal close={close} isLoading={isLoading} title='Просмотр компании' style={style} isShowing>
            {entityData.tin?.length > 10 ?
                <IndividualFields data={entityData} districts={districts}/> :
                <LegalFields data={entityData} districts={districts}/>}
        </Modal>
    )
}

export const LegalFields = ({data, districts}) => {

    const formik = useFormik({
        initialValues: {
            fullname_ru: data.fullname_ru || '',
            shortname_ru: data.shortname_ru || '',
            organizational_form: data.organizational_form || '',
            vat_payer: data.vat_payer || true,
            vat_reason: data.vat_reason || 'Не указано',
            tin: data.tin || '',
            checkpoint: data.checkpoint || '',
            OGRN: data.OGRN || '',
            legal_address: data.legal_address || '',
            mail_address: data.mail_address || '',
            payment_account: data.payment_account || '',
            correspondent_account: data.correspondent_account || '',
            proxy: data.proxy || 'Устав',
            proxy_number: data.proxy_number || '',
            proxy_date: data.proxy_date || '',
            BIC: data.BIC || '',
            director: data.director || '',
            phone: data.phone || '',
            email: data.email || '',
            district: data.district || [],
        },
        initialErrors: {
            message: '',
        }
    })

    useEffect(() => {
        formik.setValues(prev => ({...prev, ...data}))
    }, [data])

    return (
        <div className='form'>
            {/*fullname_ru*/}
            <TextInput onChange={formik.handleChange} name='fullname_ru' id='fullname_ru' disabled
                       value={formik.values.fullname_ru} error={formik.errors.fullname_ru}
                       placeholder='Полное название' label='Полное название'/>
            <div className='form_columns'>
                <div className='form_columns_column'>
                    {/*shortname_ru*/}
                    <TextInput onChange={formik.handleChange} name='shortname_ru' id='shortname_ru' disabled
                               value={formik.values.shortname_ru} error={formik.errors.shortname_ru}
                               placeholder='Краткое название' label='Краткое название'/>
                    {/*district*/}
                    <TreeListInput allItems={districts.map(district => ({
                        id: district?.id,
                        name: district?.name,
                        items: district.subjects_rf
                    }))}
                                   buttonText='Выбрать округи' disabled
                                   setItems={values => formik.setFieldValue('district', values.map(value => value.id))}
                                   selectedLists={formik.values.district.map(districtId => {
                                       const district = districts.find(district => district.id === districtId)
                                       return {id: district?.id, name: district?.name, items: district?.subjects_rf}
                                   })} modalTitle='Выбор округа' id='district' label='Округ РФ'
                    />
                    {/*organizational_form*/}
                    <TextInput onChange={formik.handleChange} name='organizational_form' id='organizational_form'
                               value={formik.values.organizational_form} disabled
                               error={formik.errors.organizational_form}
                               placeholder='Организационная форма' label='Организационная форма'/>
                    {/*vat_payer*/}
                    <CheckboxInput onChange={formik.handleChange} name='vat_payer' id='vat_payer'
                                   error={formik.errors.vat_payer} label='Облагается налогом' disabled
                                   title='Плательщик НДС' checked={formik.values.vat_payer}/>
                    {/*vat_reason*/}
                    {!formik.values.vat_payer &&
                        <DropdownInput
                            id='vat_reason'
                            name='vat_reason'
                            onChange={value => formik.setFieldValue('vat_reason', value)}
                            value={formik.values.vat_reason}
                            error={formik.errors.vat_reason}
                            label='Причина'
                            items={staticData.company.vat_reason.map(reason => ({
                                id: reason.id,
                                name: reason.name,
                                value: reason.name
                            }))}
                            disabled
                        />}
                    {/*tin*/}
                    <TextInput onChange={formik.handleChange} name='tin' id='tin'
                               inputStyle={{letterSpacing: '5px'}} disabled
                               value={formik.values.tin} error={formik.errors.tin}
                               placeholder='__________' label='ИНН' mask='9999999999'/>
                    {/*checkpoint*/}
                    <TextInput onChange={formik.handleChange} name='checkpoint' id='checkpoint'
                               inputStyle={{letterSpacing: '5px'}} disabled
                               value={formik.values.checkpoint} error={formik.errors.checkpoint}
                               placeholder='_________' label='КПП' mask='999999999'/>
                </div>
                <div className='form_columns_column'>
                    {/*OGRN*/}
                    <TextInput onChange={formik.handleChange} name='OGRN' id='OGRN'
                               inputStyle={{letterSpacing: '5px'}} disabled
                               value={formik.values.OGRN} error={formik.errors.OGRN}
                               placeholder='_____________' label='ОГРН' mask='9999999999999'/>
                    {/*payment_account*/}
                    <TextInput onChange={formik.handleChange} name='payment_account' id='payment_account'
                               value={formik.values.payment_account} disabled
                               error={formik.errors.payment_account}
                               placeholder='Расчетный счет' label='Расчетный счет'/>
                    {/*correspondent_account*/}
                    <TextInput onChange={formik.handleChange} name='correspondent_account' id='correspondent_account'
                               value={formik.values.correspondent_account} error={formik.errors.correspondent_account}
                               inputStyle={{letterSpacing: '1px'}} mask='99999999999999999999' disabled
                               placeholder='____________________' label='Корреспондентский счет'/>
                    {/*proxy*/}
                    <DropdownInput
                        id='proxy'
                        name='proxy'
                        value={formik.values.proxy}
                        onChange={value => formik.setFieldValue('proxy', value)}
                        error={formik.errors.proxy}
                        label='Документ на право подписи'
                        items={[
                            {
                                id: 0,
                                name: 'Устав',
                                value: 'Устав'
                            },
                            {
                                id: 1,
                                name: 'Доверенность',
                                value: 'Доверенность'
                            }
                        ]}
                        disabled
                    />
                    {formik.values.proxy === 'Доверенность' &&
                        <>
                            <TextInput
                                id='proxy_number'
                                name='proxy_number'
                                onChange={formik.handleChange}
                                value={formik.values.proxy_number}
                                error={formik.errors.proxy_number}
                                placeholder='Номер доверенности'
                                label='Номер доверенности'
                                disabled
                            />
                            <DateInput
                                id='proxy_date'
                                name='proxy_date'
                                onChange={date => formik.setFieldValue('proxy_date', date)}
                                value={formik.values.proxy_date}
                                error={formik.errors.proxy_date}
                                label='Дата доверенности'
                                disabled
                            />
                        </>
                    }
                    {/*BIC*/}
                    <TextInput onChange={formik.handleChange} name='BIC' id='BIC'
                               inputStyle={{letterSpacing: '5px'}} disabled
                               value={formik.values.BIC} error={formik.errors.BIC}
                               placeholder='_________' label='БИК' mask='999999999'/>
                    {/*director*/}
                    <TextInput onChange={formik.handleChange} name='director' id='director'
                               value={formik.values.director} disabled
                               error={formik.errors.director}
                               placeholder='Руководитель' label='Руководитель'/>
                    {/*phone*/}
                    <TextInput onChange={formik.handleChange} name='phone' id='phone'
                               value={formik.values.phone} error={formik.errors.phone} disabled
                               placeholder='+79999999999' label='Контактный телефон' mask='+79999999999'/>
                    {/*email*/}
                    <TextInput onChange={formik.handleChange} name='email' id='email'
                               value={formik.values.email} disabled
                               error={formik.errors.email}
                               placeholder='mail@mail.ru' label='Электронная почта'/>
                </div>
            </div>
            {/*legal_address*/}
            <TextInput onChange={formik.handleChange} name='legal_address' id='legal_address'
                       value={formik.values.legal_address} disabled
                       error={formik.errors.legal_address}
                       placeholder='Юридический адрес' label='Юридический адрес'/>
            {/*mail_address*/}
            <TextInput onChange={formik.handleChange} name='mail_address' id='mail_address'
                       value={formik.values.mail_address} disabled
                       error={formik.errors.mail_address}
                       placeholder='Почтовый адрес' label='Почтовый адрес'/>
        </div>
    )
}

export const IndividualFields = ({data, districts = []}) => {

    const formik = useFormik({
        initialValues: {
            shortname_ru: data.shortname_ru || '',
            vat_payer: data.vat_payer || true,
            vat_reason: data.vat_reason || 'Не указано',
            tin: data.tin || '',
            OGRN: data.OGRN || '',
            register_address: data.register_address || '',
            payment_account: data.payment_account || '',
            BIC: data.BIC || '',
            phone: data.phone || '',
            email: data.email || '',
            district: data.district || [],
        },
        initialErrors: {
            message: '',
        }
    })

    useEffect(() => {
        formik.setValues(prev => ({...prev, ...data}))
    }, [data])

    return (
        <div className='form'>
            <div className='form_columns'>
                <div className='form_columns_column'>
                    {/*shortname_ru*/}
                    <TextInput onChange={formik.handleChange} name='shortname_ru' id='shortname_ru'
                               value={formik.values.shortname_ru} error={formik.errors.shortname_ru}
                               placeholder='ФИО' label='ФИО' disabled/>
                    {/*district*/}
                    <TreeListInput allItems={districts.map(district => ({
                        id: district?.id,
                        name: district?.name,
                        items: district.subjects_rf
                    }))}
                                   buttonText='Выбрать округи' disabled
                                   setItems={values => formik.setFieldValue('district', values.map(value => value.id))}
                                   selectedLists={formik.values.district.map(districtId => {
                                       const district = districts.find(district => district.id === districtId)
                                       return {id: district?.id, name: district?.name, items: district?.subjects_rf}
                                   })} modalTitle='Выбор округа' id='district' label='Округ РФ'
                    />
                    {/*vat_payer*/}
                    <CheckboxInput onChange={formik.handleChange} name='vat_payer' id='vat_payer'
                                   error={formik.errors.vat_payer} label='Облагается налогом' disabled
                                   title='Плательщик НДС' checked={formik.values.vat_payer}/>
                    {/*vat_reason*/}
                    {!formik.values.vat_payer &&
                        <DropdownInput
                            id='vat_reason'
                            name='vat_reason'
                            onChange={value => formik.setFieldValue('vat_reason', value)}
                            value={formik.values.vat_reason}
                            error={formik.errors.vat_reason}
                            label='Причина'
                            items={staticData.company.vat_reason.map(reason => ({
                                id: reason.id,
                                name: reason.name,
                                value: reason.name
                            }))}
                            disabled
                        />}
                    {/*tin*/}
                    <TextInput onChange={formik.handleChange} name='tin' id='tin' mask='999999999999'
                               value={formik.values.tin} error={formik.errors.tin}
                               inputStyle={{letterSpacing: '5px'}}
                               placeholder='____________' label='ИНН' disabled/>
                </div>
                <div className='form_columns_column'>
                    {/*OGRN*/}
                    <TextInput onChange={formik.handleChange} name='OGRN' id='OGRN' mask='999999999999999'
                               value={formik.values.OGRN} error={formik.errors.OGRN}
                               inputStyle={{letterSpacing: '5px'}}
                               placeholder='_______________' label='ОГРН' disabled/>
                    {/*register_address*/}
                    <TextInput onChange={formik.handleChange} name='register_address' id='register_address'
                               value={formik.values.register_address} error={formik.errors.register_address}
                               disabled
                               placeholder='Адрес регистрации' label='Адрес регистрации'/>
                    {/*payment_account*/}
                    <TextInput onChange={formik.handleChange} name='payment_account' id='payment_account'
                               value={formik.values.payment_account} error={formik.errors.payment_account}
                               disabled
                               placeholder='Расчетный счет' label='Расчетный счет'/>
                    {/*correspondent_account*/}
                    <TextInput onChange={formik.handleChange} name='correspondent_account' id='correspondent_account'
                               value={formik.values.correspondent_account} error={formik.errors.correspondent_account}
                               inputStyle={{letterSpacing: '1px'}} mask='99999999999999999999' disabled
                               placeholder='____________________' label='Корреспондентский счет'/>
                    {/*BIC*/}
                    <TextInput onChange={formik.handleChange} name='BIC' id='BIC' mask='999999999'
                               value={formik.values.BIC} error={formik.errors.BIC}
                               inputStyle={{letterSpacing: '5px'}} disabled
                               placeholder='_________' label='БИК'/>
                    {/*phone*/}
                    <div className='form_control'>
                        <div className='form_control_label'>
                            <label htmlFor='phone'>Контактный телефон</label>
                        </div>
                        <div
                            className={clsx('form_control_input', formik.errors.phone && 'form_control_input_error')}>
                            <InputMask mask='+79999999999' type="text" id='phone' name='phone'
                                       value={formik.values.phone} disabled
                                       onChange={formik.handleChange} placeholder='+79999999999'/>
                        </div>
                    </div>
                    {/*email*/}
                    <div className='form_control'>
                        <div className='form_control_label'>
                            <label htmlFor='email'>Электронная почта</label>
                        </div>
                        <div
                            className={clsx('form_control_input', formik.errors.email && 'form_control_input_error')}>
                            <input type="text" id='email' name='email' value={formik.values.email} disabled
                                   onChange={formik.handleChange} placeholder='info@mail.ru'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EntityModal
