import React, {useState} from "react";
import classes from "./Help.module.sass";
import {ReactComponent as Logo} from "../../assets/logo.svg";
import {getAPI} from "../../api/api";
import {SuccessModal} from "../../components/Modal/Modal";
import {useNavigate} from "react-router";
import Dropdown from "../../components/UI/Dropdown";
import {MenuItem} from "../../components/UI/Dropdown/Dropdown";

const questions = [
    {id: 0, text: 'Как мне получить доступ к системе OPERAX?', answer: 'Для получения доступа к системе OPERAX Вам необходимо пройти первичную регистрацию', image: 'https://motor.ru/imgs/2021/01/14/15/4455798/0fd7326a9a2ea867cad06dfe32c063ba2937de67.jpg'},
    {id: 1, text: 'Как мне получить доступ к системе OPERAX?', answer: 'Для получения доступа к системе OPERAX Вам необходимо пройти первичную регистрацию', image: ''},
]

const topics = [
    {id: 0, text: 'Обращение в службу технической поддержки'},
    {id: 1, text: 'Обращение в отдел продаж'},
    {id: 2, text: 'Заявка на оказание удаленной помощи'},
]

const Help = () => {

    const navigate = useNavigate()

    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [successModal, setSuccessModal] = useState(false)
    const [errorModal, setErrorModal] = useState(false)

    const sendMessageHandler = async () => {
        if(!title || !title.trim() || !message || !message.trim()) return
        const response = await getAPI.sendHelpMessage({title, message})
        if(response.error){
            setErrorModal(true)
        } else {
            setSuccessModal(true)
        }
    }

    return (
        <div className={classes.help}>
            <SuccessModal title={'Успех'} description={'Сообщение отправлено'} close={()=>setSuccessModal(false)} isShowing={successModal}/>
            <SuccessModal title={'Ошибка'} isError description={'Сообщение не было отправлено'} close={()=>setErrorModal(false)} isShowing={errorModal}/>
            <div className={classes.help_logo} onClick={() => navigate('/dashboard/users')}>
                <Logo/>
            </div>
            <div className={classes.help_title}>
                <h1>Помощь</h1>
            </div>
            <div className={classes.help_content}>
                <div className={classes.help_content_column}>
                    <h1>Вопросы и ответы</h1>
                    <div className={classes.help_content_column_card}>
                        {questions.map(question => (
                            <div className={classes.help_content_column_card_question} key={question.id}>
                                <h1>{question.text}</h1>
                                <div className={classes.help_content_column_card_question_answer}>
                                    <div className={classes.point}/>
                                    <div className={classes.help_content_column_card_question_answer_content}>
                                        <p>{question.answer}</p>
                                        {question.image ? <img alt='answer_img' src={question.image}/> : null}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.help_content_column}>
                    <h1>Контакты</h1>
                    <div className={classes.help_content_column_card}>
                        <p>Компания OPERAX является ведущим поставщиком мнформационных систем
                            по учету транспортных средств
                            на промышленных объектах.</p>
                        <div className={classes.help_content_column_card_contacts}>
                            <div className={classes.help_content_column_card_contacts_contact}>
                                <div className={classes.point}/>
                                <div className={classes.help_content_column_card_contacts_contact_text}>
                                    <p>Юридический адрес<br/>
                                        111111, г. Москва, ул. Московская, д.111, к.1</p>
                                </div>
                            </div>
                            <div className={classes.help_content_column_card_contacts_contact}>
                                <div className={classes.point}/>
                                <div className={classes.help_content_column_card_contacts_contact_text}>
                                    <p>Телефон для связи<br/>
                                        8 (999)-999-99-99</p>
                                </div>
                            </div>
                            <div className={classes.help_content_column_card_contacts_contact}>
                                <div className={classes.point}/>
                                <div className={classes.help_content_column_card_contacts_contact_text}>
                                    <p>Телефон службы поддержки<br/> 8 (800)-111-11-11</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.help_content_column}>
                    <h1>Написать нам</h1>
                    <div className={classes.help_content_column_card}>
                        <p style={{color: '#0A0A0A'}}>Введите текст сообщения и нажмите кнопку «Отправить»</p>
                        <div className={classes.help_content_column_card_appeal}>
                            <Dropdown
                                id='topic'
                                name='topic'
                                onChange={title => setTitle(title)}
                                value={title}
                                style={{maxWidth: '100%'}}
                            >
                                <MenuItem key='topic_0' id='topic_0' name='topic_0' value='' disabled>Выберите тему сообщения</MenuItem>
                                {topics.map(topic => (
                                    <MenuItem key={topic.id} id={topic.id} name={topic.text} value={topic.text}>{topic.text}</MenuItem>
                                ))}
                            </Dropdown>
                            <textarea onChange={e => setMessage(e.target.value)} name="message" id="message" placeholder='Напишите нам '/>
                            <div className={classes.help_content_column_card_appeal_send}>
                                <button onClick={sendMessageHandler}>
                                    Отправить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Help
