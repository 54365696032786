import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {createUser, editUser, getUserById, sendUserPasswordEmail} from "../../../redux/actions/Users";
import {Modal, SubmitMenu, SuccessModal} from "../Modal";
import staticData from "../../../utils/staticData";
import Button from "../../UI/Button";
import {getErrorMessageFromResponse} from "../../../utils/errors";
import {getAPI} from "../../../api/api";
import TextInput from "../../FormControls/TextInput";
import PasswordInput from "../../FormControls/PasswordInput";
import CheckboxInput from "../../FormControls/CheckboxInput";
import PhoneInput from "../../FormControls/PhoneInput";
import {useParams} from "react-router";
import userSchema from "../../../schemas/userSchema";
import {getMostFrequentlyEncounteredError} from "../../../schemas";
import DropdownInput from "../../FormControls/DropdownInput";

const UserModal = ({close}) => {

    const styles = {
        width: '500px',
    }

    const params = useParams()

    const [errorModal, setErrorModal] = useState('')
    const [successModal, setSuccessModal] = useState('')

    const [isWatch, setIsWatch] = useState(false)
    const [isLoading, setLoading] = useState(true)

    const [title, setTitle] = useState(params.type === 'edit' ? 'Редактирование пользователя' : 'Добавление пользователя')
    const [sendPasswordEmail, setSendPasswordEmail] = useState(false)
    const [scroll, setScroll] = useState(0)
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: userSchema.cast({}),
        validationSchema: userSchema,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validate: () => setScroll(prev => prev + 1),
        onSubmit: async values => {
            const data = {
                first_name: values.first_name.trim(),
                last_name: values.last_name.trim(),
                middle_name: values.middle_name.trim(),
                phone: values.phone,
                password: values.password,
                email: values.email,
                account: values.account,
            }

            if (params.id) {
                const response = await dispatch(editUser(params.id, data))

                if (response.error) {
                    setErrorModal(getErrorMessageFromResponse(response))
                } else {
                    if (sendPasswordEmail) {
                        dispatch(sendUserPasswordEmail({phone: formik.values.phone, password: formik.values.password}))
                    }
                    setSuccessModal('Пользователь изменен')
                }
            } else {
                const response = await dispatch(createUser(data))

                if (response.error) {
                    setErrorModal(getErrorMessageFromResponse(response))
                } else {
                    if (sendPasswordEmail) {
                        dispatch(sendUserPasswordEmail({phone: formik.values.phone, password: formik.values.password}))
                    }
                    setSuccessModal('Пользователь добавлен')
                }
            }
        }
    })

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    const fetchAccess = async () => {
        const response = await getAPI.editUserAccess(params.id)
        if (response.error) {
            setIsWatch(true)
            setTitle('Просмотр пользователя')
        } else {
            setIsWatch(false)
        }
    }

    const fetchUser = async () => {
        const user = await dispatch(getUserById(params.id))

        if (user.error) return

        await formik.setValues(userSchema.cast(user))

        await fetchAccess()

        if (user.status === 'Удален') {
            setIsWatch(true)
            setTitle('Просмотр пользователя')
        }
    }

    useEffect(() => {
        (async () => {
            if (!params.id) {
                setLoading(false)
                return
            }
            setLoading(true)
            await fetchUser()
            setLoading(false)
        })()
    }, [params.id])

    useEffect(() => {
        if (formik.values.status === 'Удален') {
            setIsWatch(true)
            return
        }
    }, [formik.values.status])

    const generatePassword = event => {
        event.preventDefault()

        const generatedPassword = Math.random().toString(36).slice(-8)

        formik.setFieldValue('password', generatedPassword)
    }

    return (
        <>
            <Modal close={close} title={title} style={styles} scroll={scroll} isLoading={isLoading} isShowing>
                {formik.errors.message && <div className='form_error_message' style={{top: '-50px'}}>
                    {formik.errors.message}
                </div>}
                <div className="form">
                    <div className='form_columns'>
                        <div className='form_columns_column'>
                            {/*last_name*/}
                            <TextInput onChange={formik.handleChange} name='last_name' id='last_name' disabled={isWatch}
                                       value={formik.values.last_name} error={formik.errors.last_name} isRequired
                                       mask='aaaaaaaaaaaaaaa' maskChar='' formatChars={{'a': '[A-Za-zА-Яа-яёЁ]'}}
                                       placeholder='Фамилия' label='Фамилия'/>
                            {/*first_name*/}
                            <TextInput onChange={formik.handleChange} name='first_name' id='first_name' disabled={isWatch}
                                       value={formik.values.first_name} error={formik.errors.first_name} isRequired
                                       mask='aaaaaaaaaaaaaaa' maskChar='' formatChars={{'a': '[A-Za-zА-Яа-яёЁ]'}}
                                       placeholder='Имя' label='Имя'/>
                            {/*middle_name*/}
                            <TextInput onChange={formik.handleChange} name='middle_name' id='middle_name' disabled={isWatch}
                                       value={formik.values.middle_name} error={formik.errors.middle_name}
                                       mask='aaaaaaaaaaaaaaa' maskChar='' formatChars={{'a': '[A-Za-zА-Яа-яёЁ]'}}
                                       placeholder='Отчество' label='Отчество'/>
                            {/*phone*/}
                            <PhoneInput
                                onChange={value => formik.setFieldValue('phone', value)} isRequired
                                disabled={isWatch} formValue={formik.values.phone} error={formik.errors.phone}
                                label='Номер телефона' name='phone' id='phone'
                            />
                        </div>
                        <div className='form_columns_column'>
                            {/*email*/}
                            <TextInput onChange={formik.handleChange} name='email' id='email' disabled={isWatch}
                                       value={formik.values.email} error={formik.errors.email}
                                       mask='aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' maskChar=''
                                       formatChars={{'a': '[A-Za-z.@0-9\-_]'}}
                                       placeholder='mail@mail.ru' label='Электронная почта'/>
                            {/*account*/}
                            <DropdownInput
                                id='account'
                                name='account'
                                onChange={value => formik.setFieldValue('account', value)}
                                error={formik.errors.account}
                                value={formik.values.account}
                                disabled={isWatch}
                                label='Роль'
                                isRequired
                                items={staticData.user.account.map(role => ({
                                    id: role.id,
                                    value: role.name,
                                    name: role.name
                                }))}
                            />
                            {/*password*/}
                            <PasswordInput onChange={formik.handleChange} name='password' id='password' disabled={isWatch}
                                           value={formik.values.password} error={formik.errors.password} label='Пароль'
                                           isRequired/>
                            <div className={'form_control'}>
                                <div className={'form_control_label'}>
                                    <label>&nbsp;</label>
                                </div>
                                <div className={'form_control_input'}>
                                    <Button onClick={generatePassword} disabled={isWatch}
                                            text='Сгенерировать пароль'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isWatch &&
                    <CheckboxInput onChange={event => setSendPasswordEmail(event.target.checked)} name='sendPassword'
                                   id='sendPassword' checked={sendPasswordEmail}
                                   label='Отправить пароль на электронную почту'
                                   disabled={!formik.values.email?.length}/>}
                {!isWatch && <SubmitMenu close={close} saveHandler={formik.submitForm}/>}
            </Modal>
            {successModal && <SuccessModal close={() => {
                setSuccessModal('');
                close()
            }} title='Успех' description={successModal} isShowing={successModal}/>}
            {errorModal && <SuccessModal isError close={() => {
                setErrorModal('');
            }} title='Ошибка' description={errorModal} isShowing={errorModal}/>}
        </>
    )
}

export default UserModal
