import {
    VEHICLES_ADD_VEHICLES,
    VEHICLES_SET_LOADING,
    VEHICLES_SET_NEXT,
    VEHICLES_SET_RENDER,
    VEHICLES_SET_VEHICLES
} from "../types";

const Vehicles = (state = {
    vehicles: [],
    render: 0,
    loading: false,
    next: null
}, action) => {
    switch (action.type) {
        case VEHICLES_SET_VEHICLES:
            return {
                ...state,
                vehicles: action.payload.vehicles
            }
        case VEHICLES_SET_RENDER:
            return {
                ...state,
                render: state.render + 1
            }
        case VEHICLES_ADD_VEHICLES:
            return {
                ...state,
                vehicles: [...state.vehicles, ...action.payload.vehicles]
            }
        case VEHICLES_SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case VEHICLES_SET_NEXT:
            return {
                ...state,
                next: action.payload.next
            }
        default:
            return state
    }
}

export default Vehicles