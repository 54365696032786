import React, {useEffect, useState} from "react";
import classes from './DocumentModal.module.sass'
import {Modal, SubmitMenu} from "../Modal";
import DateInput from "../../FormControls/DateInput";
import {useFormik} from "formik";
import {getAPI} from "../../../api/api";
import TextInput from "../../FormControls/TextInput";
import ListInput from "../../FormControls/ListInput";
import {driverDocumentSchema} from "../../../schemas/driverSchema";
import {getMostFrequentlyEncounteredError} from "../../../schemas";
import DropdownInput from "../../FormControls/DropdownInput";
import useLoadableData from "../../../hooks/useLoadableData";

const DocumentModal = ({close, setDocuments, isShowing}) => {

    const [types, fetchTypes] = useLoadableData(
        getAPI.getDriverTypes,
        {},
        error => {
        },
        types => types.map(type => ({
            ...type, types: type.types.map(category => ({
                id: category.id,
                code: category.code,
                description: category.description
            }))
        }))
    )

    const style = {
        width: '350px'
    }

    useEffect(() => {
        fetchTypes()
    }, [])

    const formik = useFormik({
        initialValues: driverDocumentSchema.cast({}),
        validationSchema: driverDocumentSchema,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit: async values => {
            setDocuments({
                ...values,
                category: values.category.map(category => ({
                    id: category.id,
                    name: category.code || '-'
                }))
            })
            close()
        }
    })

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    useEffect(() => {
        formik.setValues(driverDocumentSchema.cast({
            type: formik.values.type
        }))
    }, [formik.values.type])

    useEffect(() => {
        const driver_license = formik.values.driver_license
        if (driver_license.includes(' ') || driver_license.includes('_')) {
            formik.setFieldValue('driver_license', driver_license.replaceAll(' ', '').replaceAll('_', ''))
        }
    }, [formik.values.driver_license])

    return (
        <Modal close={close} title='Добавление документа' style={style} isShowing={isShowing}>
            {formik.errors.message ? <div className='form_error_message'>
                <p>{formik.errors.message}</p>
            </div> : null}
            <div className='form'>
                {/*type*/}
                <DropdownInput
                    id='type'
                    name='type'
                    onChange={value => formik.setFieldValue('type', value)}
                    items={[
                        {id: '-1', name: 'Не выбрано', value: null},
                        ...types.data.map(type => ({
                            id: type.id,
                            name: type.name,
                            value: type
                        }))]}
                    error={formik.errors.type}
                    value={formik.values.type}
                    label='Тип документа'
                    isRequired
                    loading={types.loading}
                    onScrollBottom={fetchTypes}
                    displayText={formik.values.type?.name || 'Не выбрано'}
                />
                {/*category*/}
                {formik.values.type &&
                    <ListInput
                        allItems={formik.values.type?.types.map(category => ({
                            id: category.id,
                            name: category.code ? `${category.code} - ${category.description}` : category.description,
                            description: category.description,
                            code: category.code
                        })) || []}
                        buttonText='Выбрать допуски'
                        setItems={categories => {
                            formik.setFieldValue('category', categories.map(category => ({
                                id: category.id,
                                code: category.code,
                                description: category.description
                            })))
                        }}
                        selectedItems={formik.values.category.map(category => ({
                            id: category.id,
                            name: category.code ? `${category.code} - ${category.description}` : category.description,
                            description: category.description,
                            code: category.code
                        }))}
                        modalTitle='Выбор допусков'
                        error={formik.errors.category}
                        id='category'
                        label='Категории'
                        disabled={!formik.values.type}
                        isRequired
                    />}
                {/*driver_license*/}
                <TextInput onChange={formik.handleChange} name='driver_license' value={formik.values.driver_license}
                           error={formik.errors.driver_license} label='Серия и номер' id='driver_license' isRequired
                           placeholder='Введите серию и номер' mask='9999 999999'/>
                <div className="form_columns">
                    <div className="form_columns_column">
                        <DateInput
                            error={formik.errors.driver_license_date} id='driver_license_date'
                            name='driver_license_date' label='Дата выдачи' isRequired
                            value={formik.values.driver_license_date}
                            onChange={(date) => formik.setFieldValue('driver_license_date', date)}
                        />
                    </div>
                    <div className='form_columns_column'>
                        <DateInput
                            error={formik.errors.driver_license_deadline} name='driver_license_deadline'
                            id='driver_license_deadline' label='Срок действия' isRequired
                            value={formik.values.driver_license_deadline}
                            onChange={(date) => formik.setFieldValue('driver_license_deadline', date)}
                        />
                    </div>
                </div>
            </div>
            <SubmitMenu close={close} saveHandler={formik.submitForm}/>
        </Modal>
    )
}

export default DocumentModal
