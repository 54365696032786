import React from "react";
import classes from './Search.module.sass'
import {ReactComponent as Icon} from "../../../assets/settings/search.svg";
import clsx from "clsx";

const Search = ({style, onChange = () => {}, value, placeholder, size='medium'}) => {

    return (
        <div className={clsx(classes.search, classes[size])} style={style}>
            <div className={clsx(classes.search_content, classes[size])}>
                <div className={classes.search_content_icon}>
                    <Icon/>
                </div>
                <div className={classes.search_content_input}>
                    <input type="text" onChange={onChange} value={value} placeholder={placeholder}/>
                </div>
            </div>
        </div>
    )
}

export default Search