import React from "react";
import classes from './ConfirmModal.module.sass'
import {Modal} from "../Modal";
import Button from "../../UI/Button";

const ConfirmModal = ({
                          title,
                          text,
                          close,
                          declineHandler = () => {
                          },
                          confirmHandler,
                          isShowing,
                          confirmText = 'ОК',
                          declineText = 'Отмена'
                      }) => {

    const style = {
        width: '450px',
        height: 'min-content'
    }

    const confirm = async event => {
        await confirmHandler(event)
        close()
    }

    const decline = async event => {
        await declineHandler(event)
        close()
    }

    return (
        <Modal close={close} title={title} style={style} isShowing={isShowing}>
            <p>{text}</p>
            <div className={classes.menu}>
                <Button style={{minWidth: '100px'}} id='confirm_accept' onClick={confirm} text={confirmText} type='primary'/>
                <Button style={{minWidth: '100px'}} id='confirm_decline' onClick={decline} text={declineText} type='secondary'/>
            </div>
        </Modal>
    )
}

export default ConfirmModal
