import {getAPI} from "../../api/api";
import {
    SETTINGS_ADD_USERS,
    SETTINGS_SET_LOADING, SETTINGS_SET_NEXT,
    SETTINGS_SET_RENDER,
    SETTINGS_SET_USERS
} from "../types";
import {store} from "../store";

export const getUsers = params => async dispatch => {
    dispatch(setLoading(true))

    const response = await getAPI.getUsers(params)
    if(!response.error){
        dispatch(setUsers(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const suppleUsers = () => async dispatch => {
    const {next} = store.getState().Users

    if(!next) return

    dispatch(setLoading(true))

    const response = await getAPI.getRequest(next)
    if(!response.error){
        dispatch(addUsers(response.results))
        dispatch(setNext(response.next))
    }

    dispatch(setLoading(false))
    return response
}

export const deleteUser = users => async dispatch => {
    const response = await getAPI.deleteUser(users)
    if(!response.error) dispatch(setRender())

    return response
}

export const createUser = data => async dispatch => {
    const response = await getAPI.addUser(data)
    if(!response.error) dispatch(setRender())

    return response
}

export const editUser = (id, data) => async dispatch => {
    const response = await getAPI.editUser(id, data)
    if(!response.error) dispatch(setRender())

    return response
}

export const blockUser = users => async dispatch => {
    const response = await getAPI.blockUser(users)
    if(!response.error) dispatch(setRender())

    return response
}

export const getUserById = id => async dispatch => {
    return await getAPI.getUserById(id)
}

export const sendUserPasswordEmail = data => async dispatch => {
    return await getAPI.sendPasswordEmail(data)
}

const setUsers = users => ({type: SETTINGS_SET_USERS, payload: {users}})

const addUsers = users => ({type: SETTINGS_ADD_USERS, payload: {users}})

const setRender = () => ({type: SETTINGS_SET_RENDER})

const setLoading = loading => ({type: SETTINGS_SET_LOADING, payload: {loading}})

const setNext = next => ({type: SETTINGS_SET_NEXT, payload: {next}})
