import React from "react";
import {
    YMaps,
    Map as YMap,
    ZoomControl,
    FullscreenControl,
    TrafficControl
} from "react-yandex-maps";
import classes from "../../components/Map/Map.module.sass";
import {YANDEX_MAP_API_KEY} from "../../config";

const defaultState = {
    center: [59.934280, 30.335099],
    zoom: 12
}

const Map = React.forwardRef(({onLoad, modules, children}, instanceRef) => {


    return (
        <YMaps query={{apikey: YANDEX_MAP_API_KEY}}>
            <YMap defaultState={defaultState} width='100%' height='100%' onLoad={onLoad}
                  instanceRef={instanceRef} modules={modules} options={{suppressMapOpenBlock: true}}>
                {children}
                <ZoomControl/>
                <FullscreenControl/>
                <TrafficControl/>
            </YMap>
        </YMaps>
    )

})

//--------------------------------------------------------------------------------------
//Components

const svg = '<svg width="23" height="15" viewBox="0 0 23 15" fill="#213848" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M5.25965 14.2995C6.37063 14.2995 7.27125 13.4063 7.27125 12.3045C7.27125 11.2028 6.37063 10.3096 5.25965 10.3096C4.14867 10.3096 3.24805 11.2028 3.24805 12.3045C3.24805 13.4063 4.14867 14.2995 5.25965 14.2995Z" stroke="#C3CFD8" stroke-width="0.734375" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M18.6493 14.2995C19.7603 14.2995 20.6609 13.4063 20.6609 12.3045C20.6609 11.2028 19.7603 10.3096 18.6493 10.3096C17.5383 10.3096 16.6377 11.2028 16.6377 12.3045C16.6377 13.4063 17.5383 14.2995 18.6493 14.2995Z" stroke="#C3CFD8" stroke-width="0.734375" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M21.9996 8.31543V12.3054H20.6613C20.6285 11.7953 20.4027 11.3169 20.0298 10.9672C19.657 10.6176 19.165 10.423 18.6539 10.423C18.1427 10.423 17.6508 10.6176 17.2779 10.9672C16.905 11.3169 16.6793 11.7953 16.6464 12.3054H15.2998V8.31543H21.9996Z" stroke="#C3CFD8" stroke-width="0.734375" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M21.9996 8.31515V12.3051H20.6613C20.6285 11.795 20.4027 11.3166 20.0298 10.967C19.657 10.6173 19.165 10.4228 18.6539 10.4228C18.1427 10.4228 17.6508 10.6173 17.2779 10.967C16.905 11.3166 16.6793 11.795 16.6464 12.3051H15.2998V4.3252H18.1011L21.9996 8.31515Z" stroke="#C3CFD8" stroke-width="0.734375" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M15.3 1V12.3049H7.27019C7.23735 11.7948 7.01156 11.3163 6.6387 10.9667C6.26585 10.6171 5.77388 10.4225 5.26274 10.4225C4.7516 10.4225 4.25963 10.6171 3.88677 10.9667C3.51391 11.3163 3.28813 11.7948 3.25529 12.3049H1.21875V1H15.3Z" stroke="#C3CFD8" stroke-width="0.734375" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</svg>'

export const placeMarkContentLayoutHTML = color =>
    `<div class=${classes.mark} style='background: ${color}'>${svg}</div>`

export const vehiclePlaceMark = ({brand, model, registration_number, color}) => {

    return `
            <div class=${classes.placemark} style='border-color: ${color}'>
                <div class=${classes.placemark_icon} style='background: ${color}'>
                    ${svg}
                </div>
                <div class=${classes.placemark_content}>
                    <h1>${brand}&nbsp;${model}</h1>
                    <p>${registration_number}</p>
                </div>
            </div>
    `
}

export const vehicleBalloonContentLayoutHTML = ({creator, id, type, brand, model, registration_number, status}) => `
        <div class=${classes.balloon} id="balloon_${id}">
            <p id="vehicle-creator"><b>${creator}</b></p>
            <p id="vehicle-id">Транспортное средство #${id}</p>
            <p id="vehicle-type">${type}</p>
            <p id="vehicle-name">${brand} ${model} | ${registration_number}</p>
            <p id="vehicle-status">${status}</p>
        </div>
`

export default Map
