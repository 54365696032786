import React from "react";
import InputMask from "react-input-mask";
import clsx from "clsx";
import PropTypes from "prop-types";

const TextInput = React.forwardRef(({
                                        id,
                                        name = '',
                                        mask = '',
                                        formatChars = undefined,
                                        disabled = false,
                                        value,
                                        error,
                                        icon,
                                        addition,
                                        onChange = () => {
                                        },
                                        onBlur = () => {
                                        },
                                        onFocus = () => {
                                        },
                                        onSelect = () => {

                                        },
                                        label = '',
                                        helper = undefined,
                                        helperStyle = {},
                                        iconStyle = {},
                                        additionStyle = {},
                                        inputStyle = {},
                                        labelStyle = {},
                                        innerStyle = {},
                                        outerStyle = {},
                                        placeholder = '',
                                        isRequired = false,
                                    }, ref) => {


    const handleChange = event => {
        if (event.type !== 'change') return
        onChange(event)
    }

    return (
        <div className='form_control' style={outerStyle}>
            {label && <div className='form_control_label'>
                <label htmlFor={id} style={labelStyle}>{label} {isRequired && <span>*</span>}</label>
            </div>}
            <div className={clsx('form_control_input', error && 'form_control_input_error')} style={innerStyle}>
                <InputMask type='text' id={id} name={name} mask={mask} style={inputStyle} disabled={disabled}
                           onChange={handleChange} value={value} formatChars={formatChars} placeholder={placeholder}
                           autoComplete='off' maskChar='' onFocus={onFocus} onBlur={onBlur}
                           inputRef={target => ref && (ref.current = target)}/>
                {icon && <div className='form_control_icon' style={iconStyle}>
                    {icon}
                </div>}
            </div>
            {helper && <div className='form_control_helper' style={helperStyle}>
                {helper}
            </div>}
            {addition && <div className='form_control_addition' style={additionStyle}>
                {addition}
            </div>}
        </div>
    )
})

TextInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    mask: PropTypes.string,
    formatChars: PropTypes.shape({}),
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    error: PropTypes.any,
    label: PropTypes.string,
    inputStyle: PropTypes.shape({}),
    labelStyle: PropTypes.shape({}),
    innerStyle: PropTypes.shape({}),
    outerStyle: PropTypes.shape({}),
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
}

export default TextInput
