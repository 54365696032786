import {Modal, SubmitMenu} from "../Modal";
import classes from './TariffModal.module.sass'
import Search from "../../UI/Search";
import {useEffect, useState} from "react";
import Button from "../../UI/Button";
import {ReactComponent as Trash} from "../../../assets/trash.svg";

const TariffModal = ({close, tariffVehicles, setTariffVehicles, tariffs, isShowing}) => {

    const [selectedVehicles, setSelectedVehicles] = useState([])
    const [selectedTariff, setSelectedTariff] = useState(null)
    const [searchByTariff, setSearchByTariff] = useState('')

    const [updatedTariffVehicles, setUpdatedTariffVehicles] = useState([])

    const style = {
        width: '670px',
        maxHeight: '1060px'
    }

    useEffect(() => {
        setUpdatedTariffVehicles(tariffVehicles)
    }, [tariffVehicles])

    const selectVehicle = vehicle => {
        if (!selectedVehicles.some(selectedVehicle => vehicle.id === selectedVehicle.id)) {
            setSelectedVehicles(prev => [...prev, vehicle])
        } else {
            setSelectedVehicles(prev => prev.filter(selectedVehicle => vehicle.id !== selectedVehicle.id))
        }
    }

    const selectTariff = tariff => {
        setSelectedTariff(tariff)
    }

    const selectAllVehicles = event => {
        if (event.target.checked) {
            setSelectedVehicles(updatedTariffVehicles.filter(t_v => !t_v.tariff).map(t_v => t_v.vehicle))
        } else {
            setSelectedVehicles([])
        }
    }

    const setTariff = () => {
        setUpdatedTariffVehicles(prev => [...prev.filter(t_v => !selectedVehicles.some(selectedVehicle => selectedVehicle.id === t_v.vehicle.id)), ...selectedVehicles.map(vehicle => ({
            vehicle: {id: vehicle.id, name: vehicle.name},
            tariff: selectedTariff
        }))])
        setSelectedTariff(null)
        setSelectedVehicles([])
    }

    const removeHandler = vehicle => {
        setUpdatedTariffVehicles(prev => [...prev.filter(t_v => t_v.vehicle.id !== vehicle.id), {
            vehicle: {
                id: vehicle.id,
                name: vehicle.name
            }, tariff: null
        }])
    }

    return (
        <Modal style={style} close={close} title='Назначение тарифных планов' isShowing={isShowing}>
            <div className={classes.columns}>
                <div className={classes.columns_column}>
                    <h1>Транспортные средства</h1>
                    <div className={classes.columns_column_header}>
                        <div className={classes.columns_column_header_checkbox}>
                            <input type="checkbox" id='vehicle' name='vehicle'
                                   onChange={selectAllVehicles}/>
                            <label htmlFor="vehicle">Выбрать все</label>
                        </div>
                        <Search placeholder='Поиск' size='small'/>
                    </div>
                    <div className={classes.columns_column_content}>
                        {updatedTariffVehicles.filter(t_v => !t_v.tariff).map(t_v => t_v.vehicle).map(vehicle => {
                            return (
                                <div className={classes.columns_column_content_item}
                                     key={vehicle?.id}>
                                    <input type="checkbox" id={vehicle.id + vehicle.name}
                                           checked={selectedVehicles.some(selectedVehicle => vehicle.id === selectedVehicle.id)}
                                           onChange={() => selectVehicle(vehicle)}/>
                                    <label>{vehicle.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={classes.columns_column}>
                    <h1>Тарифы</h1>
                    <div className={classes.columns_column_header}>
                        <div></div>
                        <Search size='small' placeholder='Поиск'
                                onChange={({target}) => setSearchByTariff(target.value.toLowerCase())}/>
                    </div>
                    <div className={classes.columns_column_content}>
                        {tariffs
                            .filter(tariff => tariff.name.toLowerCase().includes(searchByTariff))
                            .map(tariff => (
                                <div className={classes.columns_column_content_item} key={tariff.id}>
                                    <input type="radio" id={tariff.id + tariff.name}
                                           checked={selectedTariff?.id === tariff.id}
                                           onClick={() => selectTariff(tariff)}/>
                                    <label>{tariff.name}</label>
                                </div>
                            ))}
                    </div>
                    <div className={classes.columns_column_button}>
                        <Button onClick={setTariff} disabled={!selectedTariff || !selectedVehicles.length}
                                text='Назначить тариф'/>
                    </div>
                </div>
            </div>
            <div className={classes.tariffs}>
                <h1>Назначенные тарифы</h1>
                <table className={classes.tariffs_table}>
                    <thead>
                    <tr>
                        <th>Транспортное средство</th>
                        <th>Тариф</th>
                        {/*<th style={{flexBasis: '10%', visibility: 'hidden'}}></th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {updatedTariffVehicles.map(t_v => {
                        const {vehicle, tariff} = t_v
                        if (!tariff) return <></>
                        return (
                            <tr key={vehicle.id + tariff.id}>
                                <td>{vehicle.name}</td>
                                <td>{tariff.name}</td>
                                <Trash style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: '12px',
                                    right: '15px'
                                }}
                                       onClick={() => removeHandler(vehicle)}/>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <SubmitMenu style={{marginTop: '20px'}} close={close} saveHandler={() => {
                setTariffVehicles(updatedTariffVehicles);
                close()
            }}/>
        </Modal>
    )
}

export default TariffModal
