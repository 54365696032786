import React, {useEffect, useState} from "react";
import classes from './VehicleModal.module.sass'
import {Modal, SubmitMenu, SuccessModal} from "../Modal";
import {useFormik} from "formik";
import {getAPI} from "../../../api/api";
import clsx from "clsx";
import staticData from "../../../utils/staticData";
import {timeFromISO, timeToISO} from "../../../utils/timeConverters";
import {useDispatch} from "react-redux";
import {createVehicle, editVehicle} from "../../../redux/actions/Vehicles";
import getAllItems from "../../../utils/getAllItems";
import {getErrorMessageFromResponse} from "../../../utils/errors";
import DateInput from "../../FormControls/DateInput";
import TextInput from "../../FormControls/TextInput";
import TextareaInput from "../../FormControls/TextareaInput";
import CheckboxInput from "../../FormControls/CheckboxInput";
import TreeListInput from "../../FormControls/TreeListInput";
import {useNavigate, useParams} from "react-router";
import useAuth from "../../../hooks/useAuth";
import Button from "../../UI/Button";
import {getMostFrequentlyEncounteredError} from "../../../schemas";
import vehicleSchema from "../../../schemas/vehicleSchema";
import DropdownInput from "../../FormControls/DropdownInput";
import Dropdown from "../../UI/Dropdown";
import {MenuItem} from "../../UI/Dropdown/Dropdown";
import useLoadableData from "../../../hooks/useLoadableData";

const configInfoModal = (title, description, close, isShowing, isError) => {
    return {title, description, close, isShowing, isError}
}

const VehicleModal = ({close}) => {

    const successClose = () => {
        setInfoModal(data => ({
            ...data,
            isShowing: false
        }))
        close()
    }

    const errorClose = () => {
        setInfoModal(data => ({
            ...data,
            isShowing: false
        }))
    }

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const {user} = useAuth()

    const [infoModal, setInfoModal] = useState(configInfoModal('', '', successClose, false, false))

    const formik = useFormik({
        initialValues: vehicleSchema.cast({
            id: params.id,
            creator: user?.id
        }),
        validationSchema: vehicleSchema,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validate: () => setScroll(prev => prev + 1),
        onSubmit: async (values) => {
            const data = {
                ...values,
                type: values.type?.id || '',
                brand: values.brand?.id || '',
                subject: values.subject?.id || '',
                driver: values.driver?.id || '',
                type_doc: values.type_doc?.id || '',
                type_doc_admiss: values.type_doc_admiss?.id || '',
                type_registration: values.type_registration?.id || '',
                type_admiss: values.type_admiss.map(type => type.types).flat(1).map(type => type.id),
                date_doc: timeToISO(values.date_doc) || '',
                deadline_doc: timeToISO(values.deadline_doc) || '',
                date_admiss: timeToISO(values.date_admiss) || ''
            }

            if (pageType === 'edit') {
                try {
                    const response = await dispatch(editVehicle(formik.values.id, data))

                    if (response.error) {
                        setInfoModal(configInfoModal('Ошибка', getErrorMessageFromResponse(response), errorClose, true, true))
                    } else {
                        setInfoModal(configInfoModal('Успех', 'ТС изменено', successClose, true, false))
                    }
                } catch (e) {
                    setInfoModal(configInfoModal('Ошибка', getErrorMessageFromResponse(e), errorClose, true, true))
                }
            } else {
                try {
                    const response = await dispatch(createVehicle(data))

                    if (response.error) {
                        setInfoModal(configInfoModal('Ошибка', getErrorMessageFromResponse(response), errorClose, true, true))
                    } else {
                        setInfoModal(configInfoModal('Успех', 'ТС добавлено', successClose, true, false))
                    }
                } catch (e) {
                    setInfoModal(configInfoModal('Ошибка', getErrorMessageFromResponse(e), errorClose, true, true))
                }
            }

        },
    })

    const [pageType, setPageType] = useState(params.type)
    const [title, setTitle] = useState(pageType === 'edit' ? 'Редактирование ТС' : 'Добавление ТС')

    // стейт для хранения типов ТС в виде {id, name}
    const [types, fetchTypes] = useLoadableData(
        getAPI.getVehicleTypes,
        {},
        error => setInfoModal(configInfoModal('Ошибка', error, errorClose, true, true)))

    // стейт для хранения марок ТС в виде {id, name}
    const [brands, fetchBrands] = useLoadableData(
        getAPI.getVehicleBrands,
        {},
        error => setInfoModal(configInfoModal('Ошибка', error, errorClose, true, true)))

    // стейт для хранения объектов в виде {id, name}
    // запрос принимает в себя параметры:
    // type__id - id типов ТС, которые могут содержаться на объекте,
    const [objects, fetchObjects] = useLoadableData(
        getAPI.getObjects,
        {type__id: formik.values.type?.id},
        error => setInfoModal(configInfoModal('Ошибка', error, errorClose, true, true)),
        objects => objects.map(object => ({id: object.id, name: object.show_name})))

    // стейт для хранения водителей в виде {id, name}
    // запрос принимает в себя параметры:
    // type_admiss - id допусков, которыми могут обладать водители,
    // executors - id владельца ТС
    const [drivers, fetchDrivers] = useLoadableData(
        getAPI.getDrivers,
        {
            type_admiss: formik.values.type_admiss.map(type => type.types).flat(1).map(type => type.id).join(','),
            executors: formik.values.creator,
            free: 'True'
        },
        error => setInfoModal(configInfoModal('Ошибка', error, errorClose, true, true)),
        drivers => drivers.map(driver => ({id: driver.id, name: `${driver.last_name} ${driver.first_name}`}))
    )

    // стейт для хранения типов регистрационных документов в виде {id, name}
    const [typeDocument, fetchTypeDocument] = useLoadableData(
        getAPI.getTypeDocument,
        {},
        error => setInfoModal(configInfoModal('Ошибка', error, errorClose, true, true)),
    )

    // стейт для хранения типов регистрационных номеров в виде {id, name}
    const [typeRegister, fetchTypeRegister] = useLoadableData(
        getAPI.getTypeRegister,
        {},
        error => setInfoModal(configInfoModal('Ошибка', error, errorClose, true, true)),
    )

    // стейт для хранения типов подтверждающих документов в виде {id, name}
    const [typeDocAdmiss, fetchTypeDocAdmiss] = useLoadableData(
        getAPI.getTypeDocAdmiss,
        {},
        error => setInfoModal(configInfoModal('Ошибка', error, errorClose, true, true)),
    )

    // стейт для хранения списка допусков в виде {id, name}
    const [typeAdmiss, setTypeAdmiss] = useState([])

    const [scroll, setScroll] = useState(0)
    const [isWatch, setIsWatch] = useState(false)
    const [isLoading, setLoading] = useState(true)

    const style = {
        width: '700px'
    }

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    useEffect(() => {
        setTitle(pageType === 'edit' ? 'Редактирование ТС' : 'Добавление ТС')
    }, [pageType])

    // Проверка доступа. Если доступа нет, включается режим просмотра
    const fetchAccess = async () => {
        const response = await getAPI.editVehicleAccess(params.id)

        if (response.error) {
            setIsWatch(true)
            setTitle('Просмотр ТС')
        } else {
            setIsWatch(false)
            setPageType('edit')
        }
    }

    // Получение данных о текущем ТС
    const fetchVehicle = async () => {
        const vehicle = await getAPI.getVehicleById(params.id)

        if (vehicle.error) return

        await formik.setValues(vehicleSchema.cast({
            ...vehicle,
            creator: vehicle.creator?.id,
            subject: vehicle.subject ? {id: vehicle.subject?.id, name: vehicle.subject?.show_name} : null,
            driver: vehicle.driver ? {
                id: vehicle.driver?.id,
                name: `${vehicle.driver?.last_name} ${vehicle.driver?.first_name}`
            } : null,
            type_doc: vehicle.type_doc || null,
            type_registration: vehicle.type_registration || null,
            type_doc_admiss: vehicle.type_doc_admiss || null,
            date_doc: timeFromISO(vehicle.date_doc).date,
            deadline_doc: timeFromISO(vehicle.deadline_doc).date,
            date_admiss: timeFromISO(vehicle.date_admiss).date,
            type_admiss: vehicle.type_admiss.map(category => ({
                id: category.id,
                name: category.name,
                types: category.types.map(type => ({
                    id: type.id,
                    name: type.code ? `${type.code} - ${type.description}` : type.description
                }))
            }))
        }))

        await fetchAccess()
    }

    const fetchTypeAdmiss = async () => {
        await getAllItems(getAPI.getDriverTypes(), setTypeAdmiss)
        await setTypeAdmiss(prev => prev.map(category => ({
            id: category.id,
            name: category.name,
            types: category.types.map(type => ({
                id: type.id,
                name: type.code ? `${type.code} - ${type.description}` : type.description
            }))
        })))
    }

    // данная функция необходима для ввода масок заглавными символами
    const maskInputHandler = event => {
        event.target.value = event.target.value.toUpperCase()
        formik.handleChange(event)
    }

    useEffect(() => {
        fetchDrivers()
    }, [formik.values.type_admiss])

    useEffect(() => {
        (async () => {
            setLoading(true)
            await fetchVehicle()
            setLoading(false)

            await Promise.all([fetchTypes(), fetchBrands(), fetchTypeDocument(), fetchTypeRegister(), fetchTypeDocAdmiss(), fetchTypeAdmiss(), fetchDrivers()])
        })()

    }, [params.id])

    // useEffect(()=>{
    //     (async () => {
    //         if(formik.values.type?.id) {
    //             await fetchObjects()
    //         }
    //     })()
    // },[formik.values.type?.id])

    const showOnMap = async () => {
        if (await navigateSubmitHandler())
            navigate(`/dashboard/vehicles/map/${params.id}`)
    }

    const connectMonitoring = async () => {
        if (await navigateSubmitHandler())
            navigate(`../monitoring/${params.id}`)
    }

    // Установка значения по умолчанию типа регистрационного номера
    useEffect(() => {
        if(formik.values.type_registration) return
        const typeDoc = formik.values.type_doc
        if (typeDoc?.default)
            formik.setFieldValue('type_registration', typeDoc.default)
        else
            formik.setFieldValue('type_registration', null)
    }, [formik.values.type_doc])

    useEffect(() => {
        if (!formik.values.top_eq) {
            formik.setFieldValue('engine_top_eq', false)
        }
    }, [formik.values.top_eq])

    // Функция для отправки формы перед переходом в модальное окно карты или мониторинга
    const navigateSubmitHandler = async () => {
        setScroll(prev => prev + 1)

        const errors = await formik.validateForm(formik.values)

        if (Object.keys(errors).length) return

        const data = {
            ...formik.values,
            type: formik.values.type?.id || '',
            brand: formik.values.brand?.id || '',
            subject: formik.values.subject?.id || '',
            driver: formik.values.driver?.id || '',
            type_doc: formik.values.type_doc?.id || '',
            type_doc_admiss: formik.values.type_doc_admiss?.id || '',
            type_registration: formik.values.type_registration?.id || '',
            type_admiss: formik.values.type_admiss.map(type => type.types).flat(1).map(type => type.id),
            date_doc: timeToISO(formik.values.date_doc) || '',
            deadline_doc: timeToISO(formik.values.deadline_doc) || '',
            date_admiss: timeToISO(formik.values.date_admiss) || ''
        }

        if (pageType === 'edit') {
            try {
                const response = await dispatch(editVehicle(formik.values.id, data))

                if (response.error) {
                    setInfoModal(configInfoModal('Ошибка', getErrorMessageFromResponse(response), errorClose, true, true))
                    return false
                } else {
                    setInfoModal(configInfoModal('Успех', 'ТС изменено', errorClose, true, false))
                    return true
                }
            } catch (e) {
                setInfoModal(configInfoModal('Ошибка', getErrorMessageFromResponse(e), errorClose, true, true))
                return false
            }
        } else {
            try {
                const response = await dispatch(createVehicle(data))

                if (response.error) {
                    setInfoModal(configInfoModal('Ошибка', getErrorMessageFromResponse(response), errorClose, true, true))
                    return false
                } else {
                    setInfoModal(configInfoModal('Успех', 'ТС добавлено', errorClose, true, false))
                    return true
                }
            } catch (e) {
                setInfoModal(configInfoModal('Ошибка', getErrorMessageFromResponse(e), errorClose, true, true))
                return false
            }
        }
    }

    return (
        <>
            <Modal close={close} style={style} title={title} scroll={scroll} isLoading={isLoading} isShowing>
                <div className={classes.header}>
                    <div className={classes.header_id}>
                        <p>ID</p>
                        <p>{formik.values.id}</p>
                    </div>
                </div>
                <div className='form'>
                    {formik.errors.message && <div className='form_error_message'>
                        {formik.errors.message}
                    </div>}
                    <div className={classes.category}>
                        <p>Базовые параметры</p>
                    </div>
                    <div className='form_columns'>
                        <div className='form_columns_column'>
                            {/*type*/}
                            <DropdownInput
                                id='type'
                                name='type'
                                value={formik.values.type}
                                onChange={type => formik.setFieldValue('type', type)}
                                error={formik.errors.type}
                                disabled={isWatch}
                                label='Тип ТС'
                                isRequired
                                items={[
                                    {id: '-1', value: '', name: 'Не выбрано'}, ...types.data.map(type => ({
                                        id: type.id,
                                        name: type.name,
                                        value: type
                                    }))
                                ]}
                                loading={types.loading}
                                displayText={formik.values.type?.name || 'Не выбрано'}
                                onScrollBottom={fetchTypes}
                            />
                            {/*brand*/}
                            <DropdownInput
                                id='brand'
                                name='brand'
                                value={formik.values.brand}
                                onChange={brand => formik.setFieldValue('brand', brand)}
                                error={formik.errors.brand}
                                disabled={isWatch}
                                label='Марка ТС'
                                isRequired
                                items={[
                                    {id: '-1', value: '', name: 'Не выбрано'}, ...brands.data.map(brand => ({
                                        id: brand.id,
                                        name: brand.name,
                                        value: brand
                                    }))
                                ]}
                                loading={brands.loading}
                                displayText={formik.values.brand?.name || 'Не выбрано'}
                                onScrollBottom={fetchBrands}
                            />
                            {/*model*/}
                            <TextInput id='model'
                                       name='model'
                                       value={formik.values.model}
                                       onChange={formik.handleChange}
                                       error={formik.errors.model}
                                       disabled={isWatch}
                                       label='Модель ТС'
                                       isRequired
                                       placeholder='Модель ТС'
                            />
                        </div>
                        <div className='form_columns_column'>
                            {/*release_date*/}
                            <TextInput id='release_date'
                                       name='release_date'
                                       value={formik.values.release_date}
                                       onChange={formik.handleChange}
                                       error={formik.errors.release_date}
                                       disabled={isWatch}
                                       label='Год выпуска'
                                       isRequired
                                       placeholder='____'
                                       mask='9999'
                                       inputStyle={{letterSpacing: '4px'}}
                            />
                            {/*description*/}
                            <TextareaInput id='description'
                                           name='description'
                                           value={formik.values.description}
                                           onChange={formik.handleChange}
                                           error={formik.errors.description}
                                           disabled={isWatch}
                                           label='Описание'
                                           placeholder='Описание'
                                           inputStyle={{padding: '5px 14px', height: '105px'}}
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className={classes.category}>
                        <p>Документы</p>
                    </div>
                    <div className='form_columns'>
                        <div className='form_columns_column'>
                            {/*type_doc*/}
                            <DropdownInput
                                id='type_doc'
                                name='type_doc'
                                value={formik.values.type_doc}
                                outerStyle={{marginTop: '19px'}}
                                onChange={document => {
                                    formik.setFieldValue('number_doc', '')
                                    formik.setFieldValue('type_doc', document)
                                }}
                                error={formik.errors.type_doc}
                                disabled={isWatch}
                                label='Тип регистрационного документа'
                                items={[
                                    {id: '-1', value: null, name: 'Отсутствует'},
                                    ...typeDocument.data.map(type => ({
                                        id: type.id,
                                        name: type.name,
                                        value: type
                                    }))
                                ]}
                                displayText={formik.values.type_doc?.name}
                                loading={typeDocument.loading}
                                onScrollBottom={fetchTypeDocument}
                            />
                            {/*number_doc*/}
                            {formik.values.type_doc &&
                                <TextInput
                                    outerStyle={{marginTop: '19px'}}
                                    id='number_doc'
                                    name='number_doc'
                                    value={formik.values.number_doc}
                                    onChange={formik.values.type_doc?.mask ? maskInputHandler : formik.handleChange}
                                    error={formik.errors.number_doc}
                                    formatChars={formik.values.type_doc?.formatChars || {}}
                                    disabled={isWatch}
                                    label='Номер документа'
                                    placeholder={formik.values.type_doc?.placeholder || 'Номер документа'}
                                    mask={formik.values.type_doc?.mask || ''}
                                />}
                            {/*date_doc*/}
                            {formik.values.type_doc &&
                                <DateInput
                                    id='date_doc'
                                    name='date_doc'
                                    outerStyles={{marginTop: '20px'}}
                                    value={formik.values.date_doc}
                                    onChange={(date) => formik.setFieldValue('date_doc', date)}
                                    error={formik.errors.date_doc}
                                    label='Дата выдачи документа'
                                    disabled={!formik.values.type_doc || isWatch}
                                />}
                            {/*deadline_doc*/}
                            {formik.values.type_doc === '5' &&
                                <DateInput
                                    id='deadline_doc'
                                    name='deadline_doc'
                                    outerStyles={{marginTop: '20px'}}
                                    value={formik.values.deadline_doc}
                                    onChange={(date) => formik.setFieldValue('deadline_doc', date)}
                                    error={formik.errors.deadline_doc}
                                    label='Срок действия документа'
                                    disabled={!formik.values.type_doc || isWatch}
                                />}
                            {/*type_registration*/}
                            <DropdownInput
                                id='type_registration'
                                name='type_registration'
                                value={formik.values.type_registration}
                                onChange={typeRegistration => {
                                    formik.setFieldValue('registration_number', '')
                                    formik.setFieldValue('type_registration', typeRegistration)
                                }}
                                error={formik.errors.type_registration}
                                outerStyle={{marginTop: '20px'}}
                                isRequired
                                disabled={isWatch}
                                label='Тип регистрационного номера'
                                items={[
                                    {id: '-1', value: null, name: 'Не выбрано'},
                                    ...typeRegister.data.map(type => ({
                                        id: type.id,
                                        name: type.name,
                                        value: type
                                    }))
                                ]}
                                loading={typeRegister.loading}
                                onScrollBottom={fetchTypeRegister}
                                displayText={formik.values.type_registration?.name}
                            />
                            {/*registration number*/}
                            <TextInput
                                id='registration_number'
                                name='registration_number'
                                value={formik.values.registration_number}
                                onChange={formik.values.type_registration?.mask ? maskInputHandler : formik.handleChange}
                                error={formik.errors.registration_number}
                                outerStyle={{marginTop: '20px'}}
                                isRequired
                                formatChars={formik.values.type_registration?.formatChars || {}}
                                disabled={isWatch}
                                label='Регистрационный номер'
                                placeholder={formik.values.type_registration?.placeholder || 'Регистрационный номер'}
                                mask={formik.values.type_registration?.mask || ''}
                            />
                        </div>
                        <div className='form_columns_column'>
                            {/*type_doc_admiss*/}
                            {formik.values.type_doc &&
                                <DropdownInput
                                    id='type_doc_admiss'
                                    name='type_doc_admiss'
                                    value={formik.values.type_doc_admiss}
                                    onChange={typeDocAdmiss => {
                                        formik.setFieldValue('admiss', '')
                                        formik.setFieldValue('type_doc_admiss', typeDocAdmiss)
                                    }}
                                    error={formik.errors.type_doc_admiss}
                                    disabled={isWatch}
                                    labelStyle={{whiteSpace: 'normal'}}
                                    label='Тип документа подтверждающего регистрацию'
                                    items={[
                                        {id: '-1', value: null, name: 'Отсутствует'},
                                        ...typeDocAdmiss.data.map(type => ({
                                            id: type.id,
                                            name: type.name,
                                            value: type
                                        }))
                                    ]}
                                    loading={typeDocAdmiss.loading}
                                    onScrollBottom={fetchTypeDocAdmiss}
                                    displayText={formik.values.type_doc_admiss?.name}
                                />}
                            {/*admiss*/}
                            {formik.values.type_doc_admiss &&
                                <TextInput
                                    id='admiss'
                                    name='admiss'
                                    value={formik.values.admiss}
                                    onChange={formik.values.type_doc_admiss?.mask ? maskInputHandler : formik.handleChange}
                                    error={formik.errors.admiss}
                                    formatChars={formik.values.type_doc_admiss?.formatChars || {}}
                                    disabled={isWatch}
                                    label='Номер документа подтверждающего регистрацию'
                                    labelStyle={{whiteSpace: 'normal'}}
                                    placeholder={formik.values.type_doc_admiss?.placeholder || 'Документ'}
                                    mask={formik.values.type_doc_admiss?.mask || ''}
                                />}
                            {/*date_admiss*/}
                            {formik.values.type_doc_admiss &&
                                <DateInput
                                    id='date_admiss'
                                    name='date_admiss'
                                    value={formik.values.date_admiss}
                                    onChange={(date) => formik.setFieldValue('date_admiss', date)}
                                    error={formik.errors.date_admiss}
                                    label='Дата выдачи документа подтверждающего регистрацию'
                                    labelStyles={{whiteSpace: 'normal'}}
                                    disabled={!formik.values.type_doc_admiss || isWatch}
                                />}
                        </div>
                    </div>
                    <hr/>
                    <div className={classes.category}>
                        <p>Базовые технические параметры</p>
                    </div>
                    <div className='form_columns'>
                        <div className='form_columns_column'>
                            {/*type_engine*/}
                            <DropdownInput
                                id='type_engine'
                                name='type_engine'
                                value={formik.values.type_engine}
                                onChange={value => formik.setFieldValue('type_engine', value)}
                                error={formik.errors.type_engine}
                                disabled={isWatch}
                                labelStyle={{whiteSpace: 'normal'}}
                                isRequired
                                label='Тип топлива'
                                items={[
                                    {
                                        id: '-1',
                                        value: '',
                                        name: 'Не выбрано'
                                    }, ...staticData.vehicles.type_engine.map(type => ({
                                        id: type.id,
                                        name: type.name,
                                        value: type.name
                                    }))
                                ]}/>
                            {/*top_eq*/}
                            <CheckboxInput
                                id='top_eq'
                                name='top_eq'
                                checked={formik.values.top_eq}
                                error={formik.errors.top_eq}
                                onChange={formik.handleChange}
                                disabled={isWatch}
                                title='Наличие верхнего оборудования'
                            />
                        </div>
                        <div className='form_columns_column'>
                            {/*engine_top_eq*/}
                            {formik.values.top_eq &&
                                <CheckboxInput
                                    id='engine_top_eq'
                                    name='engine_top_eq'
                                    checked={formik.values.engine_top_eq}
                                    onChange={formik.handleChange}
                                    error={formik.errors.engine_top_eq}
                                    disabled={isWatch}
                                    title='Отдельный двигатель верхнего оборудования'
                                    titleStyle={{whiteSpace: 'normal'}}/>}
                        </div>
                    </div>
                    <hr/>
                    <div className={classes.category}>
                        <p>Связи</p>
                    </div>
                    <div className='form_columns'>
                        <div className='form_columns_column'>
                            {/*type_admiss*/}
                            <TreeListInput
                                id='type_admiss'
                                setItems={values => formik.setFieldValue('type_admiss', values.map(value => ({
                                    id: value.id,
                                    name: value.name,
                                    types: value.items
                                })))}
                                allItems={typeAdmiss.map(type => ({
                                    id: type.id,
                                    name: type.name,
                                    items: type.types
                                }))}
                                selectedLists={formik.values.type_admiss.map(type => ({
                                    id: type.id,
                                    name: type.name,
                                    items: type.types
                                }))}
                                error={formik.errors.type_admiss}
                                buttonText='Выбрать допуски'
                                modalTitle='Выбор допусков'
                                disabled={isWatch}
                                label='Требуемые допуски для управления машиной'
                                isRequired
                            />
                        </div>
                        <div className='form_columns_column'>
                            {/*driver*/}
                            <div className='form_control'>
                                <div className='form_control_label'>
                                    <label htmlFor="driver">Привязка к водителю</label>
                                </div>
                                {formik.values.type_admiss?.length ?
                                    <p style={{fontSize: '12px', color: '#796c6c'}}>В списке отражены водители,
                                        подходящие
                                        под
                                        условия допуска для управления транспортом</p> : null}
                                <div
                                    className={clsx('form_control_input', formik.errors.subject && 'form_control_input_error')}>
                                    <Dropdown
                                        id='driver'
                                        name='driver'
                                        value={formik.values.driver}
                                        displayText={formik.values.driver?.name || 'Не выбрано'}
                                        onScrollBottom={fetchDrivers}
                                        loading={drivers.loading}
                                        onChange={driver => formik.setFieldValue('driver', driver)}
                                        error={formik.errors.driver}
                                        disabled={isWatch}
                                    >
                                        <MenuItem value={null}>Не выбрано</MenuItem>
                                        {drivers.data.map(driver => (
                                            <MenuItem key={driver.id} value={driver}>
                                                {driver.name}
                                            </MenuItem>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div>
                            {/*subject*/}
                            <DropdownInput
                                id='subject'
                                name='subject'
                                value={formik.values.subject}
                                onChange={subject => formik.setFieldValue('subject', subject)}
                                error={formik.errors.subject}
                                disabled={isWatch}
                                labelStyle={{whiteSpace: 'normal'}}
                                label='Привязка к объекту'
                                items={[
                                    {
                                        id: '-1',
                                        value: null,
                                        name: 'Не выбрано'
                                    }, ...objects.data.map(object => ({
                                        id: object.id,
                                        value: object,
                                        name: object.name
                                    }))
                                ]}
                                displayText={formik.values.subject?.name || 'Не выбрано'}
                                loading={objects.loading}
                                onScrollBottom={fetchObjects}
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className={classes.monitoring}>
                        <h1>Мониторинг</h1>
                        {formik.values.connect ? (
                            <div className={classes.monitoring_buttons}>
                                <Button disabled={isWatch} onClick={connectMonitoring} text='Подключено'/>
                                <Button disabled={isWatch} onClick={showOnMap} text='Показать на карте'/>
                            </div>
                        ) : (
                            <div className={classes.monitoring_buttons}>
                                <Button disabled={isWatch} onClick={connectMonitoring} text='Подключить'/>
                            </div>
                        )}
                    </div>
                </div>
                <div></div>
                {!isWatch && <SubmitMenu close={close} saveHandler={formik.submitForm}/>}
            </Modal>
            {infoModal.isShowing &&
                <SuccessModal isError={infoModal.isError} close={infoModal.close} isShowing={infoModal.isShowing}
                              title={infoModal.title} description={infoModal.description}/>}
        </>
    )
}

export default VehicleModal
