import * as yup from 'yup'
import masks from "../utils/masks";

const userSchema = yup.object().shape({
    last_name: yup.string().required('Заполните обязательные поля').default(''),
    first_name: yup.string().required('Заполните обязательные поля').default(''),
    middle_name: yup.string().default('').nullable(true),
    phone: yup.string().required('Заполните обязательные поля').default('').matches(masks.phone, 'Неверный номер телефона'),
    email: yup.string().nullable(true).default('').test('checkEmail', 'Некорректный email', email => !email || masks.email.test(email)),
    account: yup.string().required('Заполните обязательные поля').default('Пользователь'),
    password: yup.string()
        .default('')
        .test('checkPasLength', 'Слишком короткий пароль', password => !password || password.trim().length >= 8)
        .when('status', {
            is: status => status,
            then: schema => schema,
            otherwise: schema => schema.required('Заполните обязательные поля')
        }),
    status: yup.string().default(''),
})

export default userSchema