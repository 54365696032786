import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {getErrorMessageFromResponse} from "../../utils/errors";
import classes from "./Profile.module.sass";
import staticData from "../../utils/staticData";
import {SuccessModal} from "../../components/Modal/Modal";
import useAuth from "../../hooks/useAuth";
import {getAPI} from "../../api/api";
import {configurationSchema} from "../../schemas/profileSchema";
import {getMostFrequentlyEncounteredError} from "../../schemas";
import DropdownInput from "../../components/FormControls/DropdownInput";
import Button from "../../components/UI/Button";

export const Configuration = () => {

    const {user, setRender} = useAuth()

    const [wasChanged, setChanged] = useState(false)

    const [successModal, setSuccessModal] = useState('')
    const [errorModal, setErrorModal] = useState('')

    const onChange = value => {
        setChanged(true)
        formik.setFieldValue('rate', value)
    }

    const formik = useFormik({
        initialValues: configurationSchema.cast({}),
        validationSchema: configurationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        onSubmit: async values => {
            const response = await getAPI.editCurrentUser({
                rate: values.rate
            })

            if (response?.error) {
                setErrorModal(getErrorMessageFromResponse(response))
            } else {
                setSuccessModal('Данные сохранены')
                setRender()
            }
        }
    })

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    useEffect(() => {
        if (!user) return
        formik.setValues(configurationSchema.cast(user))
    }, [user])

    return (
        <>
            <div className={classes.content}>
                <h1>Изменение настроек системы</h1>
                <div className={classes.content_form}>
                    <div className='form'>
                        <div className='form_columns'>
                            <div className='form_columns_column'>
                                <DropdownInput
                                    id='rate'
                                    name='rate'
                                    onChange={onChange}
                                    items={staticData.user.rates.map(rate => ({
                                        id: rate,
                                        name: rate,
                                        value: rate
                                    }))} error={formik.errors.rate} value={formik.values.rate}
                                    label='Тарифный план'
                                    isRequired
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.submit}>
                    <Button id='save_button' onClick={formik.submitForm} disabled={!wasChanged} text='Сохранить'/>
                </div>
            </div>
            <SuccessModal close={() => setSuccessModal('')} description={successModal}
                          title='Успех' isShowing={successModal}/>
            <SuccessModal close={() => setErrorModal('')} description={errorModal} title='Ошибка'
                          isError isShowing={errorModal}/>
        </>
    )
}
