import React, {useRef, useState} from "react";
import classes from './Calendar.module.sass'
import {areEqual, getMonthData} from "./utils";
import clsx from "clsx";
import ReactDOM from "react-dom";
import Button from "../Button";

const calendarData = {
    years: new Array(2099 - 1900 + 1).fill(0).map((_, i) => 1900 + i),
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekDayNames: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
}

const Calendar = ({
                      close,
                      value,
                      onChange,
                      coords
                  }) => {

    const {years, monthNames, weekDayNames} = calendarData

    const [date, setDate] = useState(value || new Date())
    const [currentDate, setCurrentDate] = useState(new Date())
    const [selectedDate, setSelectedDate] = useState(value || null)

    const monthSelect = useRef(null)
    const yearSelect = useRef(null)

    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    const monthData = getMonthData(year, month);

    const handlePrevMonthButtonClick = () => {
        const date = new Date(year, month - 1);

        setDate(date)
    };

    const handleNextMonthButtonClick = () => {
        const date = new Date(year, month + 1);

        setDate(date)
    };

    const handleDayClick = date => {
        setSelectedDate(date)

        onChange(date)
        close()
    }

    const handleSelectChange = () => {
        const year = yearSelect.current.value;
        const month = monthSelect.current.value;

        const date = new Date(year, month);

        setDate(date)
    }

    return (
        ReactDOM.createPortal(<>
            <div className={classes.backdrop} onClick={close}/>
            <div className={classes.calendar} style={{
                top: `${coords?.top}px`,
                left: `${coords?.left}px`
            }}>
                <header>
                    <Button text={'<'} onClick={handlePrevMonthButtonClick}/>
                    <select
                        ref={monthSelect}
                        value={month}
                        onChange={handleSelectChange}
                    >
                        {monthNames.map((name, index) =>
                            <option key={name} value={index}>{name}</option>
                        )}
                    </select>
                    <select
                        ref={yearSelect}
                        value={year}
                        onChange={handleSelectChange}
                    >
                        {years.map(year =>
                            <option value={year} key={year}>{year}</option>
                        )}
                    </select>
                    <Button text={'>'} onClick={handleNextMonthButtonClick}/>
                </header>

                <table>
                    <thead>
                    <tr>
                        {weekDayNames.map(name =>
                            <th key={name}>{name}</th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {monthData.map((week, index) =>
                        <tr key={index} className="week">
                            {week.map((date, index) => date ?
                                <td
                                    key={index}
                                    className={clsx(classes.day, areEqual(date, currentDate) && classes.today, areEqual(date, selectedDate) && classes.selected)}
                                    onClick={() => handleDayClick(date)}
                                >{date.getDate()}</td>
                                :
                                <td key={index}/>
                            )}
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </>, document.body)
    )
}

export default Calendar