import * as yup from "yup";
import masks from "../utils/masks";
import {getTimeDateObject} from "../utils/timeConverters";

export const districtSchema = yup.object().shape({
    id: yup.string().required(),
    name: yup.string().required(),
    items: yup.array().of(yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required()
    }))
})

export const loginSchema = yup.object().shape({
    phone: yup.string().required('Заполните обязательные поля').matches(masks.phone, 'Неверный номер телефона').default(''),
    password: yup.string().required('Заполните обязательные поля').default('')
})

export const personalRegistrationSchema = yup.object().shape({
    phone: yup.string().required('Заполните обязательные поля').matches(masks.phone, 'Неверный номер телефона').default(''),
    password: yup.string()
        .required('Заполните обязательные поля')
        .default('')
        .test('checkPasLength', 'Слишком короткий пароль', password => !password || password.trim().length >= 8)
        .oneOf([yup.ref('repeatPassword'), null], 'Пароли не совпадают'),
    repeatPassword: yup.string()
        .required('Заполните обязательные поля')
        .default('')
        .oneOf([yup.ref('password'), null], 'Пароли не совпадают'),
    email: yup.string().nullable(true).default('').test('checkEmail', 'Некорректный email', email => !email || masks.email.test(email)),
    first_name: yup.string().required('Заполните обязательные поля').default(''),
    last_name: yup.string().required('Заполните обязательные поля').default(''),
    middle_name: yup.string().nullable(true).default(''),
    tin: yup.string().required('Заполните обязательные поля').test('checkTinLength', 'Некорректный ИНН', tin => !tin || tin.length === 10 || tin.length === 12),
    rules: yup.boolean().oneOf([true], 'Необходимо согласиться с правилами системы').default(false)
})

export const legalEntitySchema = yup.object().shape({
    fullname_ru: yup.string().trim('Заполните обязательные поля').required('Заполните обязательные поля').default(''),
    shortname_ru: yup.string().required('Заполните обязательные поля').trim('Заполните обязательные поля').default(''),
    organizational_form: yup.string().required('Заполните обязательные поля').default('').nullable(true).trim('Заполните обязательные поля'),
    vat_payer: yup.boolean().default(true),
    vat_reason: yup.string().default('Не указано').nullable(true),
    tin: yup.string().required('Заполните обязательные поля').test('checkTinLength', 'Некорректный ИНН', tin => !tin || tin.length === 10 || tin.length === 12),
    checkpoint: yup.string().required('Заполните обязательные поля').test('checkCheckpointLength', 'Некорректный КПП', value => !value || value.length === 9).default(''),
    OGRN: yup.string().required('Заполните обязательные поля').default('').test('checkOGRNlength', 'Некорректный ОГРН', ogrn => !ogrn || ogrn.length >= 13),
    legal_address: yup.string().required('Заполните обязательные поля').default('').trim('Заполните обязательные поля'),
    mail_address: yup.string().required('Заполните обязательные поля').default('').trim('Заполните обязательные поля'),
    payment_account: yup.string().default('').nullable(true).test('checkPayAcc', 'Некорректный расчетный счет', value => !value || value.length === 20),
    correspondent_account: yup.string().default('').nullable(true).test('checkCorAcc', 'Некорректный корреспондентский счет', value => !value || value.length === 20),
    proxy: yup.string().default('Устав').nullable(true),
    proxy_number: yup.string()
        .default('')
        .when('proxy', {
            is: proxy => proxy === 'Доверенность',
            then: schema => schema.required('Заполните обязательные поля').trim('Заполните обязательные поля'),
            otherwise: schema => schema
        }),
    proxy_date: yup.string()
        .default('')
        .when('proxy', {
            is: proxy => proxy === 'Доверенность',
            then: schema => schema
                .required('Заполните обязательные поля')
                .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date))
                .test('maxDate', 'Дата выдачи не может быть позже текущей', date => !masks.date.test(date) || new Date() - getTimeDateObject(date) > 0)
                .default(''),
            otherwise: schema => schema
        }),
    BIC: yup.string().nullable(true).test('checkBIClength', 'Некорректный БИК', BIC => !BIC || BIC.length === 9),
    director: yup.string().required('Заполните обязательные поля').default('').trim('Заполните обязательные поля'),
    phone: yup.string().test('checkPhone', 'Неверный номер телефона', phone => !phone || masks.phone.test(phone)).default('').nullable(true),
    email: yup.string().nullable(true).default('').test('checkEmail', 'Некорректный email', email => !email || masks.email.test(email)),
    districts: yup.array().of(districtSchema).default([]).min(1, 'Заполните обязательные поля')
})

export const individualEntitySchema = yup.object().shape({
    shortname_ru: yup.string().required('Заполните обязательные поля').default('').trim('Заполните обязательные поля').nullable(true),
    vat_payer: yup.boolean().default(true),
    vat_reason: yup.string().default('Не указано').nullable(true),
    tin: yup.string().required('Заполните обязательные поля').test('checkTinLength', 'Некорректный ИНН', tin => !tin || tin.length === 10 || tin.length === 12),
    OGRN: yup.string().required('Заполните обязательные поля').default('').test('checkOGRNlength', 'Некорректный ОГРН', ogrn => !ogrn || ogrn.length >= 13).nullable(true),
    register_address: yup.string().required('Заполните обязательные поля').default('').trim('Заполните обязательные поля').nullable(true),
    payment_account: yup.string().default('').nullable(true).test('checkPayAcc', 'Некорректный расчетный счет', value => !value || value.length === 20),
    correspondent_account: yup.string().default('').nullable(true).test('checkCorAcc', 'Некорректный корреспондентский счет', value => !value || value.length === 20),
    BIC: yup.string().nullable(true).test('checkBIClength', 'Некорректный БИК', BIC => !BIC || BIC.length === 9),
    phone: yup.string().test('checkPhone', 'Неверный номер телефона', phone => !phone || masks.phone.test(phone)).default('').nullable(true),
    email: yup.string().nullable(true).default('').test('checkEmail', 'Некорректный email', email => !email || masks.email.test(email)),
    districts: yup.array().of(districtSchema).default([]).min(1, 'Заполните обязательные поля').nullable(true)
})