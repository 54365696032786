import Axios from "axios";
import Cookies from "js-cookie";

const getAllItems = async (axiosPromise, resolve, resolveError = response => {
}) => {
    const getOtherItems = async next => {
        try{
            const response = await Axios.get(next, {headers: {Authorization: `Bearer ${Cookies.get('access')}`}})

            if (response.status === 200) {
                if (response.data.next) {
                    return [...response.data.results, ...await getOtherItems(response.data.next)]
                } else {
                    return [...response.data.results]
                }
            } else throw new Error()
        } catch (e) {
            resolveError({error: e.response?.data})
        }
    }

    const response = await axiosPromise

    if (response.error) {
        resolveError(response)
    } else {
        const items = [...response.results]
        if (response.next)
            await resolve([...items, ...await getOtherItems(response.next)])
        else
            resolve(items)
    }
}

export default getAllItems
