import moment from 'moment'

export const timeToISO = (date, time) => {
    try{
        if(!date || !date.trim()) return ''
        if(time){
            const result = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.sssZ')
            if(result === 'Invalid date') return ''
            return result
        }

        else {
            const result = moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
            if(result === 'Invalid date') return ''
            return result
        }
    } catch (e) {
        return 'error'
    }
}

export const timeFromISO = isoDate => {
    if(!isoDate || !isoDate.trim()){
        return {date:'', time:''}
    }
    try {
        const [date, time] = moment(isoDate).format('DD.MM.YYYY HH:mm').split(' ')
        return {date, time}
    } catch (e) {
        return {date: '', time: ''}
    }
}

export const getTimeDateObject = date => {
    if(!date || !date.trim()){
        return null
    }

    return new Date(moment(date, 'DD.MM.YYYY').toISOString())
}
