import {useFormik} from "formik";
import classes from './ObjectModal.module.sass'
import Button from "../../UI/Button";
import {ReactComponent as Plus} from "../../../assets/settings/add.svg";
import React, {useEffect, useState} from "react";
import {
    TariffModal,
    Modal,
    SubmitMenu,
    SuccessModal,
} from "../Modal";
import staticData from "../../../utils/staticData";
import {getAPI} from "../../../api/api";
import {useDispatch} from "react-redux";
import {createObject, editObject} from "../../../redux/actions/Objects";
import {getErrorMessageFromResponse} from "../../../utils/errors";
import {ReactComponent as Trash} from "../../../assets/trash.svg";
import TextInput from "../../FormControls/TextInput";
import ListInput from "../../FormControls/ListInput";
import {useParams} from "react-router";
import useAuth from "../../../hooks/useAuth";
import objectSchema from "../../../schemas/objectSchema";
import {getMostFrequentlyEncounteredError} from "../../../schemas";
import DropdownInput from "../../FormControls/DropdownInput";
import getAllItems from "../../../utils/getAllItems";
import useLoadableData from "../../../hooks/useLoadableData";
import useIsMobile from "../../../hooks/useIsMobile";

const ObjectModal = ({close}) => {

    const styles = {
        width: '700px'
    }

    const dispatch = useDispatch()
    const params = useParams()
    const isMobile = useIsMobile()
    const {user} = useAuth()

    const [title, setTitle] = useState(params.type === 'edit' ? 'Редактирование объекта' : 'Добавление объекта')
    const [scroll, setScroll] = useState(0)
    const [chosenExecutor, setChosenExecutor] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [isWatch, setIsWatch] = useState(false)

    // стейт для хранения списка исполнителей в виде {id, name}
    const [executors, fetchExecutors] = useLoadableData(
        getAPI.getCompanies,
        {add_subject: 'True'},
        error => setErrorModal(error),
        executors => executors.map(executor => ({
            id: executor.id,
            name: executor.full_name_company
        })))

    // стейт для хранения списка тарифов в виде {id, name}
    const [tariffs, fetchTariffs] = useLoadableData(
        getAPI.getTariffs,
        {},
        error => setErrorModal(error),
        tariffs => tariffs.map(tariff => ({
            id: tariff.id,
            name: `${tariff.name} ${tariff.price}р/${tariff.unit}`
        })))

    // стейт для хранения списка Тс в виде {id, name, type}
    const [vehicles, setVehicles] = useState({
        data: [],
        loading: true,
    })

    // стейт для хранения списка типов ТС в виде {id, name}
    const [types, setTypes] = useState({
        data: [],
        loading: true,
    })

    const [successModal, setSuccessModal] = useState('')
    const [errorModal, setErrorModal] = useState('')
    const [tariffModal, setTariffModal] = useState(false)

    const formik = useFormik({
        initialValues: objectSchema.cast({
            id: params.id,
            creator: user.id,
            executor: [{
                id: user.id,
                name: user.legal ? user.legal.fullname_ru : user.individual.shortname_ru
            }],
        }),
        validationSchema: objectSchema,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validate: () => setScroll(prev => prev + 1),
        onSubmit: async values => {
            const data = {
                id: values.id,
                name: values.name,
                time_zone: values.time_zone,
                format: values.format,
                types: values.types,
                executor: values.executor.map(executor => executor.id),
                tariff_vehicle: values.tariff_vehicle.map(t_v => ({tariff: t_v.tariff?.id, vehicle: t_v.vehicle.id}))
            }

            if (params.type === 'edit') {
                try {
                    const response = await dispatch(editObject(formik.values.id, data))

                    if (response.error) {
                        setErrorModal(getErrorMessageFromResponse(response))
                    } else {
                        setSuccessModal('Объект изменен')
                    }
                } catch (e) {
                    setErrorModal(getErrorMessageFromResponse(e))
                    return
                }
            } else {
                try {
                    const response = await dispatch(createObject(data))

                    if (response.error) {
                        setErrorModal(getErrorMessageFromResponse(response))
                    } else {
                        setSuccessModal('Объект создан')
                    }
                } catch (e) {
                    setErrorModal(getErrorMessageFromResponse(e))
                    return
                }
            }
        }
    })

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    // фильтрация загруженных ТС по тем, что совпадают с выбранными типами ТС
    useEffect(() => {
        formik.setFieldValue('tariff_vehicle', formik.values.tariff_vehicle.filter(t_v => formik.values.types.includes(t_v.vehicle.type)))
    }, [formik.values.types])

    // Проверка доступа. Если доступа нет, включается режим просмотра
    const fetchAccess = async () => {
        const response = await getAPI.editObjectAccess(params.id)

        if (response.error) {
            setIsWatch(true)
            setTitle('Просмотр объекта')
        } else {
            setIsWatch(false)
        }
    }

    // получение данных о текущем объекте
    const fetchObject = async () => {
        const object = await getAPI.getObjectById(params.id)

        if (object.error) return

        await formik.setValues(objectSchema.cast({
            ...object,
            types: object.types?.map(type => type.id),
            executor: object.executor?.map(executor => ({id: executor.id, name: executor.full_name_company})),
            tariff_vehicle: object.tariff_vehicle?.map(tariff_vehicle => {
                const {vehicle, tariff} = tariff_vehicle
                return {
                    tariff: tariff ? {
                        id: tariff.id,
                        name: `${tariff.name} ${tariff.price}/${tariff.unit}`
                    } : null,
                    vehicle: vehicle ? {
                        id: vehicle.id,
                        name: `${vehicle.brand?.name} ${vehicle.model} | ${vehicle.registration_number}`,
                        type: vehicle.type?.id
                    } : null
                }
            })
        }))

        await fetchAccess()
    }


    const fetchVehicles = async () => {
        if (!formik.values.types.length) {
            setVehicles(prev => ({...prev, data: []}))
        }

        setVehicles(prev => ({...prev, loading: true}))
        await getAllItems(getAPI.getVehicles({
            type__id: formik.values.types.join(','),
            executors: formik.values.executor.map(executor => executor.id).join(','),
        }), vehicles => setVehicles(prev => (
            {
                ...prev,
                data: vehicles.map(vehicle => ({
                    id: vehicle?.id,
                    name: `${vehicle?.brand?.name} ${vehicle?.model} | ${vehicle?.registration_number}`,
                    type: vehicle.type?.id
                }))
            }
        )))
        setVehicles(prev => ({...prev, loading: false}))
    }

    const fetchTypes = async () => {
        setTypes(prev => ({...prev, loading: true}))
        await getAllItems(getAPI.getVehicleTypes(), types => setTypes(prev => ({...prev, data: types})))
        setTypes(prev => ({...prev, loading: false}))
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            await fetchObject()
            setLoading(false)

            await Promise.all([fetchVehicles(), fetchTariffs(), fetchExecutors(), fetchTypes()])
        })()
    }, [params.id])

    useEffect(() => {
        fetchVehicles()
    }, [formik.values.types, formik.values.executor])

    const removeExecutor = (id) => {
        formik.setFieldValue('executor', formik.values.executor.filter(executor => executor.id !== id))
    }

    return (
        <>
            <Modal style={styles} close={close} title={title} scroll={scroll}
                   isLoading={isLoading || types.loading || vehicles.loading}
                   isShowing>
                <div className={classes.header}>
                    <div className={classes.header_id}>
                        <p>ID</p>
                        <p>{formik.values.id}</p>
                    </div>
                    <div className={classes.header_form}>
                        {/*time_zone*/}
                        <DropdownInput
                            id='time_zone'
                            name='time_zone'
                            onChange={value => formik.setFieldValue('time_zone', value)}
                            items={staticData.object.timezones.map(tz => ({
                                id: tz.id,
                                value: tz.name,
                                name: tz.name
                            }))}
                            value={formik.values.time_zone}
                            error={formik.errors.time_zone}
                            outerStyle={isMobile ? {width: '120px'} : {width: '165px'}}
                            label='Часовой пояс'
                            disabled={isWatch}
                        />
                        {/*format*/}
                        <DropdownInput
                            id='format'
                            name='format'
                            onChange={value => formik.setFieldValue('format', value)}
                            items={staticData.object.formats.map(format => ({
                                id: format,
                                value: format,
                                name: format
                            }))}
                            value={formik.values.format}
                            error={formik.errors.format}
                            outerStyle={isMobile ? {width: '105x'} : {width: '125px'}}
                            label='Формат объекта'
                            disabled={isWatch}
                        />
                    </div>
                </div>
                {formik.errors.message ? <div className='form_error_message'>
                    <p>{formik.errors.message}</p>
                </div> : null}
                {/*name*/}
                <TextInput onChange={formik.handleChange} name='name' id='name' value={formik.values.name}
                           disabled={isWatch} error={formik.errors.name} label='Название объекта' isRequired
                           placeholder='Промышленный объект №1234'/>
                <div className={classes.executor}>
                    {/*executor*/}
                    <DropdownInput
                        id='executor'
                        name='executor'
                        onChange={executor => setChosenExecutor(executor)}
                        items={executors.data.filter(executor => !formik.values.executor.some(existExecutor => existExecutor.id === executor.id)).length
                            ? [
                                {id: '-1', name: 'Не выбрано', value: null},
                                ...executors.data.filter(executor => !formik.values.executor.some(existExecutor => existExecutor.id === executor.id)).map(executor => ({
                                    id: executor.id,
                                    name: executor.name,
                                    value: executor
                                }))
                            ]
                            : []}
                        value={chosenExecutor}
                        error={formik.errors.executor}
                        outerStyle={{width: '80%'}}
                        label='Исполнитель'
                        disabled={isWatch}
                        emptyListMessage='Вы не сотрудничаете ни с одной компанией. Чтобы добавить исполнителя, отправьте приглашение к сотрудничеству в разделе контрагенты.'
                    />
                    <Button text='Добавить' fontSize={'14px'} icon={<Plus/>} style={{height: '30px'}}
                            disabled={!chosenExecutor || isWatch} onClick={() => {
                        formik.setFieldValue('executor', [...formik.values.executor, chosenExecutor]);
                        setChosenExecutor(null)
                    }}/>
                </div>
                <div className={classes.executors}>
                    <label className={classes.title}>Выбранные исполнители</label>
                    <div className={classes.executors_list}>
                        {formik.values.executor.map(executor => {
                            return (
                                <div className={classes.executors_list_item}>
                                    <p key={executor?.id}>{executor?.name}</p>
                                    {executor?.id !== formik.values.creator && !isWatch &&
                                        <div className={classes.executors_list_item_icon}
                                             onClick={() => removeExecutor(executor.id)}>
                                            <Trash/>
                                        </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='form'>
                    <div className='form_columns'>
                        <div className='form_columns_column'>
                            <ListInput allItems={types.data.map(type => ({id: type.id, name: type.name}))}
                                       buttonText='Выбор типов ТС' disabled={isWatch || types.loading}
                                       setItems={values => formik.setFieldValue('types', values.map(value => value.id))}
                                       selectedItems={formik.values.types.map(typeId => {
                                           const type = types.data.find(type => type.id === typeId)
                                           return {id: type?.id, name: type?.name}
                                       })} modalTitle='Выбор типов ТС' id='types'
                                       label='Используемые типы ТС' error={formik.errors.types} isRequired/>
                        </div>
                        <div className='form_columns_column'>
                            <ListInput allItems={vehicles.data}
                                       buttonText='Выбор ТС' disabled={isWatch || vehicles.loading}
                                       setItems={values => formik.setFieldValue('tariff_vehicle', values.map(value => {
                                           if (value.tariff) {
                                               return {
                                                   vehicle: {id: value.id, name: value.name, type: value.type},
                                                   tariff: value.tariff
                                               }
                                           } else {
                                               return {
                                                   vehicle: {id: value.id, name: value.name},
                                                   tariff: {id: 4, name: 'Нулевой 0.00р/км'}
                                               }
                                           }
                                       }))}
                                       selectedItems={formik.values.tariff_vehicle?.map(t_v => {
                                           const {tariff, vehicle} = t_v
                                           return {
                                               id: vehicle?.id,
                                               name: vehicle?.name,
                                               tariff,
                                               type: vehicle?.type
                                           }
                                       })} modalTitle='Выбор ТС' id='vehicle'
                                       label='Используемые ТС' error={formik.errors.tariff_vehicle}/>
                        </div>
                    </div>
                </div>
                <div className={classes.tariffs}>
                    <label className={classes.title}>Назначенные тарифы</label>
                    <div className={classes.tariffs_menu}>
                        <Button onClick={() => setTariffModal(true)} fontSize={'14px'} disabled={isWatch}
                                style={{width: '160px'}} text='Назначить тарифы'/>
                    </div>
                    <div className={classes.tariffs_table}>
                        <table style={formik.errors.tariffs && {
                            border: '1px solid #FF2421',
                            boxShadow: '0px 0px 9.46256px rgba(0, 0, 0, 0.1)',
                            background: '#FDF4F4'
                        }}>
                            <thead>
                            <tr>
                                <th>Транспортное средство</th>
                                <th>Тариф</th>
                            </tr>
                            </thead>
                            <tbody>
                            {formik.values.tariff_vehicle.map(t_v => {
                                const {tariff, vehicle} = t_v

                                return (
                                    <tr style={formik.errors.tariffs && {
                                        background: '#FDF4F4'
                                    }}
                                        key={vehicle?.name + '_' + tariff?.name + '_' + vehicle?.id + tariff?.id}>
                                        <td>{vehicle.name}</td>
                                        <td>{tariff && tariff.name}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {!isWatch && <SubmitMenu close={close} saveHandler={formik.submitForm}/>}
            </Modal>
            {successModal && <SuccessModal close={() => {
                setSuccessModal('');
                close()
            }} description={successModal} title='Успех' isShowing={successModal}/>}
            {errorModal && <SuccessModal close={() => setErrorModal('')} description={errorModal} title='Ошибка'
                                         isError isShowing={errorModal}/>}
            {tariffModal && <TariffModal tariffVehicles={formik.values.tariff_vehicle} isShowing={tariffModal}
                                         setTariffVehicles={tariffVehicle => formik.setFieldValue('tariff_vehicle', tariffVehicle)}
                                         close={() => setTariffModal(false)} tariffs={tariffs.data}/>}
        </>
    )
}

export default ObjectModal
