import * as yup from "yup";
import masks from "../utils/masks";
import {getTimeDateObject} from "../utils/timeConverters";
import moment from "moment/moment";

const bidSchema = yup.object().shape({
    id: yup.string().required(),
    executor: yup.object().shape({
        id: yup.string().required().default(''),
        full_name: yup.string().required().default(''),
        phone: yup.string().required().default('')
    }),
    status: yup.string().default('Новая'),
    subject: yup.object().shape({
        id: yup.string(),
        name: yup.string(),
        time_zone: yup.string()
    }).required('Заполните обязательные поля').default(null).nullable(true),
    type_vehicle: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).required('Заполните обязательные поля').default(null).nullable(true),
    vehicle: yup.object().shape({
        id: yup.string(),
        name: yup.string()
    }).default(null).nullable(true),
    description: yup.string().nullable(true),
    add_req_vehicle: yup.string().nullable(true),
    add_req_driver: yup.string().nullable(true),
    date_closed_date: yup.string()
        .required('Заполните обязательные поля')
        .test('correctDate', 'Неверная дата', date => !date || masks.date.test(date))
        .test('minDate', 'Дата окончания работ не может быть раньше текущей даты', date => masks.date.test(date) && new Date() - getTimeDateObject(date) <= 0)
        .default(moment().add(1, 'days').format('DD.MM.YYYY')),
    date_closed_time: yup.string()
        .test('checkTime', 'Неверное время', time => masks.time.test(time))
        .default('23:59'),
    date_finish: yup.string().default('')
})

export default bidSchema
