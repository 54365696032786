const staticData = {
    user: {
        account: [
            {
                id: 1,
                name: 'Администратор'
            },
            {
                id: 2,
                name: 'Пользователь'
            },
        ],
        rates: [
            'Демонстративный',
            'Контрактный'
        ],
        status: [
            {
                id: 0,
                name: 'Активен'
            },
            {
                id: 1,
                name: 'Удален',
            },
            {
                id: 2,
                name: 'Заблокирован',
            },
            {
                id: 3,
                name: 'Регистрация'
            }
        ]
    },
    vehicles: {
        belonging: [
            {
                id: 0,
                name: 'ТС Компании'
            },
            {
                id: 1,
                name: 'ТС Подрядчика'
            }
        ],
        type_engine: [
            {
                id: 0,
                name: 'Дизель'
            },
            {
                id: 1,
                name: 'Бензин'
            },
            {
                id: 2,
                name: 'Газ'
            },
            {
                id: 3,
                name: 'Бензин+газ'
            },
            {
                id: 4,
                name: 'Электричество'
            },
        ]
    },
    object: {
        timezones: [
            {id: 0, name: 'UTC-12', value: '-12:00'},
            {id: 1, name: 'UTC-11', value: '-11:00'},
            {id: 2, name: 'UTC-10', value: '-10:00'},
            {id: 3, name: 'UTC-9', value: '-09:00'},
            {id: 4, name: 'UTC-8', value: '-08:00'},
            {id: 5, name: 'UTC-7', value: '-07:00'},
            {id: 6, name: 'UTC-6', value: '-06:00'},
            {id: 7, name: 'UTC-5', value: '-05:00'},
            {id: 8, name: 'UTC-4', value: '-04:00'},
            {id: 9, name: 'UTC-3', value: '-03:00'},
            {id: 10, name: 'UTC-2', value: '-02:00'},
            {id: 11, name: 'UTC-1', value: '-01:00'},
            {id: 12, name: 'UTC+0', value: '+00:00'},
            {id: 13, name: 'UTC+1', value: '+01:00'},
            {id: 14, name: 'UTC+2', value: '+02:00'},
            {id: 15, name: 'UTC+3', value: '+03:00'},
            {id: 16, name: 'UTC+4', value: '+04:00'},
            {id: 17, name: 'UTC+5', value: '+05:00'},
            {id: 18, name: 'UTC+6', value: '+06:00'},
            {id: 19, name: 'UTC+7', value: '+07:00'},
            {id: 20, name: 'UTC+8', value: '+08:00'},
            {id: 21, name: 'UTC+9', value: '+09:00'},
            {id: 22, name: 'UTC+10', value: '+10:00'},
            {id: 23, name: 'UTC+11', value: '+11:00'},
            {id: 24, name: 'UTC+12', value: '+12:00'},
        ],
        formats: [
            'Закрытый',
            'Частный',
            'Публичный',
        ]
    },
    drivers: {
        belonging: [
            {
                id: 0,
                name: 'Водитель компании'
            },
            {
                id: 1,
                name: 'Водитель подрядчика'
            }
        ]
    },
    bids: {
        status: [
            {
                id: 1,
                name: 'Новые',
                value: 'Новая',
            },
            {
                id: 2,
                name: 'Назначенные',
                value: 'Назначенная'
            },
            {
                id: 3,
                name: 'В работе',
                value: 'В работе'
            },
            {
                id: 4,
                name: 'Завершенные',
                value: 'Завершенная'
            },
            {
                id: 5,
                name: 'Отклоненные',
                value: 'Отклоненная'
            }
        ]
    },
    contractors: {
        status: [
            {id: 0, name: 'Нет отношений'},
            {id: 1, name: 'Приглашение отправлено'},
            {id: 2, name: 'Получено приглашение'},
            {id: 3, name: 'Сотрудничество'},
        ]
    },
    company: {
        vat_reason: [
            {
                id: 0,
                name: 'Не указано'
            },
            {
                id: 1,
                name: 'Упрощенная система налогооблажения (УСН, Патент)'
            },
            {
                id: 2,
                name: 'Применяется налоговая льгота'
            }
        ]
    }
}

export default staticData
