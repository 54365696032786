import useQuery from "../../hooks/useQuery";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {getAPI} from "../../api/api";
import {getErrorMessageFromResponse} from "../../utils/errors";
import classes from "./Profile.module.sass";
import TextInput from "../../components/FormControls/TextInput";
import staticData from "../../utils/staticData";
import Button from "../../components/UI/Button";
import {
    EmailModal,
    NewPasswordModal,
    PasswordModal,
    PhoneModal,
    SuccessModal,
    VerificationModal
} from "../../components/Modal/Modal";
import useAuth from "../../hooks/useAuth";
import {personalSchema} from "../../schemas/profileSchema";
import {getMostFrequentlyEncounteredError} from "../../schemas";
import DropdownInput from "../../components/FormControls/DropdownInput";

export const Personal = () => {

    const {user, setRender} = useAuth()
    const query = useQuery()

    const [wasChanged, setChanged] = useState(false)

    const [newPasswordModal, setNewPasswordModal] = useState(false)
    const [passwordModal, setPasswordModal] = useState('')
    const [phoneModal, setPhoneModal] = useState(false)
    const [emailModal, setEmailModal] = useState(false)
    const [verificationModal, setVerificationModal] = useState(undefined)
    const [successModal, setSuccessModal] = useState('')
    const [errorModal, setErrorModal] = useState('')

    const onChange = event => {
        setChanged(true)
        formik.handleChange(event)
    }

    const formik = useFormik({
        initialValues: personalSchema.cast({}),
        validationSchema: personalSchema,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        onSubmit: async values => {
            const response = await getAPI.editCurrentUser({
                first_name: values.first_name,
                last_name: values.last_name,
                middle_name: values.middle_name,
                email: values.email,
                phone: values.phone,
                account: values.account
            })

            if (response?.error) {
                setErrorModal(getErrorMessageFromResponse(response))
            } else {
                setSuccessModal('Данные сохранены')
                setRender()
            }
        }
    })

    useEffect(() => {
        (async () => {
            if (query.get('token') && query.get('email')) {
                const response = await getAPI.changeEmail({
                    token: query.get('token'),
                    email: query.get('email')
                })
                if (response.error) {
                    setErrorModal(getErrorMessageFromResponse(response))
                } else {
                    setSuccessModal('Почта изменена')
                }
            }
        })()
    }, [])

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    useEffect(() => {
        if (!user) return
        formik.setValues(personalSchema.cast(user))
    }, [user])

    const enterPhoneHandler = async phone => {
        const response = await getAPI.sendPhoneCode({phone})

        if (response.error) {
            setErrorModal(getErrorMessageFromResponse(response))
        } else {
            setVerificationModal(phone)
            setPhoneModal(false)
        }
    }

    const changePasswordHandler = async new_password => {
        const response = await getAPI.changePassword({new_password})

        if (response.error) {
            setErrorModal(getErrorMessageFromResponse(response))
        } else {
            setSuccessModal('Пароль изменен')
            setNewPasswordModal(false)
        }
    }

    return (
        <>
            <div className={classes.content}>
                <h1>Редактирование данных</h1>
                <div className={classes.content_form}>
                    <div className='form'>
                        {formik.errors.message ? <div className='form_error_message'>
                            <p>{formik.errors.message}</p>
                        </div> : null}
                        <div className='form_columns'>
                            <div className='form_columns_column'>
                                {/*phone*/}
                                <TextInput onChange={formik.handleChange} name='phone' id='phone'
                                           value={formik.values.phone} error={formik.errors.phone} mask='+79999999999'
                                           placeholder='+79999999999' disabled label='Телефон' isRequired/>
                                {/*email*/}
                                <TextInput onChange={formik.handleChange} name='email' id='email'
                                           value={formik.values.email} error={formik.errors.email}
                                           mask='aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' maskChar=''
                                           formatChars={{'a': '[A-Za-z.@0-9\-_]'}}
                                           placeholder='mail@mail.ru' disabled label='Электронная почта' isRequired/>
                                {/*last_name*/}
                                <TextInput onChange={onChange} name='last_name' id='last_name' mask='aaaaaaaaaaaaaaa'
                                           formatChars={{'a': '[A-Za-zА-Яа-яёЁ]'}} maskChar=''
                                           value={formik.values.last_name} error={formik.errors.last_name}
                                           placeholder='Фамилия' label='Фамилия' isRequired/>
                                {/*first_name*/}
                                <TextInput onChange={onChange} name='first_name' id='first_name' mask='aaaaaaaaaaaaaaa'
                                           formatChars={{'a': '[A-Za-zА-Яа-яёЁ]'}} maskChar=''
                                           value={formik.values.first_name} error={formik.errors.first_name}
                                           placeholder='Имя' label='Имя' isRequired/>
                                {/*middle_name*/}
                                <TextInput onChange={onChange} name='middle_name' id='middle_name'
                                           mask='aaaaaaaaaaaaaaa' formatChars={{'a': '[A-Za-zА-Яа-яёЁ]'}} maskChar=''
                                           value={formik.values.middle_name} error={formik.errors.middle_name}
                                           placeholder='Отчество (при наличии)' label='Отчество'/>
                                {/*account*/}
                                <DropdownInput
                                    id='account'
                                    name='account'
                                    onChange={value => formik.setFieldValue('account', value)}
                                    items={staticData.user.account.map(account => ({
                                        id: account.id,
                                        value: account.name,
                                        name: account.name
                                    }))} error={formik.errors.account} value={formik.values.account}
                                    label='Роль'
                                    disabled
                                />
                            </div>
                            <div className='form_columns_column' style={{height: '155px'}}>
                                <div className='form_control'>
                                    <div className='form_control_label'>
                                        <label htmlFor="phone">&nbsp;</label>
                                    </div>
                                    <div className={'form_control_input'}>
                                        <Button onClick={() => setPasswordModal('phone')} text='Сменить телефон'/>
                                    </div>
                                </div>
                                <div className='form_control'>
                                    <div className='form_control_label'>
                                        <label htmlFor="email">&nbsp;</label>
                                    </div>
                                    <div className={'form_control_input'}>
                                        <Button onClick={() => setPasswordModal('email')} text='Сменить почту'/>
                                    </div>
                                </div>
                                <div className='form_control'>
                                    <div className='form_control_label'>
                                        <label htmlFor="password">&nbsp;</label>
                                    </div>
                                    <div className={'form_control_input'}>
                                        <Button onClick={() => setPasswordModal('password')}
                                                text='Сменить пароль'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.submit}>
                    <Button onClick={formik.submitForm} disabled={!wasChanged} text={'Сохранить'} id='save_button'/>
                </div>
            </div>

            {passwordModal && <PasswordModal close={() => setPasswordModal('')} isShowing={passwordModal}
                                             resolve={() => {
                                                 if (passwordModal === 'phone') setPhoneModal(true)
                                                 if (passwordModal === 'email') setEmailModal(true)
                                                 if (passwordModal === 'password') setNewPasswordModal(true)
                                             }}/>}

            {newPasswordModal && <NewPasswordModal
                close={() => setNewPasswordModal(false)}
                isShowing={newPasswordModal}
                resolve={changePasswordHandler}
            />}

            {phoneModal && <PhoneModal close={() => setPhoneModal(false)} resolve={enterPhoneHandler}
                                       isShowing={phoneModal}/>}

            {emailModal && <EmailModal close={() => setEmailModal(false)} isShowing={emailModal}/>}

            {verificationModal && <VerificationModal
                close={() => {
                    setVerificationModal(undefined)
                }}
                phone={verificationModal}
                passHandler={() => setVerificationModal(undefined)}
                submit={async (phone, code) => await getAPI.changePhone({
                    new_phone: phone,
                    code
                })}
                isShowing={verificationModal}
            />}

            <SuccessModal close={() => setSuccessModal('')} description={successModal}
                          title='Успех' isShowing={successModal}/>
            <SuccessModal close={() => setErrorModal('')} description={errorModal} title='Ошибка'
                          isError isShowing={errorModal}/>
        </>
    )
}
