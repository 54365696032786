import {
    OBJECTS_ADD_OBJECTS,
    OBJECTS_SET_LOADING, OBJECTS_SET_NEXT,
    OBJECTS_SET_OBJECTS,
    OBJECTS_SET_RENDER
} from "../types";

const Objects = (state = {
    objects: [],
    render: 0,
    loading: false,
    next: null
}, action) => {
    switch (action.type) {
        case OBJECTS_SET_OBJECTS:
            return {
                ...state,
                objects: action.payload.objects
            }
        case OBJECTS_SET_RENDER:
            return {
                ...state,
                render: state.render + 1
            }
        case OBJECTS_ADD_OBJECTS:
            return {
                ...state,
                objects: [...state.objects, ...action.payload.objects]
            }
        case OBJECTS_SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case OBJECTS_SET_NEXT:
            return {
                ...state,
                next: action.payload.next
            }
        default:
            return state
    }
}

export default Objects