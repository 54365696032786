import {useEffect, useState} from "react";


// Хук, вызывающий функцию onScrollBottom, каждый раз как список был пролистан до самого конца.
// В основном используется для подзагрузки данных
const useScrollBottom = (ref, onScrollBottom) => {

    const [isBottom, setBottom] = useState(false)

    useEffect(() => {
        if (!ref.current || !onScrollBottom) return

        const scrollListener = async event => {
            const scrollBottom = event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight

            if (!isBottom && scrollBottom) {
                setBottom(true)
                await onScrollBottom(event)
            }
            if(!scrollBottom){
                setBottom(false)
            }
        }

        ref.current?.addEventListener('scroll', scrollListener)

        return () => {
            ref.current?.removeEventListener('scroll', scrollListener)
        }

    }, [ref.current, onScrollBottom, isBottom])
}

export default useScrollBottom
