import {
    COMPANIES_ADD_COMPANIES,
    COMPANIES_SET_LOADING,
    COMPANIES_SET_NEXT,
    COMPANIES_SET_COMPANIES,
    COMPANIES_SET_RENDER
} from "../types";

const Companies = (state = {
    companies: [],
    render: 0,
    loading: false,
    next: null
}, action) => {
    switch (action.type) {
        case COMPANIES_SET_COMPANIES:
            return {
                ...state,
                companies: action.payload.companies
            }
        case COMPANIES_SET_RENDER:
            return {
                ...state,
                render: state.render + 1
            }
        case COMPANIES_ADD_COMPANIES:
            return {
                ...state,
                companies: [...state.companies, ...action.payload.companies]
            }
        case COMPANIES_SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            }
        case COMPANIES_SET_NEXT:
            return {
                ...state,
                next: action.payload.next
            }
        default:
            return state
    }
}

export default Companies