import React, {useState} from "react";
import {TreeSelectionModal} from "../../Modal/Modal";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "../../UI/Button";

const TreeListInput = ({
                           id,
                           label,
                           error,
                           isRequired,
                           buttonText,
                           selectedLists,
                           allItems,
                           setItems,
                           modalTitle,
                           disabled,
                           selectableList = false,
                           hideButton = false
                       }) => {

    const [treeListModal, setTreeListModal] = useState(false)

    return (<div className='form_control'>
        {treeListModal &&
            <TreeSelectionModal
                close={() => setTreeListModal(false)}
                selectedLists={selectedLists}
                selectableList={selectableList}
                isShowing={treeListModal}
                allLists={allItems}
                saveLists={setItems}
                title={modalTitle}
            />}
        <div className='form_control_menu'>
            {label && <div className='form_control_label'>
                <label style={{whiteSpace: 'normal'}} htmlFor={id}>{label} {isRequired && <span>*</span>}</label>
            </div>}
            {!hideButton && <Button
                id={`tree_list_button_${id}`}
                disabled={disabled}
                onClick={() => setTreeListModal(true)}
                text={buttonText || 'Выбрать'}
            />}
            <div className={clsx('form_control_menu_list', error && 'form_control_menu_list_error')} id={id}>
                {!selectedLists.length && <p style={{
                    width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>Список пуст</p>}
                {selectedLists.map(list => (<div>
                    <p key={list?.id} style={{fontFamily: 'PTRootUIMedium'}}>{list?.name}</p>
                    <div className='form_control_menu_list_innerlist'>
                        {list?.items?.map(item => (<p key={item.id}>{item.name}</p>))}
                    </div>
                </div>))}
            </div>
        </div>
    </div>)
}

TreeListInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    disabled: PropTypes.bool,
    setItems: PropTypes.func.isRequired,
    allItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, name: PropTypes.string,
        }))
    })).isRequired,
    selectedLists: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, name: PropTypes.string,
        }))
    })).isRequired,
    error: PropTypes.any,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    buttonText: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
}

export default TreeListInput
