import {useNavigate, useParams} from "react-router";
import React, {useEffect, useRef, useState} from "react";
import {SuccessModal} from "../../../components/Modal/Modal";
import classes from './VehicleMap.module.sass'
import {ReactComponent as Back} from "../../../assets/arrow.svg";
import LoadingScreen from "../../../components/LoadingScreen";
import Map, {vehiclePlaceMark, vehicleBalloonContentLayoutHTML} from "../../../components/Map";
import {Placemark} from "react-yandex-maps";
import {getAPI} from "../../../api/api";
import {getErrorMessageFromResponse} from "../../../utils/errors";

const VehicleMap = () => {
    const navigate = useNavigate()
    const params = useParams()

    const [data, setData] = useState({
        id: params.id,
        brand: {id: 0, name: ''},
        model: '',
        type: {id: 0, name: ''},
        registration_number: ''
    })
    const [ymaps, setYmaps] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [errorModal, setErrorModal] = useState('')

    const mapRef = useRef()

    const balloonContentLayout = React.useCallback(vehicle => {
        if(ymaps && mapRef){
            const {coordinates} = vehicle
            if (coordinates.latitude && coordinates.longitude)
                mapRef.current?.setCenter([coordinates.latitude, coordinates.longitude])
            const BalloonContentLayout = ymaps['templateLayoutFactory']['createClass'](vehicleBalloonContentLayoutHTML({
                creator: vehicle.creator,
                id: vehicle.id,
                type: vehicle.type.name,
                brand: vehicle.brand.name,
                status: vehicle.status,
                model: vehicle.model,
                registration_number: vehicle.registration_number
            }), {
                build: function () {
                    BalloonContentLayout.superclass.build.call(this)
                },
                clear: function () {
                    BalloonContentLayout.superclass.clear.call(this)
                }
            })

            return BalloonContentLayout
        }
    },[ymaps])

    const placeMarkContentLayout = React.useCallback(vehicle => {
        if (ymaps && mapRef) {
            const map = mapRef.current
            if (data.coordinates.latitude && data.coordinates.longitude)
                map?.setCenter([data.coordinates.latitude, data.coordinates.longitude])
            const {model, brand, registration_number} = vehicle
            return ymaps['templateLayoutFactory']['createClass'](vehiclePlaceMark({
                brand: brand.name,
                color: '#FF2223',
                model,
                registration_number
            }))
        }
    }, [ymaps, mapRef])

    const fetchData = async () => {
        const response = await getAPI.getVehicleMap(params.id)

        if (response.error) {
            setErrorModal(getErrorMessageFromResponse(response))
        } else {
            setData(response)
        }
    }

    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current
            map.setBounds(map.geoObjects.getBounds())
        }
    }, [mapRef, data])

    useEffect(() => {
        (async () => {
            setLoading(true)
            await fetchData()
            setLoading(false)
        })()
    }, [params.id])

    if (isLoading) return <LoadingScreen/>

    return (
        <>
            <div className={classes.container}>
                <div className={classes.container_header}>
                    <div className={classes.container_header_back} onClick={() => navigate(-1)}>
                        <Back/>
                    </div>
                    <h1>{data.brand?.name} {data.model} | {data.registration_number}</h1>
                </div>
                <div className={classes.container_map}>
                    <Map onLoad={setYmaps} modules={['templateLayoutFactory', 'geoObject.addon.balloon']} ref={mapRef}>
                        {
                            (data.coordinates?.latitude && data.coordinates?.longitude)
                                ? <Placemark
                                    key={data.id}
                                    geometry={[data.coordinates.latitude, data.coordinates.longitude]}
                                    options={{
                                        opacity: 1,
                                        fillColor: '#FF2223',
                                        iconContentLayout: placeMarkContentLayout(data),
                                        balloonContentLayout: balloonContentLayout(data),
                                    }}
                                />
                                : <></>
                        }
                    </Map>
                </div>
            </div>
            <SuccessModal close={() => {
                setErrorModal('');
                navigate(-1)
            }} description={errorModal} title='Ошибка' isError
                          isShowing={errorModal}/>
        </>
    )
}

export default VehicleMap