// Функция возвращаюшая наиболее часто встречающуюся ошибку в списке ошибок
export const getMostFrequentlyEncounteredError = errors => {
    const errorCounter = Object.values(errors).reduce((accumulator ={}, error) => {
        if(accumulator.hasOwnProperty(error)){
            accumulator[error] = accumulator[error] + 1
        } else {
            accumulator[error] = 1
        }

        return accumulator
    }, {})

    const mostEncounteredError = Object.keys(errorCounter).reduce((accumulator, error) => {
        if(errorCounter[error] <= errorCounter[accumulator]){
            return accumulator
        } else {
            return error
        }
    }, '')
    Object.keys(errors).forEach(field => {
        if(errors[field] !== mostEncounteredError) delete  errors[field]
    })

    return {...errors, message: mostEncounteredError}
}