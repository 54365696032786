import {useEffect, useState} from "react";
import {getAPI} from "../api/api";
import {getErrorMessageFromResponse} from "../utils/errors";


// Хук, возвращающий функцию для запроса данных и сами актуальные данные. Принимает в себя колбэк-запрос,
// параметры, колбэк для обработки ошибок и схему для нормализации полученных данных
const useLoadableData = (callback, params, onError, schema) => {
    const [data, setData] = useState({
        data: [],
        loading: true,

    })
    // url для получения следующей пачки данных
    const [next, setNext] = useState(null)
    // проверка на первый фетч данных. В случае первой загрузки, данные запрашиваются через callback(params),
    // все последующие через getApi.getRequest(next)
    const [firstLoad, setFirstLoad] = useState(true)

    // Обнуление стейта при обновлении параметров
    useEffect(() => {
        (async () => {
            setData(prev => ({
                ...prev,
                data: [],
            }))
            setFirstLoad(true)
            setNext(null)
            await fetchData(true)
        })()
    }, Object.values(params))

    const fetchData = async (isFirstLoad = firstLoad) => {
        setData(prev => ({...prev, loading: true}))

        if (isFirstLoad) {
            const response = await callback(params)

            if (response.error) {
                onError && onError(getErrorMessageFromResponse(response))
                setData(prev => ({...prev, data: []}))
                setFirstLoad(true)
            } else {
                setData(prev => ({
                    ...prev,
                    data: schema ? schema(response.results) : response.results,
                }))
                setFirstLoad(false)
                setNext(response.next)
            }
        } else if (next) {
            const response = await getAPI.getRequest(next)

            if (response.error) {
                onError && onError(getErrorMessageFromResponse(response))
            } else {
                setData(prev => ({
                    ...prev,
                    data: [...prev.data, ...(schema ? schema(response.results) : response.results)],
                }))
                setFirstLoad(false)
                setNext(response.next)
            }
        }

        setData(prev => ({...prev, loading: false}))

    }


    return [data, fetchData, setData]
}

export default useLoadableData