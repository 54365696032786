import React, {useState} from "react";
import {Modal, SubmitMenu, SuccessModal} from "../Modal";
import {useFormik} from "formik";
import {getErrorMessageFromResponse} from "../../../utils/errors";
import {ReactComponent as Password} from "../../../assets/auth/password.svg";
import {getAPI} from "../../../api/api";
import PasswordInput from "../../FormControls/PasswordInput";

const PasswordModal = ({close, resolve, isShowing}) => {

    const [errorModal, setErrorModal] = useState('')

    const style = {
        width: '420px'
    }

    const formik = useFormik({
        initialValues: {
            password: ''
        }
    })

    const submitHandler = async () => {
        if (!formik.values.password) {
            formik.setErrors({password: 'error', message: 'Поле не может быть пустым'})
            return
        }

        const response = await getAPI.checkPassword({password: formik.values.password})

        if (response.error) {
            setErrorModal(getErrorMessageFromResponse(response))
        } else {
            await resolve()
            close()
        }
    }

    return (
        <>
            <Modal close={close} style={style} title={'Введите пароль'} isShowing={isShowing}>
                {formik.errors.message ? <div className='form_error_message'>
                    <p>{formik.errors.message}</p>
                </div> : null}
                <PasswordInput onChange={formik.handleChange} name='password' id='password'
                               value={formik.values.password}
                               placeholder='Пароль' error={formik.errors.password} label='Пароль' icon={<Password/>}/>
                <SubmitMenu close={close} saveHandler={submitHandler} saveText='Ок'/>
            </Modal>
            <SuccessModal close={() => setErrorModal('')} description={errorModal} title='Ошибка'
                                        isError isShowing={errorModal}/>
        </>
    )
}

export default PasswordModal
