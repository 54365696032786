import Axios from "axios";
import Cookies from "js-cookie";
import {SERVER_URL, SOCKET_URL} from "../config";

export const serverURL = SERVER_URL
export const socketURL = SOCKET_URL
const apiURL = `${SERVER_URL}/api`

const instance = Axios.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'application/json',
    }
})

instance.interceptors.request.use(async config => {
    let token = Cookies.get('access') ?? ''
    if(token === 'null' || token === 'undefined') token = ''
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
})

instance.interceptors.response.use(
    response => response,
    async error => {
        if(error.response.status === 500){
            // window.location = '/500'
        }
        if ((error.response.status === 401 &&
            (error.response.data.code === 'token_not_valid'
                || error.response.data.detail === 'Пользователь удален'
                || error.response.data.detail === 'Данный токен недействителен для любого типа токена'))) {
            Cookies.remove('access')
        }

        return Promise.reject(error)
    }
)

const apiHandler = async (promise, statusCode) => {
    return await promise.then(response => {
        if (response.status === statusCode) {
            return {...response.data, statusCode}
        }
    }).catch(error => {
        return {error: error.response?.data, statusCode: error.response.status}
    })
}

const objectToParams = filters => {
    let params = []
    for (let key in filters) {
        if (!filters[key]) continue
        if (Array.isArray(filters[key])) {
            params.push(`${key}=${filters[key].join(',')}`)
        } else {
            params.push(`${key}=${filters[key]}`)
        }
    }
    if (params.length) return `?${params.join('&')}`
    return ''
}

export const getAPI = {
    getRequest: async url => {
        return await apiHandler(instance.get(url), 200)
    },
    // AUTH
    auth: async data => {
        return await apiHandler(instance.post(`/auth/jwt/create/`, data), 200)
    },
    registerPersonal: async (data, options) => {
        return await apiHandler(instance.post(`/auth/users/`, data), 201, options)
    },
    registerPersonalUpd: async (id, data, auto) => {
        return await apiHandler(instance.patch(`/auth/users/update/${id}/${objectToParams({auto})}`, data), 200)
    },
    phoneVerify: async data => {
        return await apiHandler(instance.post(`/auth/verify/`, data), 200)
    },
    sendPasswordEmail: async data => {
        return await apiHandler(instance.post(`/auth/send_password/`, data), 200)
    },
    sendHelpMessage: async data => {
        return await apiHandler(instance.post(`/auth/send_help/`, data), 200)
    },
    resetPassword: async data => {
        return await apiHandler(instance.post(`/auth/reset_password/`, data), 200)
    },
    updateToken: () => {

    },

    //PROFILE
    getCurrentUser: async () => {
        return await apiHandler(instance.get(`/auth/users/me`), 200)
    },
    editCurrentUser: async (data, auto) => {
        return await apiHandler(instance.patch(`/auth/users/me/?auto=${auto ? 'True' : 'False'}`, data), 200)
    },
    editEntity: async (id, data) => {
        return await apiHandler(instance.patch(`/v1/entity/${id}/`, data), 200)
    },
    changePassword: async data => {
        return await apiHandler(instance.patch(`/auth/change_password/`, data), 200)
    },
    changePhone: async data => {
        return await apiHandler(instance.patch(`/auth/change_phone/`, data), 200)
    },
    changeEmail: async data => {
        return await apiHandler(instance.patch(`/auth/change_email/`, data), 200)
    },
    checkPassword: async data => {
        return await apiHandler(instance.post(`/auth/check_password/`, data), 200)
    },
    sendPhoneCode: async data => {
        return await apiHandler(instance.post(`/auth/send/`, data), 200)
    },
    sendEmailCode: async data => {
        return await apiHandler(instance.post(`/auth/send_email/`, data), 200)
    },

    //USERS
    getUsers: async params => {
        return await apiHandler(instance.get(`/auth/users/all/${objectToParams(params)}`), 200)
    },
    deleteUser: async users => {
        return await apiHandler(instance.delete(`/auth/users/delete/`, {data: {users}}), 200)
    },
    addUser: async data => {
        return await apiHandler(instance.post(`/auth/users/create/`, data), 201)
    },
    editUser: async (id, data) => {
        return await apiHandler(instance.patch(`/auth/users/get/${id}/`, data), 200)
    },
    editUserAccess: async id => {
        return await apiHandler(instance.options(`/auth/users/get/${id}/update/`), 200)
    },
    getUserById: async id => {
        return await apiHandler(instance.get(`/auth/users/get/${id}/`), 200)
    },
    blockUser: async users => {
        return await apiHandler(instance.post(`/auth/users/block/`, {users}), 200)
    },

    //SEARCH
    search: async (params) => {
        return await apiHandler(instance.get(`/v1/search/${objectToParams(params)}`), 200)
    },

    //VEHICLES
    getVehicles: async params => {
        return await apiHandler(instance.get(`/v1/vehicle/all/${objectToParams(params)}`), 200)
    },
    createVehicle: async data => {
        return await apiHandler(instance.post(`/v1/vehicle/create/`, data), 201)
    },
    editVehicle: async (id, data) => {
        return await apiHandler(instance.patch(`/v1/vehicle/${id}/update/`, data), 200)
    },
    editVehicleAccess: async id => {
        return await apiHandler(instance.options(`/v1/vehicle/${id}/update/`), 200)
    },
    deleteVehicles: async vehicles => {
        return await apiHandler(instance.delete(`/v1/vehicle/delete/`, {data: {vehicles}}), 200)
    },
    getVehicleById: async id => {
        return await apiHandler(instance.get(`/v1/vehicle/${id}/`), 200)
    },
    getVehicleId: async () => {
        return await apiHandler(instance.get(`/v1/vehicle/id/`), 200)
    },
    getVehicleTypes: async params => {
        return await apiHandler(instance.get(`/v1/vehicle/types/${objectToParams(params)}`), 200)
    },
    getVehicleBrands: async params => {
        return await apiHandler(instance.get(`/v1/vehicle/brand/${objectToParams(params)}`), 200)
    },
    getTypeDocument: async () => {
        return await apiHandler(instance.get(`/v1/vehicle/type_document/`), 200)
    },
    getTypeRegister: async () => {
        return await apiHandler(instance.get(`/v1/vehicle/type_register/`), 200)
    },
    getTypeDocAdmiss: async () => {
        return await apiHandler(instance.get(`/v1/vehicle/type_doc_admiss/`), 200)
    },
    deleteVehicleMonitoring: async id => {
        return await apiHandler(instance.post(`/v1/vehicle/unlink/${id}/`), 200)
    },
    getVehicleMap: async id => {
        return await apiHandler(instance.get(`/v1/map/vehicle/${id}/`), 200)
    },

    //OBJECTS
    getObjects: async params => {
        return await apiHandler(instance.get(`/v1/objects/all/${objectToParams(params)}`), 200)
    },
    getObjectId: async () => {
        return await apiHandler(instance.get(`/v1/objects/id/`), 200)
    },
    getObjectById: async id => {
        return await apiHandler(instance.get(`/v1/objects/${id}/`), 200)
    },
    createObject: async data => {
        return await apiHandler(instance.post(`/v1/objects/create/`, data), 201)
    },
    editObjectAccess: async id => {
        return await apiHandler(instance.options(`/v1/objects/${id}/update/`), 200)
    },
    editObject: async (id, data) => {
        return await apiHandler(instance.patch(`/v1/objects/${id}/update/`, data), 200)
    },
    deleteObjects: async objects => {
        return await apiHandler(instance.delete(`/v1/objects/delete/`, {data: {objects}}), 200)
    },

    //DRIVERS
    getDrivers: async params => {
        return await apiHandler(instance.get(`/v1/drivers/${objectToParams(params)}`), 200)
    },
    getDriverId: async () => {
        return await apiHandler(instance.get(`/v1/drivers/id/`), 200)
    },
    createDriver: async data => {
        return await apiHandler(instance.post(`/v1/drivers/create/`, data), 201)
    },
    editDriver: async (id, data) => {
        return await apiHandler(instance.patch(`/v1/drivers/${id}/update/`, data), 200)
    },
    editDriverAccess: async id => {
        return await apiHandler(instance.options(`/v1/drivers/${id}/update/`), 200)
    },
    getDriverById: async id => {
        return await apiHandler(instance.get(`/v1/drivers/${id}/`), 200)
    },
    deleteDrivers: async drivers => {
        return await apiHandler(instance.delete(`/v1/drivers/delete/`, {data: {drivers}}), 200)
    },
    getDriverTypes: async () => {
        return await apiHandler(instance.get(`/v1/drivers/type/types/`), 200)
    },

    //BIDS
    getBidId: async () => {
        return await apiHandler(instance.get(`/v1/bids/id/`), 200)
    },
    getBids: async params => {
        return await apiHandler(instance.get(`/v1/bids/all/${objectToParams(params)}`), 200)
    },
    createBid: async data => {
        return await apiHandler(instance.post(`/v1/bids/create/`, data), 201)
    },
    editBid: async (id, data) => {
        return await apiHandler(instance.patch(`/v1/bids/get/${id}/update/`, data), 200)
    },
    editBidAccess: async id => {
        return await apiHandler(instance.options(`/v1/bids/get/${id}/update/`), 200)
    },
    deleteBid: async bids => {
        return await apiHandler(instance.delete(`/v1/bids/delete/`, {data: {bids}}), 200)
    },
    getBidById: async id => {
        return await apiHandler(instance.get(`/v1/bids/get/${id}/`), 200)
    },

    //COMPANIES
    getCompanies: async (filters) => {
        return await apiHandler(instance.get(`/auth/users/companies/${objectToParams(filters)}`), 200)
    },
    getEntity: async id => {
        return await apiHandler(instance.get(`/v1/entity/${id}/`), 200)
    },
    getDistricts: async params => {
        return await apiHandler(instance.get(`/v1/entity/district/${objectToParams(params)}`), 200)
    },
    getSubjectRF: async params => {
        return await apiHandler(instance.get(`/v1/entity/subject_rf/${objectToParams(params)}`), 200)
    },
    sendInvitation: async (data) => {
        return await apiHandler(instance.post(`/auth/invitations/`, data), 201)
    },
    acceptInvitation: async id => {
        return await apiHandler(instance.patch(`/auth/invitations/${id}/`, {status: 'Сотрудничество'}), 200)
    },
    rejectInvitation: async id => {
        return await apiHandler(instance.delete(`/auth/invitations/${id}/`), 204)
    },

    //TARIFFS
    getTariffs: async () => {
        return await apiHandler(instance.get(`/v1/tariff/`), 200)
    },

    //MAP
    getMapData: async id => {
        return await apiHandler(instance(`/v1/map/${id}/`), 200)
    }


}
