function getCoords(ref) { // crossbrowser version
    const box = ref.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const bottom = box.bottom + scrollTop - clientTop;
    const right = box.right + scrollLeft - clientLeft
    const left = box.left + scrollLeft - clientLeft;

    const height = bottom - top

    return {
        top: Math.round(top),
        left: Math.round(left),
        bottom: Math.round(bottom),
        right: Math.round(right),
        height: Math.round(height)
    };
}

export default getCoords