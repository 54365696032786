// Функция для извлечение сообщения об ошибке из ответа на запрос
export const getErrorMessageFromResponse = response => {

    const getItemFromObject = object => {
        return object[Object.keys(object)[0]]
    }

    const getItemFromArray = array => {
        return array[0]
    }

    if(!response.error) return 'Произошла ошибка'

    let error = response.error

    while(typeof error !== 'string'){
        if(Array.isArray(error)){
            error = getItemFromArray(error)
        } else {
            error = getItemFromObject(error)
        }
    }

    return error
}
