import React, {useState} from "react";
import {SelectionModal} from "../../Modal/Modal";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "../../UI/Button";

const ListInput = ({
                       id,
                       label,
                       error,
                       isRequired,
                       buttonText,
                       selectedItems,
                       allItems,
                       setItems,
                       modalTitle,
                       disabled
                   }) => {

    const [listModal, setListModal] = useState(false)

    return (
        <div className='form_control'>
            {listModal &&
                <SelectionModal
                    close={() => setListModal(false)}
                    selectedItems={selectedItems}
                    allItems={allItems}
                    saveItems={setItems}
                    title={modalTitle}
                    isShowing={listModal}
                />}
            <div className='form_control_menu'>
                {label && <div className='form_control_label'>
                    <label htmlFor={id}>{label} {isRequired && <span>*</span>}</label>
                </div>}
                <Button
                    id={`list_button_${id}`}
                    disabled={disabled}
                    onClick={() => setListModal(true)}
                    text={buttonText || 'Выбрать'}
                />
                <div className={clsx('form_control_menu_list', error && 'form_control_menu_list_error')} id={id}>
                    {!selectedItems.length && <p style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>Список пуст</p>}
                    {selectedItems.map(item => <p key={item.id}>{item.name}</p>)}
                </div>
            </div>
        </div>
    )
}

ListInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    disabled: PropTypes.bool,
    setItems: PropTypes.func.isRequired,
    allItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string
    })).isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string
    })).isRequired,
    error: PropTypes.any,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    buttonText: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
}
export default ListInput
