import React, {useEffect} from "react";
import {Modal, SubmitMenu} from "../Modal";
import {useFormik} from "formik";
import {ReactComponent as Password} from "../../../assets/auth/password.svg";
import PasswordInput from "../../FormControls/PasswordInput";
import * as yup from "yup";
import {getMostFrequentlyEncounteredError} from "../../../schemas";

const schema = yup.object().shape({
    password: yup.string()
        .required('Заполните обязательные поля')
        .default('')
        .test('checkPasLength', 'Слишком короткий пароль', password => !password || password.trim().length >= 8)
        .oneOf([yup.ref('repeat_password'), null], 'Пароли не совпадают'),
    repeat_password: yup.string()
        .required('Заполните обязательные поля')
        .default('')
        .oneOf([yup.ref('password'), null], 'Пароли не совпадают'),
})

const NewPasswordModal = ({close, resolve, isShowing}) => {

    const style = {
        width: '420px'
    }

    const formik = useFormik({
        initialValues: schema.cast({}),
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        validationSchema: schema,
        onSubmit: async values => {
            await resolve(values.password)
        }
    })

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    return (
        <Modal close={close} title='Смена пароля' style={style} isShowing={isShowing}>
            {formik.errors.message ? <div className='form_error_message'>
                <p>{formik.errors.message}</p>
            </div> : null}
            <PasswordInput onChange={formik.handleChange} name='password' id='password'
                           value={formik.values.password}
                           placeholder='Новый пароль' error={formik.errors.password} label='Новый пароль'
                           icon={<Password/>}/>
            <PasswordInput onChange={formik.handleChange} name='repeat_password' id='repeat_password'
                           value={formik.values.repeat_password}
                           placeholder='Повторите пароль' error={formik.errors.repeat_password}
                           label='Повторите пароль' icon={<Password/>}/>
            <SubmitMenu saveHandler={formik.submitForm} close={close}/>
        </Modal>
    )
}

export default NewPasswordModal
