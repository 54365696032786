import React, {useCallback} from "react";
import classes from './Modal.module.sass'
import {ReactComponent as Close} from "../../assets/close.svg";
import Button from "../UI/Button";
import {Transition} from "react-transition-group";
import ReactDOM from "react-dom";
import LoadingScreen from "../LoadingScreen";
import useEnter from "../../hooks/useEnter";

const Backdrop = ({children, close, style}) => {
    return (
        <div className={classes.backdrop} onMouseDown={close} style={style}>
            {children}
        </div>
    )
}

export const Modal = ({children, close, style, title, scroll, isLoading, isShowing, contentStyle}) => {

    const transitionStyles = {
        entering: {
            visibility: 'visible',
            opacity: 0.5
        },
        entered: {
            visibility: 'visible',
            opacity: 1
        },
        exiting: {
            visibility: 'visible',
            opacity: 0.5
        },
        exited: {
            visibility: 'hidden',
            opacity: 0
        }
    }

    const scrollRef = useCallback(scrollRef => {
        if ((scroll !== 0 && !scroll) || !scrollRef) return

        scrollRef.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [scroll])

    if(isLoading){

        return (
            isShowing ? ReactDOM.createPortal(
                <Transition in={isShowing} timeout={100}>
                    {state => (
                        <Backdrop close={close} style={transitionStyles[state]}>
                            <div className={classes.modal} style={{...style, ...transitionStyles[state]}} onClick={event => event.stopPropagation()}
                                 onMouseDown={e => e.stopPropagation()}>
                                <div className={classes.header}>
                                    <p>Загрузка</p>
                                    <div className={classes.header_icon} onClick={close}>
                                        <Close/>
                                    </div>
                                </div>
                                <div className={classes.content} style={contentStyle} ref={scrollRef}>
                                    <LoadingScreen/>
                                </div>
                            </div>
                        </Backdrop>
                    )}
                </Transition>, document.body
            ) : null
        )
    }

    return isShowing ? ReactDOM.createPortal(
        <Transition in={isShowing ? true : false} timeout={100}>
            {state => (
                <Backdrop close={close} style={transitionStyles[state]}>
                    <div className={classes.modal} style={{...style, ...transitionStyles[state]}} onClick={event => event.stopPropagation()}
                         onMouseDown={e => e.stopPropagation()}>
                        <div className={classes.header}>
                            <p>{title}</p>
                            <div className={classes.header_icon} onClick={close}>
                                <Close/>
                            </div>
                        </div>
                        <div className={classes.content} style={contentStyle} ref={scrollRef}>
                            {children}
                        </div>
                    </div>
                </Backdrop>
            )}
        </Transition>, document.body
    ) : null
}

export const SubmitMenu = ({saveHandler, close, style, saveText = 'Сохранить', declineText = 'Отмена'}) => {

    return (
        <div className={classes.submit} style={style}>
            <div className={classes.submit_menu}>
                <Button style={{minWidth: '90px'}} text={saveText} onClick={async event => await saveHandler(event)}/>
                <Button style={{minWidth: '90px'}} text={declineText} onClick={close} type='secondary'/>
            </div>
        </div>
    )
}

export {default as VerificationModal} from './VerificationModal'

export {default as SuccessModal} from './SuccessModal'

export {default as TariffModal} from './TariffModal'

export {default as ConfirmModal} from './ConfirmModal'

export {default as DateRangeModal} from './DateRangeModal'

export {default as NewPasswordModal} from './NewPasswordModal'

export {default as EmailModal} from './EmailModal'

export {default as PhoneModal} from './PhoneModal'

export {default as PasswordModal} from './PasswordModal'

export {default as DocumentModal} from './DocumentModal'

export {SelectionModal} from './SelectionModal'

export {TreeSelectionModal} from './SelectionModal'
