export const GENERAL_SET_PAGE = 'GENERAL_SET_PAGE'

export const SETTINGS_SET_USERS = 'SETTINGS_SET_USERS'
export const SETTINGS_ADD_USERS = 'SETTINGS_ADD_USERS'
export const SETTINGS_SET_RENDER = `SETTINGS_SET_RENDER`
export const SETTINGS_SET_LOADING = 'SETTINGS_SET_LOADING'
export const SETTINGS_SET_NEXT = 'SETTINGS_SET_NEXT'

export const VEHICLES_SET_VEHICLES = 'VEHICLES_SET_VEHICLES'
export const VEHICLES_ADD_VEHICLES = 'VEHICLES_ADD_VEHICLES'
export const VEHICLES_SET_RENDER = 'VEHICLES_SET_RENDER'
export const VEHICLES_SET_LOADING = 'VEHICLES_SET_LOADING'
export const VEHICLES_SET_NEXT = 'VEHICLES_SET_NEXT'

export const DRIVERS_SET_DRIVERS = 'DRIVERS_SET_DRIVERS'
export const DRIVERS_ADD_DRIVERS = 'DRIVERS_ADD_DRIVERS'
export const DRIVERS_SET_RENDER = 'DRIVERS_SET_RENDER'
export const DRIVERS_SET_LOADING = 'DRIVERS_SET_LOADING'
export const DRIVERS_SET_NEXT = 'DRIVERS_SET_NEXT'

export const BIDS_SET_RENDER = 'BIDS_SET_RENDER'
export const BIDS_SET_BIDS = 'BIDS_SET_BIDS'
export const BIDS_ADD_BIDS = 'BIDS_ADD_BIDS'
export const BIDS_SET_LOADING = 'BIDS_SET_LOADING'
export const BIDS_SET_NEXT = 'BIDS_SET_NEXT'

export const OBJECTS_SET_RENDER = 'OBJECTS_SET_RENDER'
export const OBJECTS_SET_OBJECTS = 'OBJECTS_SET_OBJECTS'
export const OBJECTS_ADD_OBJECTS = 'OBJECTS_ADD_OBJECTS'
export const OBJECTS_SET_LOADING = 'OBJECTS_SET_LOADING'
export const OBJECTS_SET_NEXT = 'OBJECTS_SET_NEXT'

export const COMPANIES_SET_RENDER = 'COMPANIES_SET_RENDER'
export const COMPANIES_SET_COMPANIES = 'COMPANIES_SET_COMPANIES'
export const COMPANIES_ADD_COMPANIES = 'COMPANIES_ADD_COMPANIES'
export const COMPANIES_SET_LOADING = 'COMPANIES_SET_LOADING'
export const COMPANIES_SET_NEXT = 'COMPANIES_SET_NEXT'
