import {useEffect} from "react";

// Хук, вызывающий колбэк при нажатии на Enter
const useEnter = submit => {

    useEffect(() => {

        const onKeyPress = event => {
            if (event.key === 'Enter')
                submit()
        }

        document.addEventListener('keypress', onKeyPress)

        return () => {
            document.removeEventListener('keypress', onKeyPress)
        }
    }, [submit])

}

export default useEnter