import * as yup from "yup";
import staticData from "../utils/staticData";

const objectSchema = yup.object().shape({
    id: yup.string().required(),
    name: yup.string().trim().required('Заполните обязательные поля').default(''),
    creator: yup.number().required().default(''),
    time_zone: yup.string().required('Заполните обязательные поля').default(staticData.object.timezones[15].name),
    format: yup.string().required('Заполните обязательные поля').default(staticData.object.formats[0]),
    executor: yup.array().of(yup.object().shape({
        id: yup.number(),
        name: yup.string()
    })).required('Заполните обязательные поля').min(1, 'Заполните обязательные поля').default([]),
    types: yup.array().of(yup.number()).required('Заполните обязательные поля').min(1, 'Заполните обязательные поля').default([]),
    tariff_vehicle: yup.array().of(yup.object().shape({
        tariff: yup.object().shape({
            id: yup.string(),
            name: yup.string()
        }).nullable(true),
        vehicle: yup.object().shape({
            id: yup.string(),
            name: yup.string()
        })
    }))
        // .required('Заполните обязательные поля')
        // .min(1, 'Заполните обязательные поля')
        // .test('checkTariff', 'Каждому ТС необходимо назначить тариф', tariff_vehicle => tariff_vehicle.every(t_v => t_v.tariff))
        .default([]),
    tariffs: yup.array().of(yup.object()).nullable(true).when('tariff_vehicle', {
            is: tariff_vehicle => tariff_vehicle.every(t_v => t_v.tariff),
            then: schema => schema,
            otherwise: schema => schema.required('Каждому ТС необходимо назначить тариф')
        }
    )
})

export default objectSchema
