import React, {useEffect, useState} from "react";
import classes from './Layout.module.sass'
import {ReactComponent as Logo} from "../../assets/logo_main.svg";
import {ReactComponent as LogoHidden} from "../../assets/navigation/logo.svg";
import {ReactComponent as Collapse} from "../../assets/navigation/collapse.svg";
import {ReactComponent as HeadSettings} from "../../assets/header/settings.svg";
import {ReactComponent as HeadSearch} from "../../assets/header/search.svg";
import {ReactComponent as Info} from "../../assets/header/info.svg";
import {ReactComponent as Avatar} from "../../assets/header/profile.svg";
import {ReactComponent as Logout} from "../../assets/header/logout.svg";
import {ReactComponent as Burger} from "../../assets/header/burger.svg";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import clsx from "clsx";
import {ConfirmModal} from "../../components/Modal/Modal";
import {Outlet, useLocation, useNavigate} from "react-router";
import useSessionStorage from "../../hooks/useSessionStorage";
import {navbarItems} from "../../config";
import useIsMobile from "../../hooks/useIsMobile";
import useAuth from "../../hooks/useAuth";

const Navbar = ({hidden, setHidden}) => {
    const {currentPage} = useSelector(state => state.General)
    const {user} = useAuth()

    return (
        <div className={classes.navigation}>
            <div className={clsx(classes.navigation_logo, hidden && classes.navigation_logo_hidden)}>
                {hidden ? <LogoHidden/> : <Logo/>}
            </div>
            <div className={clsx(classes.navigation_menu, hidden && classes.navigation_menu_hidden)}>
                {navbarItems(user.account).map(item => (
                    <Link
                        className={clsx(classes.navigation_menu_item, currentPage === item.name && classes.navigation_menu_item_active, hidden && classes.navigation_menu_item_hidden)}
                        to={item.path} key={item.name}>
                        {item.icon}
                        {hidden ? null :
                            <p style={item.name === 'Транспортные средства' ? {marginLeft: '15px'} : null}>{item.name}</p>}
                    </Link>
                ))}
            </div>
            <div className={clsx(classes.navigation_collapse, hidden && classes.navigation_collapse_hidden)}
                 onClick={() => setHidden(!hidden)}>
                <Collapse/>
            </div>
        </div>
    )
}

const MobileNavbar = ({hidden, setHidden}) => {
    const {currentPage} = useSelector(state => state.General)
    const {user} = useAuth()

    return (
        <div className={clsx(classes.navigation, hidden && classes.navigation_hidden)}>
            <div className={classes.navigation_logo}>
                <Logo/>
            </div>
            <div className={classes.navigation_profile}>
                <Profile onLogout={() => setHidden(false)}/>
            </div>
            <div className={classes.navigation_menu}>
                {navbarItems(user.account).map(item => (
                    <Link
                        className={clsx(classes.navigation_menu_item, currentPage === item.name && classes.navigation_menu_item_active)}
                        to={item.path} key={item.name}>
                        {item.icon}
                        <p style={item.name === 'Транспортные средства' ? {marginLeft: '15px'} : null}>{item.name}</p>
                    </Link>
                ))}
            </div>
            <div className={clsx(classes.navigation_collapse)}
                 onClick={() => setHidden(!hidden)}>
                <Collapse/>
            </div>

        </div>
    )
}

const Profile = ({onLogout = () => {}}) => {
    const {user} = useAuth()
    const {logout} = useAuth()
    const navigate = useNavigate()

    const [logoutModal, setLogoutModal] = useState(false)

    const logoutHandler = () => {
        logout()
    }
    return (
        <>
            <div className={classes.profile} onClick={() => navigate('/dashboard/profile')}>
                <div className={classes.profile_avatar}>
                    <Avatar/>
                </div>
                <div className={classes.profile_info}>
                    <p className={classes.profile_info_name}>{user?.last_name} {user?.first_name?.substring(0, 1)}.</p>
                    <p className={classes.profile_info_account}>{user?.account?.toLowerCase()}</p>
                </div>
                <div className={classes.profile_logout} onClick={e => {
                    e.stopPropagation();
                    onLogout()
                    setLogoutModal(true)
                }}>
                    <Logout/>
                </div>
            </div>
            <ConfirmModal close={() => setLogoutModal(false)} title={'Выход'} text={'Вы действительно хотите выйти?'}
                          confirmHandler={logoutHandler} isShowing={logoutModal}/>
        </>
    )
}

const Layout = () => {

    const isMobile = useIsMobile()
    const {pathname} = useLocation()

    const [hidden, setHidden] = useSessionStorage('hide_navbar', false)

    useEffect(()=>{
        if(isMobile) setHidden(false)
    },[pathname])

    return (
        <div className={classes.layout}>
            <div className={clsx(classes.layout_navigation, hidden && classes.layout_navigation_hidden)}>
                {!isMobile ? <Navbar hidden={hidden} setHidden={setHidden}/> :
                    <MobileNavbar hidden={hidden} setHidden={setHidden}/>}
            </div>
            <div className={clsx(classes.layout_content, hidden && classes.layout_content_hidden)}>
                <div className={classes.layout_content_header}>
                    {!isMobile ? (
                        <>
                            <div className={classes.layout_content_header_menu}>
                                <Link to={'/dashboard/profile/configuration'}><HeadSettings/></Link>
                                <Link to={'/help'}><Info/></Link>
                                <Link to={'/dashboard/search'}><HeadSearch/></Link>
                            </div>
                            <Profile/>
                        </>
                    ) : (
                        <div className={classes.layout_content_header_menu}>
                            <div onClick={() => setHidden(prev => !prev)}>
                                <Burger/>
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.layout_content_wrapper}>
                    <div className={classes.layout_content_wrapper_page}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout
