import useAuth from "../hooks/useAuth";
import {Navigate} from "react-router-dom";

// Данный гард перенаправляет пользователя в личный кабинет после прохождения авторизации
const GuestGuard = ({children}) => {
    const {isAuthenticated} = useAuth()

    if(isAuthenticated){
        return <Navigate to='/dashboard/profile'/>
    }

    return <>{children}</>
}

export default GuestGuard
