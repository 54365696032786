import React, {useEffect, useState} from "react";
import DateInput from "../../FormControls/DateInput";
import classes from './DriverModal.module.sass'
import {DocumentModal, Modal, SubmitMenu, SuccessModal} from "../Modal";
import {useFormik} from "formik";
import {getAPI} from "../../../api/api";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {createDriver, editDriver} from "../../../redux/actions/Drivers";
import {timeToISO, timeFromISO} from "../../../utils/timeConverters";
import {getErrorMessageFromResponse} from "../../../utils/errors";
import {ReactComponent as Trash} from "../../../assets/trash.svg";
import TextInput from "../../FormControls/TextInput";
import TextareaInput from "../../FormControls/TextareaInput";
import PhoneInput from "../../FormControls/PhoneInput";
import {useParams} from "react-router";
import driverSchema from "../../../schemas/driverSchema";
import {getMostFrequentlyEncounteredError} from "../../../schemas";
import DropdownInput from "../../FormControls/DropdownInput";
import useAuth from "../../../hooks/useAuth";
import Button from "../../UI/Button";
import useLoadableData from "../../../hooks/useLoadableData";

const DriverModal = ({close}) => {

    const dispatch = useDispatch()
    const params = useParams()

    const {user} = useAuth()

    const formik = useFormik({
        initialValues: driverSchema.cast({
            id: params.id,
            contractor: user.id
        }),
        validationSchema: driverSchema,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        validate: () => setScroll(prev => prev + 1),
        onSubmit: async values => {
            const data = {
                ...values,
                object: values.object?.id || '',
                passport_date: timeToISO(values.passport_date),
                type: values.type.map(type => ({
                    ...type,
                    type: type.type.id,
                    category: type.category.map(category => category.id),
                    driver_license_date: timeToISO(type.driver_license_date),
                    driver_license_deadline: timeToISO(type.driver_license_deadline)
                })),
                vehicle: values.vehicle?.id || ''
            }

            if (params.type === 'edit') {
                try {
                    const response = await dispatch(editDriver(params.id, data))

                    if (response.error) {
                        setErrorModal(getErrorMessageFromResponse(response))
                    } else {
                        setSuccessModal('Водитель изменен')
                    }
                } catch (e) {
                    setErrorModal(getErrorMessageFromResponse(e))
                    return
                }
            } else {
                try {
                    const response = await dispatch(createDriver(data))

                    if (response.error) {
                        setErrorModal(getErrorMessageFromResponse(response))
                    } else {
                        setSuccessModal('Водитель добавлен в систему')
                    }
                } catch (e) {
                    setErrorModal(getErrorMessageFromResponse(e))
                    return
                }
            }

        }
    })

    const [vehicles, fetchVehicles] = useLoadableData(
        getAPI.getVehicles,
        {free: 'True'},
        error => setErrorModal(error),
        vehicles => vehicles.map(vehicle => ({id: vehicle.id, name: vehicle.show_name}))
    )

    const [title, setTitle] = useState(params.type === 'edit' ? 'Редактирование водителя' : 'Добавление водителя')
    const [scroll, setScroll] = useState(0)
    const [isWatch, setIsWatch] = useState(false)
    const [isLoading, setLoading] = useState(true)

    const [documentModal, setDocumentModal] = useState(false)
    const [successModal, setSuccessModal] = useState('')
    const [errorModal, setErrorModal] = useState('')

    useEffect(() => {
        formik.setErrors(getMostFrequentlyEncounteredError(formik.errors))
    }, [formik.errors])

    const style = {
        width: '750px',
    }

    const fetchAccess = async () => {
        const response = await getAPI.editDriverAccess(params.id)
        if (response.error) {
            setIsWatch(true)
            setTitle('Просмотр водителя')
        } else {
            setIsWatch(false)
        }
    }

    const fetchDriver = async () => {
        const driver = await getAPI.getDriverById(params.id)

        if (driver.error) return

        await formik.setValues(driverSchema.cast({
            ...driver,
            contractor: driver.contractor.id,
            passport_date: timeFromISO(driver.passport_date).date,
            type: driver.type?.map(type => ({
                ...type,
                type: {
                    id: type.type?.id,
                    name: type.type?.name
                },
                category: type.category.map(category => ({
                    id: category.id,
                    name: category.code || '-',
                })),
                driver_license_date: timeFromISO(type.driver_license_date).date,
                driver_license_deadline: timeFromISO(type.driver_license_deadline).date,
            })),
            vehicle: driver.vehicle ? {
                id: driver.vehicle.id,
                name: driver.vehicle.show_name
            } : null
        }))

        await fetchAccess()

        if (driver.status === 'Удален') {
            setIsWatch(true)
            setTitle('Просмотр водителя')
        }
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            await fetchDriver()
            setLoading(false)

            await fetchVehicles()
        })()
    }, [params.id])

    useEffect(() => {
        const division_code = formik.values.division_code
        if (!division_code) return
        if (division_code.includes('-') || division_code.includes('_')) {
            formik.setFieldValue('division_code', division_code.replaceAll('-', '').replaceAll('_', ''))
        }
    }, [formik.values.division_code])

    useEffect(() => {
        const passport_number = formik.values.passport_number
        if (!passport_number) return
        if (passport_number.includes(' ') || passport_number.includes('_')) {
            formik.setFieldValue('passport_number', passport_number.replaceAll(' ', '').replaceAll('_', ''))
        }
    }, [formik.values.passport_number])

    const removeDocumentHandler = id => {
        formik.setFieldValue('type', formik.values.type.filter(type => type.id != id))
    }

    return (
        <>
            <Modal close={close} title={title} style={style} scroll={scroll} isLoading={isLoading} isShowing>
                <div className={classes.header}>
                    <div className={classes.header_id}>
                        <p>ID</p>
                        <p>{formik.values.id}</p>
                    </div>
                </div>
                {formik.errors.message ? <div className='form_error_message'>
                    <p>{formik.errors.message}</p>
                </div> : null}
                <div className='form'>
                    <div className='form_columns'>
                        <div className='form_columns_column'>
                            <div className='form_control'>
                                <div className='form_control_label'>
                                    <label>ФИО <span>*</span></label>
                                </div>
                                {/*last_name*/}
                                <TextInput onChange={formik.handleChange} name='last_name' id='last_name'
                                           mask='aaaaaaaaaaaaaaa'
                                           formatChars={{'a': '[A-Za-zА-Яа-яёЁ]'}} maskChar=''
                                           value={formik.values.last_name} disabled={isWatch} placeholder='Фамилия'
                                           error={formik.errors.last_name} isRequired/>
                                {/*first_name*/}
                                <TextInput onChange={formik.handleChange} name='first_name' id='first_name'
                                           mask='aaaaaaaaaaaaaaa'
                                           formatChars={{'a': '[A-Za-zА-Яа-яёЁ]'}} maskChar=''
                                           value={formik.values.first_name} disabled={isWatch} placeholder='Имя'
                                           error={formik.errors.first_name} isRequired/>
                                {/*middle_name*/}
                                <TextInput onChange={formik.handleChange} name='middle_name' id='middle_name'
                                           mask='aaaaaaaaaaaaaaa'
                                           formatChars={{'a': '[A-Za-zА-Яа-яёЁ]'}} maskChar=''
                                           value={formik.values.middle_name} disabled={isWatch} placeholder='Отчество'
                                           error={formik.errors.middle_name}/>
                            </div>
                            {/*phone*/}
                            <PhoneInput
                                onChange={value => formik.setFieldValue('phone', value)}
                                name='phone' id='phone' formValue={formik.values.phone} disabled={isWatch}
                                error={formik.errors.phone} isRequired viewIcon={false}
                            />
                            {/*email*/}
                            <TextInput onChange={formik.handleChange} name='email' id='email' label='Почта'
                                       mask='aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' maskChar=''
                                       formatChars={{'a': '[A-Za-z.@0-9\-_]'}}
                                       value={formik.values.email} disabled={isWatch} placeholder='mail@mail.ru'
                                       error={formik.errors.email}/>
                            <div className='form_control'>
                                <div className='form_control_label'>
                                    <label>Паспорт</label>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: '10px'
                                }}>
                                    {/*passport_number*/}
                                    <TextInput onChange={formik.handleChange} name='passport_number'
                                               id='passport_number'
                                               label='Номер паспорта' labelStyle={{fontSize: '14px'}}
                                               value={formik.values.passport_number} disabled={isWatch}
                                               placeholder='____ ______' outerStyle={{width: '150px'}}
                                               error={formik.errors.passport_number} mask='9999 999999'
                                               inputStyle={{
                                                   letterSpacing: '3px',
                                                   fontSize: '12px',
                                                   paddingRight: '5px'
                                               }}/>
                                    {/*passport_date*/}
                                    <DateInput
                                        labelStyles={{fontSize: '14px'}}
                                        error={formik.errors.passport_date} id='passport_date' name='passport_date'
                                        disabled={isWatch} label='Дата выдачи' outerStyles={{width: '120px'}}
                                        value={formik.values.passport_date}
                                        onChange={(date) => formik.setFieldValue('passport_date', date)}
                                        inputStyles={{fontSize: '12px'}}
                                    />
                                </div>
                                {/*passport_entity*/}
                                <TextInput onChange={formik.handleChange} name='passport_entity' id='passport_entity'
                                           label='Орган, выдавший паспорт' labelStyle={{fontSize: '14px'}}
                                           value={formik.values.passport_entity} disabled={isWatch}
                                           placeholder='Орган, выдавший паспорт'
                                           error={formik.errors.passport_entity}
                                           inputStyle={{fontSize: '12px'}}/>
                                {/*division_code*/}
                                <TextInput onChange={formik.handleChange} name='division_code' id='division_code'
                                           label='Код подразделения' labelStyle={{fontSize: '14px'}}
                                           value={formik.values.division_code} disabled={isWatch}
                                           placeholder='___-___' mask='999-999'
                                           error={formik.errors.division_code}
                                           inputStyle={{fontSize: '12px', letterSpacing: '3px'}}/>
                                {/*passport_address*/}
                                <TextInput onChange={formik.handleChange} name='passport_address' id='passport_address'
                                           label='Адрес регистрации' labelStyle={{fontSize: '14px'}}
                                           inputStyle={{fontSize: '12px'}}
                                           value={formik.values.passport_address} disabled={isWatch}
                                           placeholder='Адрес регистрации'
                                           error={formik.errors.passport_address}/>
                            </div>
                        </div>
                        <div className='form_columns_column'>
                            {/*type*/}
                            <div className='form_control'>
                                <div className='form_control_label'>
                                    <label htmlFor="type">Документ на право управления ТС <span>*</span></label>
                                </div>
                                <div className={classes.menu}>
                                    <Button
                                        id='add_document_button'
                                        disabled={isWatch}
                                        onClick={() => setDocumentModal(true)}
                                        text='Добавить'
                                    />
                                </div>
                                <div
                                    className={clsx(classes.table, formik.errors.type && classes.table_error)}>
                                    <thead>
                                    <tr>
                                        <th>Тип документа</th>
                                        <th>Категории</th>
                                        <th>Срок действия</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {formik.values.type.map((type) => {
                                        return (
                                            <tr key={type.id}>
                                                <td>{type.type?.name}</td>
                                                <td style={{
                                                    overflowY: 'auto',
                                                    overflowX: 'hidden'
                                                }}>{type?.category.map(category => category.name).join(', ')}</td>
                                                <td>{type?.driver_license_deadline}</td>
                                                <td>
                                                    <div className={classes.button}
                                                         onClick={() => !isWatch && removeDocumentHandler(type.id)}>
                                                        <Trash/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </div>
                            </div>
                            {/*vehicle*/}
                            <DropdownInput
                                id='vehicle'
                                name='vehicle'
                                items={[
                                    {id: '-1', value: null, name: 'Не выбрано'},
                                    ...vehicles.data.map(vehicle => ({
                                        id: vehicle.id,
                                        name: vehicle.name,
                                        value: vehicle
                                    }))
                                ]}
                                onChange={vehicle => formik.setFieldValue('vehicle', vehicle)}
                                error={formik.errors.vehicle}
                                value={formik.values.vehicle}
                                disabled={isWatch}
                                label='Привязка к ТС'
                                labelStyle={{whiteSpace: 'normal'}}
                                loading={vehicles.loading}
                                onScrollBottom={fetchVehicles}
                                displayText={formik.values.vehicle?.name || 'Не выбрано'}
                            />
                            {/*comment*/}
                            <TextareaInput onChange={formik.handleChange} name='comment' id='comment'
                                           label='Описание' inputStyle={{padding: '5px 14px', height: '75px'}}
                                           value={formik.values.comment} disabled={isWatch}
                                           placeholder='Описание'
                                           error={formik.errors.comment}/>
                        </div>
                    </div>
                </div>
                {!isWatch && <SubmitMenu close={close} saveHandler={formik.submitForm}/>}
            </Modal>
            {documentModal && <DocumentModal close={() => setDocumentModal(false)} isShowing={documentModal}
                                             setDocuments={type => formik.setFieldValue('type', [...formik.values.type, type])}/>}
            {successModal && <SuccessModal close={() => {
                setSuccessModal('');
                close()
            }} description={successModal} title='Успех' isShowing={successModal}/>}
            {errorModal && <SuccessModal close={() => setErrorModal('')} description={errorModal} title='Ошибка'
                                         isError isShowing={errorModal}/>}
        </>
    )
}

export default DriverModal
