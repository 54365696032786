import {Modal, SubmitMenu} from "../Modal";
import React, {useEffect, useState} from "react";
import classes from './SelectionModal.module.sass'
import {ReactComponent as Search} from "../../../assets/settings/search.svg";
import clsx from "clsx";
import {ReactComponent as Arrow} from "../../../assets/arrow.svg";

export const SelectionModal = ({close, title, allItems, selectedItems, saveItems, isShowing}) => {

    const [search, setSearch] = useState('')
    const [currentValues, setCurrentValues] = useState([])

    useEffect(() => {
        setCurrentValues(selectedItems)
    }, [selectedItems])

    const submitHandler = () => {
        saveItems(currentValues)
        close()
    }

    const selectAllHandler = event => {
        if (event.target.checked) {
            setCurrentValues(allItems)
        } else {
            setCurrentValues([])
        }
    }

    const selectHandler = value => {
        if (currentValues.some(item => item.id === value.id)) {
            setCurrentValues(prev => prev.filter(item => item.id !== value.id))
        } else {
            setCurrentValues(prev => [...prev, value])
        }
    }

    return (
        <Modal close={close} title={title} style={{width: '360px'}} isShowing={isShowing}>
            <div className={classes.menu}>
                <div className={classes.checkbox}>
                    <input type='checkbox' id='select_all' name='select_all'
                           onChange={selectAllHandler}/>
                    <label htmlFor='select_all'>Выбрать все</label>
                </div>
                <div className={classes.search}>
                    <Search/>
                    <input type="text" value={search} onChange={e => setSearch(e.target.value)}
                           id='search' name='search'/>
                </div>
            </div>
            <div className={classes.list}>
                {!allItems.length && <p style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>Список пуст</p>}
                {allItems.filter(value => value.name.toLowerCase().includes(search.toLowerCase())).map(value => (
                    <div className={classes.list_item} key={value.name + value.id}>
                        <div className={classes.checkbox}>
                            <input type='checkbox' id={value.name + value.id} name={value.name + value.id}
                                   checked={currentValues.some(item => item.id === value.id)}
                                   onChange={() => selectHandler(value)}/>
                            <label htmlFor={value.name + value.id}>{value.name}</label>
                        </div>
                    </div>
                ))}
            </div>
            <SubmitMenu close={close} saveHandler={submitHandler}/>
        </Modal>
    )
}

const ListItem = ({list, selectHandler, id, search = '', selectedItems, selectableList = false, selectListHandler}) => {

    const [showItems, setShowItems] = useState(false)

    const items = list.items.filter(item => item.name.toLowerCase().includes(search.toLowerCase())) || []

    if (!items.length) return null

    return (
        <div key={list.name + list.id} className={classes.list_item}>
            <div className={classes.checkbox}>
                <div
                    className={clsx(classes.checkbox_arrow, showItems && classes.checkbox_arrow_active, !items.length && classes.checkbox_arrow_invisible)}
                    onClick={() => setShowItems(prev => !prev)}>
                    <Arrow/>
                </div>
                {selectableList && <input type='checkbox' id={list.id} name={list.id}
                                          onChange={selectListHandler}
                                          checked={selectedItems.length === items.length}/>}
                <label htmlFor={`${id}_list`} style={{fontFamily: 'PTRootUIMedium'}}>{list.name}</label>
            </div>
            {showItems && <div className={classes.list_item_innerlist}>
                {items.map(item => (
                    <div key={item.name + item.id} className={classes.list_item}>
                        <div className={classes.checkbox}>
                            <input type='checkbox' id={item.id} name={item.id}
                                   onChange={() => selectHandler(item)}
                                   checked={selectedItems.some(selectedItem => selectedItem.id === item.id)}/>
                            <label htmlFor={`${id}_item`}>{item.name}</label>
                        </div>
                    </div>
                ))}
            </div>}
        </div>
    )

}

export const TreeSelectionModal = ({close, title, allLists, selectedLists, saveLists, selectableList, isShowing}) => {

    const [currentLists, setCurrentLists] = useState([])
    const [search, setSearch] = useState('')

    useEffect(() => {
        setCurrentLists(selectedLists)
    }, [selectedLists])


    const selectAllHandler = event => {
        if (event.target.checked) {
            setCurrentLists(allLists)
        } else {
            setCurrentLists([])
        }
    }

    const selectListHandler = listId => {
        const currentList = allLists.find(list => list.id === listId)
        if(currentLists.some(list => list.id === listId)){
            setCurrentLists(prev => prev.filter(selectedList => selectedList.id !== listId))
        } else {
            setCurrentLists(prev => [...prev, currentList])
        }
    }

    const selectHandler = item => {
        const {id: listId, name: listName} = allLists.find(list => list.items.flat(1).some(curItem => curItem.id === item.id))
        const currentList = currentLists.find(selectedList => selectedList.id === listId) || {id: listId, name: listName, items: []}

        if(currentLists.map(list => list.items).flat(1).some(selectedItem => selectedItem.id === item.id)){
            const newItems = currentList.items.filter(selectedItem => selectedItem.id !== item.id)
            if(newItems.length){
                setCurrentLists(prev => [...prev.filter(list => list.id !== currentList.id), {
                    ...currentList,
                    items: newItems
                }])
            } else {
                setCurrentLists(prev => prev.filter(list => list.id !== currentList.id))
            }
        } else {
            setCurrentLists(prev => [...prev.filter(list => list.id !== currentList.id), {
                ...currentList,
                items: [...currentList.items, item]
            }])
        }
    }

    const submitHandler = () => {
        saveLists(currentLists)
        close()
    }

    return (
        <Modal close={close} title={title} style={{width: '360px'}} isShowing={isShowing}>
            <div className={classes.menu}>
                <div className={classes.checkbox}>
                    <input type='checkbox' id='select_all' name='select_all'
                           onChange={selectAllHandler}/>
                    <label htmlFor='select_all'>Выбрать все</label>
                </div>
                <div className={classes.search}>
                    <Search/>
                    <input type="text" value={search} onChange={e => setSearch(e.target.value)}
                           id='search' name='search'/>
                </div>
            </div>
            <div className={classes.list}>
                {!allLists.length && <p style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>Список пуст</p>}
                {allLists.map(list => (
                    <ListItem key={list.id + list.name} list={list} id={list.id + list.name} selectableList={selectableList}
                              selectHandler={(item) => selectHandler(item)} search={search} selectListHandler={() => selectListHandler(list.id)}
                              selectedItems={currentLists.find(selectedList => list.id === selectedList.id)?.items || []}/>

                ))}
            </div>
            <SubmitMenu close={close} saveHandler={submitHandler}/>
        </Modal>
    )
}
