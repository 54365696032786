import classes from "./SuccessModal.module.sass";
import {ReactComponent as Success} from "../../../assets/success.svg";
import React from "react";
import {Modal} from "../Modal";
import Button from "../../UI/Button";

const SuccessModal = ({close, title, description, isError = false, isShowing}) => {

    const styles = {
        width: '360px',
        height: 'min-content',
    }

    return (
        <Modal close={close} title={title} style={styles} isShowing={isShowing}>
            <div className={classes.text}>
                <p>{description}</p>
                {!isError && <Success/>}
            </div>
            <div className={classes.menu}>
                <Button style={{width: '100px'}} id='success_button' onClick={close} text='ОК'/>
            </div>
        </Modal>
    )
}

export default SuccessModal
